import { environment } from '../../../environments/environment';

export enum OAuthName {
  adwords = 'adwords',
  analytics = 'analytics',
  googleCloudSpanner = 'googlecloudspanner',
  salesforce = 'salesforce',
  salesforceSandbox = 'salesforcesandbox',
  bingAds = 'bingads',
  facebookAdsInsights = 'facebookadsinsights',
  facebookAds = 'facebookads',
  intercom = 'intercom',
  pipedrive = 'pipedrive',
  xero = 'xero',
  linkedin = 'linkedin',
  instagram = 'instagram',
  googleSheets = 'googlesheets',
  googleDrive = 'googleDrive',
  youtube = 'youtube',
  shopify = 'shopify',
  slack = 'slack',
  live = 'live',
  tiktokAds = 'tiktokads',
  hubspotOAuth = 'hubspot',
  twitter = 'twitter',
}

const types: OAuthName[] = [
  OAuthName.slack,
  OAuthName.adwords,
  OAuthName.analytics,
  OAuthName.bingAds,
  OAuthName.salesforce,
  OAuthName.salesforceSandbox,
  OAuthName.googleCloudSpanner,
  OAuthName.facebookAdsInsights,
  OAuthName.facebookAds,
  OAuthName.intercom,
  OAuthName.pipedrive,
  OAuthName.xero,
  OAuthName.linkedin,
  OAuthName.youtube,
  OAuthName.googleSheets,
  OAuthName.googleDrive,
  OAuthName.instagram,
  OAuthName.shopify,
  OAuthName.tiktokAds,
  OAuthName.hubspotOAuth,
  OAuthName.twitter,
];

export interface PopupOptions {
  width: number;
  height: number;
}

export interface ConnectionsAuthenticationProvider {
  name: string;
  authorizationEndpoint: string;
  url: string;
  redirectUri: string;
  origin: string;
  defaultUrlParams: string[];
  optionalUrlParams: string[];
  display: string;
  type: string;
  popupOptions: PopupOptions;
  responseType: string;
  oauthType: string;
  shop?: string;
  environment?: string;
  accessToken?: string;
  responseParams?: {
    code: string;
    clientId: string;
    redirectUri: string;
  };
  scopeDelimiter?: string;
  scopePrefix?: string;
  state?: string;
  clientId?: string;
  code?: string;
}

export interface ConnectionsAuthenticationConfig {
  withCredentials: boolean;
  tokenRoot: string;
  baseUrl: string;
  loginUrl: string;
  revokeUrl: string;
  signupUrl: string;
  unlinkUrl: string;
  tokenName: string;
  tokenPrefix: string;
  authHeader: string;
  authToken: string;
  storageType: string;
  providers: {
    [key: string]: ConnectionsAuthenticationProvider;
  };
}

export const connectionsAuthenticationConfig: ConnectionsAuthenticationConfig = {
  withCredentials: false,
  tokenRoot: null,
  baseUrl: '/',
  loginUrl: '/auth/login',
  revokeUrl: '/oauth/revoke',
  signupUrl: '/auth/signup',
  unlinkUrl: '/auth/unlink',
  tokenName: 'token',
  tokenPrefix: 'satellizer',
  authHeader: 'Authorization',
  authToken: 'Bearer',
  storageType: 'localStorage',
  providers: {},
};

types.forEach((connectionType) => {
  connectionsAuthenticationConfig.providers[connectionType] = {
    name: connectionType,
    authorizationEndpoint: `${environment.APP_URL}/auth/${connectionType}`,
    url: null,
    redirectUri: `${window.location.origin}/auth/${connectionType}/callback`,
    origin: `${window.location.origin}/auth/${connectionType}/callback`,
    defaultUrlParams: ['response_type', 'origin', 'access_token', 'account_id'],
    optionalUrlParams: ['shop', 'environment'],
    display: 'popup',
    type: '2.0',
    popupOptions: { width: 540, height: 580 },
    responseType: 'jwt',
    oauthType: '2.0',
  } as ConnectionsAuthenticationProvider;
});
