import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CloudStorageDestinationComponentData,
  CloudStorageSourceComponentData,
  CSVDestinationType,
  RECORD_TYPE,
} from '../../package.models';
import { ComponentTypeItem } from '../../../constants/component_types';
import { COMPONENT_TYPE } from '../../../constants/component_type';
import { updateComponent, updateRawComponent } from '../../store/component.actions';
import { AppState } from '../../../store';

@Component({
  selector: 'cloud-storage-delimiter',
  template: `
    <div class="cloud-storage-delimiter">
      <div
        *ngIf="
          (rawComponent.record_type === RECORD_TYPE.DELIMITED || isCloudStorageDestinationComponent) &&
          rawComponent.record_type !== RECORD_TYPE.PARQUET
        "
      >
        <label>{{ 'cloud-storage-source-editor.form.labels.delimiter_properties' | translate }}</label>
        <xp-form-group>
          <label for="field_delimiter">{{
            'cloud-storage-source-editor.form.labels.field_delimiter' | translate
          }}</label>
          <xp-select
            id="field_delimiter"
            name="field_delimiter"
            class="form-control xp-select"
            [value]="fieldDelimiter"
            (valueChange)="onFieldDelimiterChange($event)"
            [options]="fieldDelimiterOptions"
            [preventEmpty]="true"
          ></xp-select>
        </xp-form-group>
        <xp-form-group *ngIf="showCustomDelimiterInput">
          <label for="custom_field_delimiter">{{
            'cloud-storage-source-editor.form.labels.field_delimiter_custom' | translate
          }}</label>
          <xp-input
            id="custom_field_delimiter"
            name="custom_field_delimiter"
            [ngModel]="customDelimiter"
            class="form-control"
            (ngModelChange)="onCustomDelimiterChange($event)"
            type="text"
          ></xp-input>
          <div class="error-message" *ngIf="showDelimiterErrorMessage">
            <span>{{ 'cloud-storage-source-editor.form.labels.field_delimiter_custom_error' | translate }}</span>
          </div>
        </xp-form-group>
        <xp-form-group>
          <label for="text_qualifier">{{
            'cloud-storage-source-editor.form.labels.string_qualifier' | translate
          }}</label>
          <xp-select
            id="text_qualifier"
            name="text_qualifier"
            class="form-control xp-select"
            [value]="settings.text_qualifier"
            (valueChange)="onTextQualifierChange($event)"
            [options]="textQualifierOptions"
            [preventEmpty]="true"
          ></xp-select>
        </xp-form-group>
        <xp-form-group id="escape-character-container" *ngIf="isEscapeCharacterDropdownVisible">
          <label for="escape_character">{{
            'cloud-storage-destination-editor.labels.escape_character' | translate
          }}</label>
          <xp-select
            id="escape_character"
            name="escape_character"
            class="form-control xp-select"
            [value]="settings.escape_character"
            (valueChange)="onEscapeCharacterChange($event)"
            [options]="escapeCharacterOptions"
          ></xp-select>
          <small *ngIf="component && component.componentType === 'cloud_storage_destination_component'">{{
            'cloud-storage-destination-editor.hints.escape_character' | translate
          }}</small>
          <small *ngIf="component && component.componentType === 'cloud_storage_source_component'">{{
            'cloud-storage-source-editor.hints.escape_character' | translate
          }}</small>
        </xp-form-group>
        <div *ngIf="isLineEndingLabelVisible" class="form-group">
          <label>{{ 'cloud-storage-destination-editor.labels.line_ending' | translate }}</label>
          <div class="btn-group btn-group-md btn-group-select">
            <button
              type="button"
              class="btn btn-default"
              [ngClass]="{ 'active btn-primary': settings.line_ending === 'unix' }"
              (click)="this.onSettingsValueChange('unix', 'line_ending')"
            >
              {{ 'cloud-storage-destination-editor.selects.line_ending.unix' | translate }}
            </button>
            <button
              type="button"
              class="btn btn-default"
              [ngClass]="{ 'active btn-primary': settings.line_ending === 'windows' }"
              (click)="this.onSettingsValueChange('windows', 'line_ending')"
            >
              {{ 'cloud-storage-destination-editor.selects.line_ending.windows' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div>
        <xp-input-checkbox
          [ngModel]="settings.write_header"
          (ngModelChange)="onWriteHeaderChange($event)"
          *ngIf="component && component.componentType === 'cloud_storage_destination_component'"
          [labelText]="'cloud-storage-' + component.type + '-editor.form.checkbox' | translate"
          name="write_header"
        ></xp-input-checkbox>
        <xp-input-checkbox
          [hidden]="rawComponent.record_type === RECORD_TYPE.PARQUET"
          (ngModelChange)="onSkipHeaderChange($event)"
          [ngModel]="settings.skip_header"
          *ngIf="component && component.componentType !== 'cloud_storage_destination_component'"
          [labelText]="'cloud-storage-' + component.type + '-editor.form.checkbox' | translate"
          name="skip_header"
        ></xp-input-checkbox>
      </div>
      <hr />
    </div>
  `,
})
export class CloudStorageDelimiterComponent implements OnInit, OnChanges {
  @Input() rawComponent: CloudStorageDestinationComponentData | CloudStorageSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() settings: CSVDestinationType;
  @Input() lineInside: boolean;

  showCustomDelimiterInput = false;
  customDelimiter = ',';
  fieldDelimiter = ',';
  textQualifier = '';
  escapeCharacter = '';
  lineEnding = '';
  writeHeader = false;
  skipHeader = false;
  isEscapeCharacterDropdownVisible = false;
  showDelimiterErrorMessage = false;
  RECORD_TYPE = RECORD_TYPE;

  fieldDelimiterOptions = [
    { value: ',', text: ',' },
    { value: '\\t', text: '\\t (Tab)' },
    { value: '\\u0001', text: '\\u0001' },
    { value: 'space', text: 'Space' },
    { value: '\\n', text: '\\n (New Line)' },
    { value: 'custom', text: 'Custom' },
  ];
  textQualifierOptions = [];
  escapeCharacterOptions = [
    { value: '"', text: '"' },
    { value: "'", text: "'" },
    { value: '\\', text: '\\' },
  ];

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.fieldDelimiter = this.settings.field_delimiter;
    this.textQualifier = this.settings.text_qualifier;
    this.escapeCharacter = this.settings.escape_character;
    this.lineEnding = this.settings.line_ending;

    if (
      this.fieldDelimiterOptions.find((option) => option.value === this.fieldDelimiter) ||
      this.fieldDelimiter === ' '
    ) {
      this.fieldDelimiter = this.fieldDelimiter === ' ' ? 'space' : this.fieldDelimiter;
    } else {
      this.customDelimiter = this.fieldDelimiter;
      this.fieldDelimiter = 'custom';
      this.showCustomDelimiterInput = true;
    }

    if (this.settings.write_header !== undefined) {
      this.writeHeader = this.settings.write_header;
    }

    if (this.settings.skip_header !== undefined) {
      this.skipHeader = this.settings.skip_header;
    }

    this.isEscapeCharacterDropdownVisible =
      this.settings.text_qualifier !== '' && this.settings.text_qualifier !== 'none_with_line_ending';

    this.textQualifierOptions = [
      {
        value: '',
        text: 'cloud-storage-source-editor.form.selects.string_qualifier.options.none',
        translate: true,
      },
      {
        value: 'none_with_line_ending',
        text: 'cloud-storage-source-editor.form.selects.string_qualifier.options.none_with_line_ending',
        translate: true,
      },
      {
        value: 'quoted_multi_line',
        text: 'cloud-storage-source-editor.form.selects.string_qualifier.options.double_quote_newline_inside',
        translate: true,
      },
      !this.isCloudStorageDestinationComponent ? { value: '"', text: '"' } : null,
      {
        value: 'single_quoted_multi_line',
        text: 'cloud-storage-source-editor.form.selects.string_qualifier.options.single_quote_newline_inside',
        translate: true,
      },
      !this.isCloudStorageDestinationComponent ? { value: "'", text: "'" } : null,
      { value: 'enforce_double_quotes', text: '"' },
      { value: 'enforce_single_quotes', text: "'" },
      this.isCloudStorageDestinationComponent
        ? {
            value: '"',
            text:
              'cloud-storage-destination-editor.form.selects.string_qualifier.options.double_quote_contains_delimiter_or_line_break',
            translate: true,
          }
        : null,
      this.isCloudStorageDestinationComponent
        ? {
            value: "'",
            text:
              'cloud-storage-destination-editor.form.selects.string_qualifier.options.single_quote_contains_delimiter_or_line_break',
            translate: true,
          }
        : null,
    ].filter(Boolean);

    if (this.settings.escape_character == null || this.settings.escape_character === 'none') {
      this.onSettingsValueChange('"', 'escape_character');
    }

    if (!this.fieldDelimiterOptions.find((item) => item.value === this.settings.field_delimiter)) {
      this.fieldDelimiterOptions = [
        ...this.fieldDelimiterOptions,
        {
          value: this.settings.field_delimiter,
          text: this.settings.field_delimiter,
        },
      ];
    }

    if (!this.escapeCharacterOptions.find((item) => item.value === this.settings.escape_character)) {
      this.escapeCharacterOptions = [
        ...this.escapeCharacterOptions,
        {
          value: this.settings.escape_character,
          text: this.settings.escape_character,
        },
      ];
    }

    if (!this.lineInside) {
      this.textQualifierOptions = this.textQualifierOptions.filter(
        (item) => item.value !== 'quoted_multi_line' && item.value !== 'single_quoted_multi_line',
      );
    } else {
      this.textQualifierOptions = this.textQualifierOptions.filter(
        (item) => item.value !== 'enforce_double_quotes' && item.value !== 'enforce_single_quotes',
      );
      this.escapeCharacterOptions = this.escapeCharacterOptions.filter((item) => item.value !== 'none');
    }

    if (this.settings && this.settings.field_delimiter === ' ') {
      this.onSettingsValueChange('space', 'field_delimiter');
    }
    if (this.settings && (this.settings.escape_character === 'none' || this.settings.escape_character === '')) {
      this.onSettingsValueChange('"', 'escape_character');
    }
    if (this.settings && this.settings.text_qualifier === 'none') {
      this.onSettingsValueChange('', 'text_qualifier');
    }
    if (!this.settings.line_ending) {
      this.onSettingsValueChange('unix', 'line_ending');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const newComponent = changes.rawComponent.currentValue || {};
    const previousComponent = changes.rawComponent.previousValue || {};
    if (newComponent.record_type === RECORD_TYPE.PARQUET && previousComponent.record_type !== RECORD_TYPE.PARQUET) {
      this.onSkipHeaderChange(true);
    }
  }

  get isLineEndingLabelVisible(): boolean {
    return (
      this.component &&
      this.component.componentType === COMPONENT_TYPE.CLOUD_STORAGE_DESTINATION_COMPONENT &&
      this.settings.text_qualifier !== ''
    );
  }

  onSettingsValueChange(value: any, key: string) {
    let newValue = value;

    if (key === 'field_delimiter' && value === 'space') {
      newValue = ' ';
    }

    if (key === 'escape_character' && (value === 'none' || value === '')) {
      newValue = '"';
    }

    if (key === 'text_qualifier' && value === 'none') {
      newValue = '';
    }

    const settings = {
      ...this.settings,
      [key]: newValue,
    };

    if (this.component.componentType === COMPONENT_TYPE.CLOUD_STORAGE_SOURCE_COMPONENT) {
      this.store.dispatch(
        updateRawComponent({
          rawComponent: { record_settings: { ...settings } },
        }),
      );
      this.store.dispatch(updateComponent({ component: { record_settings: { ...settings } } }));
    }

    if (this.component.componentType === COMPONENT_TYPE.CLOUD_STORAGE_DESTINATION_COMPONENT) {
      this.store.dispatch(
        updateRawComponent({
          rawComponent: { destination_type: { csv_destination_type: { ...settings } } },
        }),
      );
      this.store.dispatch(
        updateComponent({ component: { destination_type: { csv_destination_type: { ...settings } } } }),
      );
    }
  }

  onWriteHeaderChange(value: boolean) {
    this.onSettingsValueChange(value, 'write_header');
  }

  onSkipHeaderChange(value: boolean) {
    this.onSettingsValueChange(value, 'skip_header');
  }

  onFieldDelimiterChange(fieldDelimiterValue: string) {
    this.fieldDelimiter = fieldDelimiterValue;

    if (fieldDelimiterValue === 'custom') {
      this.showCustomDelimiterInput = true;
      this.onSettingsValueChange(this.customDelimiter, 'field_delimiter');
    } else {
      this.showCustomDelimiterInput = false;
      this.onSettingsValueChange(fieldDelimiterValue, 'field_delimiter');
    }
  }

  onCustomDelimiterChange(customDelimiter: string) {
    if (customDelimiter === undefined) {
      this.showDelimiterErrorMessage = true;
    } else {
      this.showDelimiterErrorMessage = false;
      this.customDelimiter = customDelimiter;
      this.onSettingsValueChange(customDelimiter, 'field_delimiter');
    }
  }

  onTextQualifierChange(textQualifierValue: string) {
    this.onSettingsValueChange(textQualifierValue, 'text_qualifier');
    this.isEscapeCharacterDropdownVisible = textQualifierValue !== '' && textQualifierValue !== 'none_with_line_ending';
  }

  onEscapeCharacterChange(escapeCharacterValue: string) {
    this.onSettingsValueChange(escapeCharacterValue, 'escape_character');
  }

  get isCloudStorageDestinationComponent(): boolean {
    return this.component && this.component.componentType === COMPONENT_TYPE.CLOUD_STORAGE_DESTINATION_COMPONENT;
  }
}
