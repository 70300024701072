import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, NgForm, ValidatorFn, Validators } from '@angular/forms';

function maskValue(value: string): string {
  if (value === '') {
    return '';
  }
  return '•'.repeat(16) + value.slice(-4);
}

const VARIABLES_PLACEHOLDERS = {
  standard: [
    { name: 'username', value: "'integrate-io'" },
    { name: 'account_id', value: Math.floor(Math.random() * 90000) + 10000 },
    { name: 'date_today', value: 'CurrentTime()' },
  ],
  secret: [
    { name: 'api_key', value: 'sample-api-key' },
    { name: 'password', value: 'sample-password' },
    { name: 'client_secret', value: 'sample-client-secret' },
  ],
};

@Component({
  selector: 'variables-editor-row',
  template: `
    <div class="variables-editor-row" [ngClass]="item.type" cdkDrag>
      <div class="variables-editor-row-number">{{ index | xpIndex }}</div>
      <div class="variables-editor-cell variables-editor-move" [ngClass]="{ locked: disableSort }">
        <button type="button" class="btn btn-defaut btn-lg" [disabled]="disableSort" cdkDragHandle tabindex="-1">
          <svg
            *ngIf="!disableSort"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="7.5px"
            height="24px"
            viewBox="0 0 7.5 24"
          >
            <g>
              <rect
                x="-3.2"
                y="11"
                transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 15.75 8.25)"
                style="fill: #999999;"
                width="14"
                height="2"
              />
            </g>
            <polygon style="fill: #999;" points="0,19 3.8,24 7.5,19" />
            <polygon style="fill: #999;" points="7.5,5 3.8,0 0,5" />
          </svg>
          <svg *ngIf="disableSort" width="15px" height="16.5px" viewBox="0 0 15 16.5">
            <g id="icon-list-lock_1_">
              <path
                style="fill: none; stroke: #999999; stroke-width: 2; stroke-miterlimit: 10;"
                d="M3,5.5C3,3,5,1,7.5,1S12,3,12,5.5"
              />
              <path
                style="fill: #999999;"
                d="M13.9,5.5H1.1C0.5,5.5,0,6,0,6.6v8.9c0,0.6,0.5,1.1,1.1,1.1h12.9c0.6,0,1.1-0.5,1.1-1.1V6.6
                        C15,6,14.5,5.5,13.9,5.5z M8,11.4v2.1H7v-2.1c-0.6-0.2-1-0.8-1-1.4c0-0.8,0.7-1.5,1.5-1.5S9,9.2,9,10C9,10.7,8.6,11.2,8,11.4z"
              />
            </g>
          </svg>
        </button>
      </div>
      <div class="variables-editor-cell-input variables-editor-key variables-editor-cell-padding">
        <div class="cell-container">
          <input
            type="text"
            [attr.name]="item[key]"
            class="form-control input-lg"
            [(ngModel)]="item[key]"
            (input)="onChange()"
            [disabled]="disableKey"
            [readOnly]="readonlyKey"
            [ngClass]="{ invalid: item.keyInvalid && item.keyTouched }"
            [placeholder]="
              showPlaceholder ? VARIABLES_PLACEHOLDERS[showPlaceholder][index % 3]?.name || '' : keyPlaceholder || ''
            "
            (blur)="item.keyTouched = true"
          />
        </div>
      </div>
      <div class="variables-editor-cell-input">
        <div class="cell-container select-box">
          <input
            #inputElement
            type="text"
            class="form-control input-lg icon-right"
            [formControl]="inputControl"
            [name]="'variable-value-' + index"
            [(ngModel)]="item[value]"
            (input)="onChange()"
            [disabled]="disableValue"
            [readOnly]="readonlyValue || item.disabled"
            [ngClass]="{
              invalid:
                inputControl.touched && (item.valueInvalid || (inputControl.errors && inputControl.errors['minlength']))
            }"
            [placeholder]="showPlaceholder ? VARIABLES_PLACEHOLDERS[showPlaceholder][index % 3]?.value || '' : ''"
          />
          <expression-editor-icon
            *ngIf="!readonlyValue && !secretValue"
            [id]="rowId"
            [readonlyValue]="readonlyValue"
            [value]="item[value]"
            [type]="type"
            [index]="index"
            (valueChange)="onValueChange($event)"
          ></expression-editor-icon>

          <i
            class="fa fa-pencil edit-icon"
            [matTooltip]="'Edit variable value'"
            *ngIf="secretValue && item.disabled"
            (click)="onEditClick()"
          ></i>
          <i
            class="fa fa-times cancel-icon"
            [matTooltip]="'Cancel editing variable value'"
            *ngIf="secretValue && !item.disabled"
            (click)="onCancelClick()"
          ></i>
        </div>
        <div class="error-message" *ngIf="item.error">
          {{ item.error }}
        </div>
        <div
          class="error-message"
          *ngIf="inputControl.errors && inputControl.touched && inputControl.errors['minlength']"
        >
          The variable value must be at least 6 characters long.
        </div>
      </div>
      <div class="variables-editor-cell variables-editor-remove" *ngIf="!hideRemove">
        <button type="button" class="btn btn-link btn-lg variables-editor-remove-btn" (click)="remove()" tabindex="-1">
          <i class="fa fa-trash-o" [matTooltip]="'Remove variable'"></i>
        </button>
      </div>
      <div class="variables-editor-cell variables-editor-undo" *ngIf="showUndo">
        <button
          type="button"
          class="btn btn-link btn-lg variables-editor-remove-btn"
          [disabled]="item[value] === item.default"
          (click)="setDefault(item)"
          [matTooltip]="
            (usePackageDefault ? 'variables-editor-row.package-default' : 'variables-editor-row.system-default')
              | translate
          "
          matTooltipPosition="above"
        >
          <i class="fa fa-undo"></i>
        </button>
      </div>
    </div>
  `,
})
export class VariablesEditorRowComponent implements OnInit {
  @Input() item: any;
  @Input() disableKey = false;
  @Input() disableValue = false;
  @Input() readonlyValue = false;
  @Input() secretValue = false;
  @Input() readonlyKey = false;
  @Input() hideRemove = false;
  @Input() showUndo = false;
  @Input() usePackageDefault = false;
  @Input() disableSort = false;
  @Input() index: number;
  @Input() key: string;
  @Input() value: string;
  @Input() type: string;
  @Input() keyPlaceholder: string = '';
  @Input() inputPlaceholder: string = '';
  @Input() hideTooltip: boolean;
  @Input() valueValidators: ValidatorFn[] = [];
  @Input() showPlaceholder: string;
  @Output() itemChange = new EventEmitter();
  @Output() itemRemove = new EventEmitter();
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
  rowId: string;
  previousValue = '';
  originalValue = '';
  inputControl = new FormControl('', []);

  VARIABLES_PLACEHOLDERS = VARIABLES_PLACEHOLDERS;

  ngOnInit() {
    if (this.secretValue && !!this.item[this.value]) {
      this.item.disabled = true;
      if (!this.item[this.value].includes('•'.repeat(16))) {
        this.originalValue = this.item[this.value];
        this.item[this.value] = maskValue(this.item[this.value]);
      }
    }

    this.rowId = uuidv4();
    this.inputControl = new FormControl('', this.valueValidators);
  }

  onChange() {
    if (!this.item[this.value].includes('•'.repeat(16))) {
      this.itemChange.emit(this.item);
    }
  }

  remove() {
    this.itemRemove.emit();
  }

  onValueChange(code: string) {
    this.item[this.value] = code;
    this.itemChange.emit(this.item);
  }

  // eslint-disable-next-line class-methods-use-this
  identify(index: number, item) {
    return item[this.key];
  }

  setDefault(item) {
    this.item[this.value] = item.default;
    this.itemChange.emit(this.item);
  }

  onEditClick() {
    this.item.disabled = false;
    this.previousValue = this.item[this.value];
    this.item[this.value] = '';
    this.inputElement.nativeElement.focus();
  }

  onCancelClick() {
    this.item.disabled = true;
    this.item[this.value] = maskValue(this.originalValue || this.previousValue);
    this.previousValue = '';
    this.onChange();
  }
}
