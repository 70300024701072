export enum SelectPickerTypes {
  'connection' = 'connection',
  'package' = 'package',
  'schedule-package-version' = 'schedule-package-version',
  'run-package-package-version' = 'run-package-package-version',
  'package-version' = 'package-version',
  'cluster' = 'cluster',
  'component' = 'component',
  'workspace' = 'workspace',
}

export const PAGE_LIMIT = 100;

const defaultQueryParams = {
  direction: 'desc',
  include: 'owner,creator',
  sort: 'updated',
  sort_locally: 'updated_at',
  status: 'active',
  limit: PAGE_LIMIT,
};

export const queryParamsMap = {
  [SelectPickerTypes.package]: {
    ...defaultQueryParams,
    include: 'owner,creator,workspace',
  },
  [SelectPickerTypes.cluster]: {
    ...defaultQueryParams,
    status: 'available,creating,pending,idle,error',
  },
  [SelectPickerTypes['package-version']]: {
    ...defaultQueryParams,
    direction: 'desc',
  },
  [SelectPickerTypes['schedule-package-version']]: {
    ...defaultQueryParams,
    direction: 'desc',
  },
  [SelectPickerTypes['run-package-package-version']]: {
    ...defaultQueryParams,
    direction: 'desc',
  },
  [SelectPickerTypes.workspace]: {
    ...defaultQueryParams,
    limit: 8,
    include: 'packages',
  },
  [SelectPickerTypes.connection]: {
    ...defaultQueryParams,
  },
};
