import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { AdwordsSourceComponentData, Connection, ConnectionSchemaField, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import { ComponentTypeItem } from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { getConnectionSchemaResponse } from '../../../connections/store/connections.actions';
import { ADWORDS_API_VERSIONS } from '../../../constants/adwords_api_versions';
import { selectValidators } from '../../../common/validators/store/validators.selectors';
import { ADWORDS_STRUCTURE_REPORTS } from '../../../constants/adwords_structure_reports';
import { environment } from '../../../../environments/environment';
import { SelectOption } from '../../../common/components/forms/xp-select.component';
import { ADWORDS_ADS_V10_DATE_RANGE_TYPES } from '../../../constants/adwords_ads_v10_date_range_types';

interface AdWordsDataResponse {
  name: string;
  title: string;
  performance_max_reporting_option?: boolean;
  fields: {
    [key: string]: {
      title: string;
      type: string;
    };
  };
}

function markDeadApiVersions(apiVersions, validators) {
  const adwordsValidationConstraint = validators['Xplenty::JobAuthoring::Components::AdwordsSourceComponent'];

  const supportedApiVersions = adwordsValidationConstraint?.api_version?.InclusionValidator?.in;
  apiVersions.forEach((apiVersion) => {
    if (supportedApiVersions && supportedApiVersions.indexOf(apiVersion.value) === -1) {
      // eslint-disable-next-line no-param-reassign
      apiVersion.dead = true;
    }
  });
}

@Component({
  selector: 'adwords-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <xp-select-picker-editable
            id="connection-picker-component"
            [type]="selectPickerTypes.connection"
            [value]="rawComponent.connection"
            placeholder="Select connection"
            emptyPlaceholder="Connections list is empty"
            (valueChange)="onSelectConnection($event)"
            (createNew)="onCreateNewConnection($event)"
            [params]="{ type: component.connectionTypes }"
            [connectionTypes]="component.connectionTypes.split(',')"
          ></xp-select-picker-editable>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="adwords-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::AdwordsSourceComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <div class="alert alert-warning" *ngIf="apiErrorMessage">
                  <button type="button" class="close" (click)="apiErrorMessage = null">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span [innerHTML]="apiErrorMessage"></span>
                </div>
                <div class="alert alert-warning" *ngIf="reportErrorMessage">
                  <button type="button" class="close" (click)="reportErrorMessage = null">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <span [innerHTML]="reportErrorMessage"></span>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <xp-form-group>
                      <label for="api_version">{{ 'adwords-source-editor.form.labels.api_version' | translate }}</label>
                      <xp-select
                        name="api_version"
                        id="api_version"
                        [value]="rawComponent.api_version"
                        [options]="apiVersions"
                        [isSearchEnabled]="true"
                        [preventEmpty]="true"
                        class="form-control xp-select"
                        (valueChange)="onApiVersionChange($event)"
                        emptyPlaceholder="No api version found"
                      ></xp-select>
                    </xp-form-group>
                    <xp-form-group>
                      <label for="report_type">{{ 'adwords-source-editor.form.labels.report_type' | translate }}</label>
                      <xp-select
                        class="report-type form-control xp-select"
                        name="report_type"
                        id="report_type"
                        [value]="rawComponent.report_type"
                        [options]="reportTypes"
                        [isSearchEnabled]="true"
                        (valueChange)="onReportTypeChange($event)"
                        [preventEmpty]="true"
                        emptyPlaceholder="No report type found"
                      ></xp-select>
                    </xp-form-group>
                    <div class="form-group">
                      <xp-input-checkbox
                        class="dynamic-connection-checkbox"
                        [(ngModel)]="rawComponent.performance_max_reporting_option"
                        name="performance_max_reporting_option"
                        labelText="Performance Max Reporting"
                        *ngIf="isPerformanceMaxReport"
                        (ngModelChange)="onValueChange($event, 'performance_max_reporting_option')"
                      ></xp-input-checkbox>
                    </div>
                    <xp-form-group>
                      <label for="report_date_range_field">{{
                        'adwords-source-editor.form.labels.report_date_range_field' | translate
                      }}</label>
                      <xp-select
                        class="report-type form-control xp-select"
                        name="report_date_range_field"
                        id="report_date_range_field"
                        [value]="rawComponent.report_date_range_field"
                        [options]="reportDateRangeFieldTypes"
                        [isSearchEnabled]="true"
                        (valueChange)="onReportTypeDateRangeFieldChange($event)"
                        [preventEmpty]="true"
                      ></xp-select>
                    </xp-form-group>
                    <xp-form-group>
                      <label for="report_date_range_type">{{
                        'adwords-source-editor.form.dates.labels.range' | translate
                      }}</label>
                      <xp-select
                        name="report_date_range_type"
                        id="report_date_range_type"
                        class="form-control xp-select"
                        [value]="rawComponent.report_date_range_type"
                        [options]="dateRangeTypes"
                        [disabled]="isDateRangeDisabled || rawComponent.report_date_range_field === ''"
                        (valueChange)="onValueChange($event, 'report_date_range_type')"
                      ></xp-select>
                    </xp-form-group>
                    <div *ngIf="rawComponent.report_date_range_type === 'CUSTOM_DATE'" class="date-range-container">
                      <date-range
                        [startDate]="rawComponent.report_date_range_min"
                        [endDate]="rawComponent.report_date_range_max"
                        (startDateChange)="onValueChange($event, 'report_date_range_min')"
                        (endDateChange)="onValueChange($event, 'report_date_range_max')"
                      ></date-range>
                    </div>
                    <div class="form-group-options">
                      <label for="report_on">{{ 'bing-ads-source-editor.form.labels.report_on' | translate }}</label>
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="all"
                          id="report-on-all"
                        />
                        <label for="report-on-all">{{
                          'adwords-source-editor.form.labels.all_customers' | translate
                        }}</label>
                      </div>
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="specific"
                          id="report-on-specific"
                        />
                        <label for="report-on-specific">{{
                          'adwords-source-editor.form.labels.specific_customers' | translate
                        }}</label>
                      </div>
                      <div class="radio">
                        <input
                          type="radio"
                          [ngModel]="rawComponent.report_on"
                          (ngModelChange)="onValueChange($event, 'report_on')"
                          name="report_on"
                          value="custom"
                          id="report-on-custom"
                        />
                        <label for="report-on-custom">{{
                          'adwords-source-editor.form.labels.custom' | translate
                        }}</label>
                      </div>
                    </div>
                    <xp-form-group *ngIf="rawComponent.report_on === 'custom'">
                      <label for="customer_ids">{{
                        'adwords-source-editor.form.labels.customer_ids' | translate
                      }}</label>
                      <xp-chips
                        name="customer_ids"
                        id="customer_ids"
                        [value]="rawComponent.customer_ids"
                        (valueChange)="onValueChange($event, 'customer_ids')"
                        class="form-control xp-select"
                        [placeholder]="'adwords-source-editor.form.placeholders.customer_ids' | translate"
                      ></xp-chips>
                      <i
                        class="fa fa-exclamation-circle form-control-info date-range-hint"
                        [matTooltip]="'adwords-source-editor.form.hints.customer_ids' | translate"
                        matTooltipPosition="after"
                      ></i>
                    </xp-form-group>
                  </div>
                  <!--account campaign selector here-->
                  <div class="col-sm-12" *ngIf="rawComponent.report_on === 'specific'">
                    <accounts-campaigns-selector
                      [component]="component"
                      [rawComponent]="rawComponent"
                    ></accounts-campaigns-selector>
                    <label for="ad_accounts" style="visibility: hidden;"></label>
                    <xp-input
                      type="text"
                      [ngModel]="rawComponent.ad_accounts"
                      (ngModelChange)="onValueChange($event, 'customer_ids')"
                      name="ad_accounts"
                      id="ad_accounts"
                      style="visibility: hidden;"
                    ></xp-input>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [component]="component"
            [rawComponent]="rawComponent"
            (updateValidation)="updateSchemaValidation($event)"
            [dataPreviewDisabled]="!this.isSchemaValid || !this.isFormValid"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class AdwordsSourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: AdwordsSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;

  isSchemaValid = true;
  isFormValid = true;
  isBaseComponentStepActivated = false;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});

  apiVersions = ADWORDS_API_VERSIONS;
  dateRangeTypes = ADWORDS_ADS_V10_DATE_RANGE_TYPES;
  apiErrorMessage = '';
  reportErrorMessage = '';
  reportTypes: SelectOption[] = [];
  reportDateRangeFieldTypes: SelectOption[] = [{ text: 'None', value: '' }];
  lastReportTypeObject = null;
  isDateRangeDisabled = false;
  adwordsData: AdWordsDataResponse[] = [];
  isPerformanceMaxReport = false;
  performanceMaxReports: string[] = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private http: HttpClient,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: !!this.rawComponent.connection?.id && isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: !!this.rawComponent.connection?.id,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: this.areFieldsValid,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });

    this.store
      .select(selectValidators)
      .pipe(first())
      .subscribe((validators) => {
        this.init(validators);
      });

    if (
      !this.rawComponent.report_date_range_field &&
      (this.rawComponent.report_date_range_min || this.rawComponent.report_date_range_max)
    ) {
      this.rawComponent = {
        ...this.rawComponent,
        report_date_range_field: 'Date',
      };
      this.onReportTypeDateRangeFieldChange('Date');
    }

    if (!this.rawComponent.report_date_range_field && this.rawComponent.report_date_range_type) {
      this.rawComponent = {
        ...this.rawComponent,
        report_date_range_field: 'Date',
      };
      this.onReportTypeDateRangeFieldChange('Date', true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.rawComponent.connection?.id
        ? this.componentFormTagsService.getTags(this.rawComponent, this.component)
        : [],
    };

    if (
      changes.rawComponent?.currentValue?.report_date_range_type !== 'CUSTOM_DATE' &&
      changes.rawComponent?.currentValue?.report_date_range_type !==
        changes.rawComponent?.previousValue?.report_date_range_type
    ) {
      if (!this.rawComponent.report_date_range_min && !this.rawComponent.report_date_range_max) {
        this.onValueChange('', 'report_date_range_min');
        this.onValueChange('', 'report_date_range_max');
      }
      this.onValueChange('Default', 'manual_breakdown');
    }
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  get areFieldsValid(): boolean {
    return !!((this.rawComponent.schema || {}).fields || []).filter((item) => item.name !== 'customer_id').length;
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    setTimeout(() => {
      this.isSchemaValid = isSchemaValid && this.areFieldsValid;

      this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
      this.onValidityChange();
    });
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { schema: { ...this.rawComponent.schema, fields } },
      }),
    );
  }

  onApiVersionChange(apiVersion: string) {
    this.onValueChange(apiVersion, 'api_version');
    this.checkCurrentApiVersion(this.apiVersions.find((item) => item.value === apiVersion));
    this.getReportTypes(apiVersion);
  }

  onReportTypeChange(reportType: string) {
    this.isPerformanceMaxReport = this.performanceMaxReports.includes(reportType);
    if (!this.isPerformanceMaxReport) {
      this.onValueChange(false, 'performance_max_reporting_option');
    }

    if (reportType && reportType !== this.rawComponent.report_type) {
      this.onValueChange(reportType, 'report_type');
      this.setReportDateRangeFieldTypes(reportType);
    }
    this.checkIfStructuredReport();
    this.getFields(reportType);
    this.lastReportTypeObject = this.reportTypes.find((item) => item.value === reportType);
  }

  onReportTypeDateRangeFieldChange(reportTypeDateRangeField: string, skipType = false) {
    this.onValueChange(reportTypeDateRangeField, 'report_date_range_field');

    if (skipType) {
      return;
    }

    if (reportTypeDateRangeField === '') {
      this.onValueChange('', 'report_date_range_type');
    } else {
      this.onValueChange('LAST_7_DAYS', 'report_date_range_type');
    }
  }

  init(validators) {
    this.initApiVersions(this.apiVersions, validators);

    this.onApiVersionChange(this.rawComponent.api_version);
  }

  initApiVersions(apiVersions, validators) {
    markDeadApiVersions(apiVersions, validators);
    const currentVersion = apiVersions.find((item) => item.value === this.rawComponent.api_version);
    this.checkCurrentApiVersion(currentVersion);
  }

  checkCurrentApiVersion(currentApiVersion) {
    if (!currentApiVersion) {
      this.apiErrorMessage = `Selected api version ${this.rawComponent.api_version} is not found.`;
      this.onValueChange(this.apiVersions[0].value, 'api_version');
    } else if (currentApiVersion.dead) {
      this.apiErrorMessage = this.translate.instant('adwords-source-editor.form.errors.api_version', {
        api_version: currentApiVersion.text,
      });
    }
  }

  checkIfStructuredReport() {
    // hardcoded structure reports
    if (ADWORDS_STRUCTURE_REPORTS.indexOf(this.rawComponent.report_type) > -1) {
      this.onValueChange('', 'report_date_range_min');
      this.onValueChange('', 'report_date_range_max');
      this.onValueChange('ALL_TIME', 'report_date_range_type');
      this.isDateRangeDisabled = true;
    } else {
      this.isDateRangeDisabled = false;
    }
  }

  getReportTypes(apiVersion: string) {
    const selectedApiObject = this.apiVersions.find((item) => item.value === apiVersion);
    if (selectedApiObject) {
      if (selectedApiObject.dead) {
        this.apiErrorMessage = this.translate.instant('adwords-source-editor.form.errors.api_version', {
          api_version: selectedApiObject.text,
        });
      } else {
        this.apiErrorMessage = null;
      }

      this.http.get(`${environment.API_URL}/adwords/${selectedApiObject.value}.json`).subscribe({
        next: (adwordsData: AdWordsDataResponse[]) => {
          this.apiErrorMessage = '';
          const reportTypes = [];
          this.adwordsData = adwordsData;
          this.performanceMaxReports = [];

          adwordsData.forEach((obj) => {
            reportTypes.push({
              text: obj.title,
              value: obj.name,
              fields: obj.fields,
            });

            if (obj.performance_max_reporting_option) {
              this.performanceMaxReports.push(obj.name);
            }
          });

          this.reportTypes = reportTypes;

          const currentReportType = reportTypes.find((item) => item.value === this.rawComponent.report_type);
          if (!currentReportType) {
            if (this.rawComponent.report_type && this.lastReportTypeObject) {
              this.reportErrorMessage = this.translate.instant('adwords-source-editor.form.errors.report_type', {
                report: this.lastReportTypeObject.text,
              });
            }
            this.onValueChange(reportTypes[0].value, 'report_type');
            this.onReportTypeChange(reportTypes[0].value);
            this.setReportDateRangeFieldTypes(reportTypes[0].value);
            return;
          }
          this.setReportDateRangeFieldTypes(this.rawComponent.report_type);
          this.onReportTypeChange(this.rawComponent.report_type);
        },
        error: (error) => {
          if (error.message) {
            this.apiErrorMessage = error.message;
          }
          this.notify.error(`An error occurred while calling ${selectedApiObject.value} API.`);
        },
      });
    }
  }

  setReportDateRangeFieldTypes(reportType: string) {
    this.reportDateRangeFieldTypes = [{ text: 'None', value: '' }];

    const reportTypeObject = this.reportTypes.find((item) => item.value === reportType) || ({} as SelectOption);

    Object.keys(reportTypeObject.fields).forEach((key) => {
      const field = reportTypeObject.fields[key];
      if (field.dateRangeField) {
        this.reportDateRangeFieldTypes.push({
          text: field.title,
          value: key,
        });
      }
    });

    if (
      this.rawComponent.report_date_range_field == null &&
      (this.rawComponent.report_date_range_type != null || this.rawComponent.report_date_range_min != null)
    ) {
      if (this.reportDateRangeFieldTypes.length > 1) {
        this.onValueChange(this.reportDateRangeFieldTypes[1].value, 'report_date_range_field');
      } else {
        this.onValueChange('', 'report_date_range_type');
        this.onValueChange('', 'report_date_range_min');
        this.onValueChange('', 'report_date_range_max');
      }
    }
  }

  getFields(reportType: string) {
    const currentReportType = this.adwordsData.find((item) => item.name === reportType);

    if (currentReportType) {
      this.store.dispatch(
        getConnectionSchemaResponse({
          connectionSchema: Object.keys(currentReportType.fields).map((key) => ({
            name: key,
            alias: key,
            data_type: currentReportType.fields[key].type,
            category: 'Fields',
          })),
          connectionSchemaData: { data: [], fields: [] },
        }),
      );
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
