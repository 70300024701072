import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { selectUser } from '../store/account.selectors';
import { GenericListType } from '../../common/components/lists/generic-list.component';

@Component({
  selector: 'oauth-callback',
  template: ` <div class="">Redirecting...</div> `,
})
export class OauthCallbackComponent {}
