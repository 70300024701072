import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './account.component';
import { ConnectionsComponent } from './connections/connections.component';
import { PackagesComponent } from './packages/packages.component';
import { WorkspacesComponent } from './workspaces/workspaces.component';
import { PackageDesignerComponent } from './package-designer/package-designer.component';
import { PackageDesignerDeactivateGuard } from './package-designer/package-designer-deactivate.guard';
import { PackageDesignerResolver } from './package-designer/package-designer.resolver';
import { JobsComponent } from './jobs/jobs.component';
import { ClustersComponent } from './clusters/clusters.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { ConsoleComponent } from './help/console.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountSettingsInfoComponent } from './settings/components/account-settings-info.component';
import { AccountSettingsBillingComponent } from './settings/components/account-settings-billing.component';
import { MembersListComponent } from './settings/components/members/members-list.component';
import { MembersItemComponent } from './settings/components/members/members-item.component';
import { AccountSettingsUsageComponent } from './settings/components/account-settings-usage.component';
import { HooksListComponent } from './settings/components/hooks/hooks-list.component';
import { SettingsProfileComponent } from './settings/components/settings-profile.component';
import { SettingsPasswordComponent } from './settings/components/settings-password.component';
import { SettingsMfaComponent } from './settings/components/settings-mfa.component';
import { SettingsNotificationsComponent } from './settings/components/settings-notifications.component';
import { KeysListComponent } from './settings/components/keys/keys-list.component';
import { AccountsListComponent } from './settings/components/accounts/accounts-list.component';
import { SettingsTokensComponent } from './settings/components/settings-tokens.component';
import { Error404Component } from './account/components/error-404.component';
import { Error401Component } from './account/components/error-401.component';
import { WelcomeComponent } from './account/components/welcome.component';
import { LaunchpadComponent } from './account/components/launchpad.component';
import { canActivateAuthenticationFn } from './common/services/authentication.guard';
import { canActivateAuthorizationFn } from './common/services/authorization.guard';
import { AccountResolver } from './account.resolver';
import { EmailConfirmationComponent } from './account/components/email-confirmation.component';
import { SignInComponent } from './account/components/sign-in.component';
import { SignUpComponent } from './account/components/sign-up.component';
import { SignUpFreeTrialComponent } from './account/components/sign-up-free-trial.component';
import { ConfirmationComponent } from './account/components/confirmation.component';
import { ThankYouComponent } from './account/components/thank-you.component';
import { ForgotPasswordComponent } from './account/components/forgot-password.component';
import { ResetPasswordComponent } from './account/components/reset-password.component';
import { MembersInvitationComponent } from './account/components/members-invitation.component';
import { AuthComponent } from './account/components/auth.component';
import { PartnersComponent } from './account/components/partners.component';
import { EditComponentProxyComponent } from './package-designer/edit-package-proxy.component';
import { GlobalVariablesComponent } from './settings/components/global-variables/global-variables.component';
import { ErrorNotAllowedComponent } from './account/components/error-not-allowed.component';
import { PingComponent } from './account/components/ping.component';
import { OauthCallbackComponent } from './account/components/oauth-callback.component';

const routes: Routes = [
  { path: '', redirectTo: 'launchpad', pathMatch: 'full' },
  { path: 'auth-callback', component: OauthCallbackComponent },
  {
    path: 'ping',
    component: PingComponent,
    data: {
      title: 'Ping!',
    },
  },
  {
    path: 'launchpad',
    component: LaunchpadComponent,
    data: {
      title: 'Welcome!',
    },
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    children: [{ path: 'start', component: WelcomeComponent }],
    data: {
      title: 'Welcome!',
    },
  },
  {
    path: 'not-allowed',
    component: ErrorNotAllowedComponent,
    data: {
      title: 'Access Denied',
    },
  },
  {
    path: '404',
    component: Error404Component,
    data: {
      title: 'Page not found',
    },
  },
  {
    path: '401',
    component: Error401Component,
    data: {
      title: 'Access denied',
    },
  },
  {
    path: 'partners/heroku/sso',
    component: PartnersComponent,
  },
  {
    path: 'partners/awsmarketplace/sso',
    component: PartnersComponent,
  },
  {
    path: 'account/new_email_confirmation/:token',
    component: EmailConfirmationComponent,
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: SignInComponent,
        data: {
          title: 'Sign In',
        },
      },
      {
        path: 'signup',
        component: SignUpComponent,
        data: {
          title: 'Sign up',
        },
      },
      {
        path: 'signup/old',
        component: SignUpComponent,
        data: {
          title: 'Sign up',
        },
      },
      {
        path: 'signup/freetrial',
        component: SignUpFreeTrialComponent,
        data: {
          title: 'Sign up',
        },
      },
      {
        path: 'confirmation',
        component: ConfirmationComponent,
        data: {
          title: 'Email Confirmation',
        },
      },
      {
        path: 'thank-you/freetrial',
        component: ThankYouComponent,
        data: {
          title: 'Thank you!',
        },
      },
      {
        path: 'thank-you',
        component: ThankYouComponent,
        data: {
          title: 'Thank you!',
        },
      },
      {
        path: 'forgot',
        component: ForgotPasswordComponent,
        data: {
          title: 'Forgot password',
        },
      },
      {
        path: 'password_reset',
        component: ResetPasswordComponent,
        data: {
          title: 'Reset password',
        },
      },
      {
        path: 'invitation',
        component: MembersInvitationComponent,
        data: {
          title: 'Invitation',
        },
      },
    ],
  },
  {
    path: 'signup/confirmation/:token',
    component: ConfirmationComponent,
    data: {
      title: 'Confirmation',
    },
  },
  {
    path: ':account_id',
    component: AccountComponent,
    canActivateChild: [canActivateAuthenticationFn, canActivateAuthorizationFn],
    data: {
      title: 'Account',
      permissions: 'viewProfile',
    },
    resolve: {
      account: AccountResolver,
    },
    children: [
      { path: '', redirectTo: 'jobs', pathMatch: 'full' },
      {
        path: '404',
        component: Error404Component,
        data: {
          title: 'Page not found',
        },
      },
      {
        path: '401',
        component: Error401Component,
        data: {
          title: 'Access denied',
        },
      },
      {
        path: 'connections',
        component: ConnectionsComponent,
        data: {
          title: 'Connections',
          permissions: 'listConnections',
        },
        children: [
          {
            path: 'new',
            data: {
              title: 'New Connection',
              permissions: 'createConnection',
            },
            component: ConnectionsComponent,
          },
          {
            path: ':type',
            children: [
              { path: 'new', component: ConnectionsComponent, data: { title: 'New Connection' } },
              {
                path: ':connection_id/edit',
                component: ConnectionsComponent,
                data: { title: 'Edit Connection', permissions: 'updateConnection' },
              },
            ],
            data: {
              title: 'Connections',
              permissions: 'createConnection',
            },
          },
        ],
      },
      {
        path: 'workspaces/new',
        data: {
          title: 'New Workspace',
          permissions: 'createWorkspace',
        },
        component: WorkspacesComponent,
      },
      {
        path: 'workspaces/:workspace_id/edit',
        component: WorkspacesComponent,
        data: {
          title: 'Edit Workspace',
          permissions: 'createWorkspace',
        },
      },
      {
        path: 'workspaces/:workspace_id',
        component: PackagesComponent,
        data: {
          title: 'Workspace packages',
          permissions: 'viewWorkspace',
        },
        children: [
          {
            path: 'new',
            component: PackagesComponent,
            data: {
              title: 'New workspace package',
              permissions: 'createPackage',
            },
          },
        ],
      },
      {
        path: 'workspaces',
        component: WorkspacesComponent,
        children: [
          {
            path: ':workspace_id/edit',
            component: WorkspacesComponent,
            data: { title: 'Edit Workspace', permissions: 'updateWorkspace' },
          },
        ],
        data: {
          title: 'Workspaces',
          permissions: 'listWorkspaces',
        },
      },
      {
        path: 'packages/:package_id/edit',
        component: PackageDesignerComponent,
        canDeactivate: [PackageDesignerDeactivateGuard],
        data: {
          title: 'Package',
          permissions: 'viewPackage',
        },
        resolve: {
          package: PackageDesignerResolver,
        },
      },
      { path: 'packages/:package_id/edit-new', component: EditComponentProxyComponent },
      {
        path: 'packages/:package_id/versions/:version',
        component: PackageDesignerComponent,
        canDeactivate: [PackageDesignerDeactivateGuard],
        data: {
          title: 'Package',
          permissions: 'versionControl',
        },
        resolve: {
          package: PackageDesignerResolver,
        },
      },
      {
        path: 'packages',
        component: PackagesComponent,
        children: [
          {
            path: 'new',
            data: {
              title: 'New Package',
              permissions: 'createPackage',
            },
            component: PackagesComponent,
          },
          {
            path: ':package_id/edit',
            component: PackagesComponent,
            data: { title: 'Edit Package', permissions: 'updatePackage' },
          },
        ],
        data: {
          title: 'Packages',
          permissions: 'listPackages',
        },
      },
      {
        path: 'jobs',
        component: JobsComponent,
        children: [
          {
            path: 'new',
            data: {
              title: 'New Job',
              permissions: 'createJob',
            },
            component: JobsComponent,
          },
          {
            path: ':job_id',
            component: JobsComponent,
            data: {
              title: 'View Job',
              permissions: 'viewJob',
            },
            children: [
              { path: 'outputs', component: JobsComponent, data: { title: 'Job Outputs' } },
              { path: 'errors', component: JobsComponent, data: { title: 'Job Errors' } },
              { path: 'variables', component: JobsComponent, data: { title: 'Job Variables' } },
              { path: 'responses', component: JobsComponent, data: { title: 'Job Responses' } },
              { path: 'tasks', component: JobsComponent, data: { title: 'Job Tasks' } },
              {
                path: 'children/:child_id',
                component: JobsComponent,
                data: { title: 'Job Task' },
                children: [
                  { path: 'outputs', component: JobsComponent, data: { title: 'Job Outputs' } },
                  { path: 'responses', component: JobsComponent, data: { title: 'Job Responses' } },
                  { path: 'errors', component: JobsComponent, data: { title: 'Job Errors' } },
                  { path: 'variables', component: JobsComponent, data: { title: 'Job Variables' } },
                  { path: 'General', component: JobsComponent, data: { title: 'Job Tasks' } },
                ],
              },
            ],
          },
        ],
        data: {
          title: 'Jobs',
          permissions: 'listJobs',
        },
      },
      {
        path: 'clusters',
        component: ClustersComponent,
        children: [
          {
            path: 'new',
            data: {
              title: 'New Cluster',
              permissions: 'createCluster',
            },
            component: ClustersComponent,
          },
          {
            path: ':cluster_id/edit',
            component: ClustersComponent,
            data: { title: 'Edit Cluster', permissions: 'updateCluster' },
          },
        ],
        data: {
          title: 'Clusters',
          permissions: 'listClusters',
        },
      },
      {
        path: 'schedules',
        component: SchedulesComponent,
        children: [
          {
            path: 'new',
            data: {
              title: 'New Schedule',
              permissions: 'createSchedule',
            },
            component: SchedulesComponent,
          },
          {
            path: ':schedule_id/edit',
            component: SchedulesComponent,
            data: { title: 'Edit Schedule', permissions: 'updateSchedule' },
          },
        ],
        data: {
          title: 'Schedules',
          permissions: 'listSchedules',
        },
      },
      {
        path: 'console',
        component: ConsoleComponent,
        data: {
          title: 'X-Console',
        },
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          title: 'Settings',
        },
        children: [
          { path: '', redirectTo: 'account', pathMatch: 'full' },
          {
            path: 'account',
            component: SettingsComponent,
            data: {
              title: 'Account',
            },
            children: [
              { path: '', redirectTo: 'profile', pathMatch: 'full' },
              {
                path: 'profile',
                component: AccountSettingsInfoComponent,
                data: {
                  title: 'Profile',
                  permissions: 'viewProfile',
                },
              },
              {
                path: 'billing',
                component: AccountSettingsBillingComponent,
                data: {
                  title: 'Billing',
                  permissions: 'viewBillingInfo',
                },
              },
              {
                path: 'usage',
                component: AccountSettingsUsageComponent,
                data: {
                  title: 'Usage',
                  permissions: 'viewUsage',
                },
              },
              {
                path: 'global-variables',
                component: GlobalVariablesComponent,
                data: {
                  title: 'Global Variables',
                  permissions: 'viewGlobalVariables',
                },
              },
              {
                path: 'members',
                component: MembersListComponent,
                data: {
                  title: 'Members',
                  permissions: 'listMembers',
                },
                children: [
                  {
                    path: 'invitation/:token',
                    component: MembersListComponent,
                  },
                  {
                    path: 'new',
                    data: {
                      title: 'New Member',
                      permissions: 'createMember',
                    },
                    component: MembersListComponent,
                  },
                  {
                    path: ':member_id/edit',
                    data: {
                      title: 'Edit Member',
                      permissions: 'updateMember',
                    },
                    component: MembersListComponent,
                  },
                ],
              },
              {
                path: 'members/:member_id',
                data: {
                  title: 'Member',
                  permissions: 'viewMember',
                },
                component: MembersItemComponent,
              },
              {
                path: 'hooks',
                component: HooksListComponent,
                data: {
                  title: 'Hooks',
                  permissions: 'listHooks',
                },
                children: [
                  {
                    path: 'new',
                    data: {
                      title: 'New Hook',
                      permissions: 'createHook',
                    },
                    component: HooksListComponent,
                  },
                  {
                    path: ':type/new',
                    data: {
                      title: 'New Hook',
                      permissions: 'createHook',
                    },
                    component: HooksListComponent,
                  },
                  {
                    path: ':hook_id/edit',
                    data: {
                      title: 'Edit Hook',
                      permissions: 'updateHook',
                    },
                    component: HooksListComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'my',
            component: SettingsComponent,
            data: {
              title: 'Account',
            },
            children: [
              { path: '', redirectTo: 'profile', pathMatch: 'full' },
              {
                path: 'profile',
                component: SettingsProfileComponent,
                data: {
                  title: 'Profile',
                },
              },
              {
                path: 'password',
                component: SettingsPasswordComponent,
                data: {
                  title: 'Password change',
                },
              },
              {
                path: 'mfa',
                component: SettingsMfaComponent,
                data: {
                  title: 'MFA',
                },
              },
              {
                path: 'notifications',
                component: SettingsNotificationsComponent,
                data: {
                  title: 'Notifications',
                },
              },
              {
                path: 'keys',
                component: KeysListComponent,
                data: {
                  title: 'Keys',
                },
                children: [
                  {
                    path: 'new',
                    component: KeysListComponent,
                    data: {
                      title: 'New Key',
                    },
                  },
                ],
              },
              {
                path: 'accounts',
                component: AccountsListComponent,
                data: {
                  title: 'Accounts',
                },
                children: [
                  {
                    path: 'new',
                    component: AccountsListComponent,
                    data: {
                      title: 'New Account',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'developer',
            component: SettingsComponent,
            data: {
              title: 'Developer',
            },
            children: [
              { path: '', redirectTo: 'profile', pathMatch: 'full' },
              {
                path: 'tokens',
                component: SettingsTokensComponent,
                data: {
                  title: 'Tokens',
                },
              },
            ],
          },
        ],
      },
      { path: '**', redirectTo: '404' },
    ],
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
