import { SelectOption } from '../common/components/forms/xp-select.component';

export const ADWORDS_API_VERSIONS: SelectOption[] = [
  {
    text: 'AdsV18',
    value: 'AdsV18',
  },
  {
    text: 'AdsV17',
    value: 'AdsV17',
  },
  {
    text: 'AdsV16',
    value: 'AdsV16',
  },
  {
    text: 'AdsV15',
    value: 'AdsV15',
    dead: true,
  },
];
