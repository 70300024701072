import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';

import { filter, withLatestFrom } from 'rxjs/operators';
import { Subscription as RxSubscription } from 'rxjs';
import { BaseForm, BaseFormInterface } from '../../base/base-form.component';
import { Cluster, ClusterTypes } from '../../../clusters/clusters.models';
import { Subscription } from '../../../settings/subscription.models';
import {
  selectClustersErrors,
  selectClustersIsSubmittingFlag,
  selectLastlyCreatedCluster,
} from '../../../clusters/store/clusters.selectors';
import { AppState } from '../../../store';
import { SubscriptionsResource } from '../../../settings/resources/subscriptions.resource';
import { NotifyService } from '../../services/notify.service';
import { IdleTimesService, IdleTimeValues } from '../../../clusters/services/idle-times.service';
import { closeClustersModal, saveCluster, updateCluster } from '../../../clusters/store/clusters.actions';
import { SelectOption } from './xp-select.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'cluster-form',
  template: `
    <div class="cluster-form">
      <div class="cluster-form-body">
        <xp-form-validation [type]="item.id ? 'Cluster' : 'NewCluster'" [name]="formName">
          <form id="clusterForm" name="clusterForm" #form="ngForm" class="form modal-form-container">
            <div class="form-group cluster-form-name" *ngIf="!hideName">
              <xp-form-group>
                <label for="name"
                  >{{ 'cluster.form.labels.name' | translate }}
                  <span *ngIf="!item.id" class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
                >
                <xp-input id="name" name="name" [(ngModel)]="item.name" type="text" class="form-control"></xp-input>
              </xp-form-group>
              <small *ngIf="!item.id">{{ 'cluster.form.hints.name' | translate }}</small>
            </div>
            <div class="form-group cluster-form-cat" [hidden]="editMode && item.type === ClusterTypes.sandbox">
              <cluster-cat [cluster]="item"></cluster-cat>
              <span *ngIf="item.type === ClusterTypes.production || !item.type"
                >{{ item.nodes }}
                {{ (item.nodes === 1 ? 'cluster.form.labels.node' : 'cluster.form.labels.nodes') | translate }}</span
              >
              <span *ngIf="item.type === ClusterTypes.sandbox">{{ 'cluster.form.labels.sandbox' | translate }}</span>
              <div class="cluster-form-cat-nodes" *ngIf="item.type === ClusterTypes.production || !item.type">
                {{ item.nodes }}
              </div>
            </div>
            <div class="form-group text-center" *ngIf="!editMode && !hideSandbox && !productionOnly">
              <div class="btn-group btn-group-sm">
                <button
                  type="button"
                  class="btn btn-default"
                  [ngClass]="{ 'btn-primary': item.type === ClusterTypes.sandbox }"
                  (click)="onItemTypeChange(ClusterTypes.sandbox)"
                >
                  {{ 'cluster.form.labels.sandbox' | translate }}
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  [ngClass]="{ 'btn-primary': item.type === ClusterTypes.production }"
                  (click)="onItemTypeChange(ClusterTypes.production)"
                >
                  {{ 'cluster.form.labels.production' | translate }}
                </button>
              </div>
            </div>
            <div class="cluster-form-slider" *ngIf="item.type === ClusterTypes.production || !item.type">
              <strong>{{ 'cluster.form.labels.slider' | translate }}</strong>
              <small>{{ 'cluster.form.hints.sandbox' | translate }}</small>
              <xp-loader *ngIf="isSubscriptionLoading"></xp-loader>
              <mat-slider
                class="example-margin"
                [max]="
                  subscription.plan.cluster_nodes_limit > CLUSTER_NODES_LIMIT
                    ? CLUSTER_NODES_LIMIT
                    : subscription.plan.cluster_nodes_limit
                "
                [min]="1"
                [step]="1"
                [discrete]="true"
              >
                <input
                  matSliderThumb
                  [value]="item.nodes"
                  name="nodes"
                  [ngModel]="item.nodes"
                  (ngModelChange)="onNodesChange($event)"
                />
              </mat-slider>
            </div>
            <div class="form-group cluster-form-terminate">
              <div class="cluster-form-terminate-checkbox">
                <mat-slide-toggle
                  [(ngModel)]="item.terminate_on_idle"
                  name="terminate_on_idle"
                  (ngModelChange)="clusterChange.emit()"
                  >{{ 'cluster.form.checkbox' | translate }}</mat-slide-toggle
                >
              </div>
              <div class="cluster-form-terminate-select">
                <span class="cluster-form-terminate-select-label">
                  {{ 'cluster.form.labels.after' | translate }}:
                </span>
                <xp-form-group class="cluster-form-terminate-select-control" [validationDisabled]="true">
                  <xp-select
                    class="form-control xp-select"
                    name="time_to_idle"
                    id="select-terminate"
                    [value]="item.time_to_idle"
                    [options]="idleTimes"
                    (valueChange)="onIdleTimeChange($event)"
                  >
                  </xp-select>
                </xp-form-group>
              </div>
            </div>
            <div class="form-group re-use" *ngIf="showReUse">
              <span class="cluster-form-re-use-select-label">{{ 'cluster.form.labels.re_use' | translate }}: </span>
              <div>
                <xp-select
                  class="form-control xp-select"
                  id="select-strategy"
                  [value]="item.reuse_cluster_strategy"
                  name="reuse_cluster_strategy"
                  [options]="clusterStrategies"
                  (valueChange)="onClusterStrategyChange($event)"
                >
                </xp-select>
                <small *ngIf="item.reuse_cluster_strategy === 'self'">{{
                  'cluster.form.hints.strategy.self' | translate
                }}</small>
                <small *ngIf="item.reuse_cluster_strategy === 'any'">{{
                  'cluster.form.hints.strategy.any' | translate
                }}</small>
                <small *ngIf="item.reuse_cluster_strategy === 'none'">{{
                  'cluster.form.hints.strategy.none' | translate
                }}</small>
                <small *ngIf="item.reuse_cluster_strategy === 'same_node'">{{
                  'cluster.form.hints.strategy.same_node' | translate
                }}</small>
                <small *ngIf="item.reuse_cluster_strategy === 'least_jobs'">{{
                  'cluster.form.hints.strategy.least_jobs' | translate
                }}</small>
              </div>
            </div>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="cluster-form-footer modal-footer" *ngIf="!hideFooter">
        <div class="modal-title-container active">
          <common-icon iconId="icon-cluster" size="L"></common-icon>

          <h3 class="modal-title" *ngIf="!item.id">{{ 'cluster.form.titles.new' | translate }}</h3>
          <h3 class="modal-title" *ngIf="item.id">{{ 'cluster.form.titles.edit' | translate }}</h3>
        </div>

        <button
          type="button"
          id="connection-button-cancel"
          class="btn btn-lg btn-default pull-right"
          (click)="cancel()"
          *ngIf="isInModalPanel"
        >
          {{ 'connections.form.default.buttons.cancel' | translate }}
        </button>
        <xp-submit-button
          (click)="saveCluster(item)"
          classNames="btn-lg btn-success pull-right modal-btn-save"
          [createText]="!item.id && 'cluster.form.buttons.create' | translate"
          [updateText]="item.id && 'cluster.form.buttons.update' | translate"
          [isFormValid]="form.valid"
          [isFormSubmitting]="isSubmitting$ | async"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class ClusterFormComponent extends BaseForm implements BaseFormInterface, OnDestroy {
  @Input() hideName = false;
  @Input() editMode = false;
  @Input() hideSandbox = false;
  @Input() hideFooter = false;
  @Input() productionOnly = false;
  @Input() showReUse = false;
  @Input() isInModalPanel = false;
  @Input() disableModalClose = false;
  @Input() isInJobsView = false;
  @Input() item: Partial<Cluster> = {};
  @Output() clusterCreated = new EventEmitter<Cluster>();
  @Output() clusterChange = new EventEmitter();
  @ViewChild('form') form: NgForm;

  subscription: Partial<Subscription> = {
    plan: {
      cluster_nodes_limit: 32,
    },
  };
  isSubscriptionLoading = true;

  ClusterTypes = ClusterTypes;
  formName = 'clusterForm';
  successMessageText = 'cluster.form.success_message';
  isSubmitting$ = this.store.select(selectClustersIsSubmittingFlag);
  errors$ = this.store.select(selectClustersErrors);
  errorTexts = [];
  clusterNewItemSubscriber: RxSubscription;
  idleTimes: SelectOption[];
  CLUSTER_NODES_LIMIT = Number(environment.CLUSTER_NODES_LIMIT);

  clusterStrategies = [
    {
      name: this.translate.instant('cluster.form.strategies.self'),
      value: 'self',
    },
    {
      name: this.translate.instant('cluster.form.strategies.any'),
      value: 'any',
    },
    {
      name: this.translate.instant('cluster.form.strategies.same_node'),
      value: 'same_node',
    },
    {
      name: this.translate.instant('cluster.form.strategies.least_jobs'),
      value: 'least_jobs',
    },
    {
      name: this.translate.instant('cluster.form.strategies.none'),
      value: 'none',
    },
  ];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private subscriptionsResource: SubscriptionsResource,
    private idleTimesService: IdleTimesService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();

    this.subscriptionsResource
      .get({
        include: 'plan',
      } as any)
      .subscribe({
        next: (subscription) => {
          this.subscription = subscription;
          this.isSubscriptionLoading = false;
        },
        error: () => {
          this.notify.error('An error occurred while loading subscription data.');
        },
      });
  }

  ngOnInit() {
    super.ngOnInit();

    this.idleTimes =
      this.item.type === ClusterTypes.sandbox
        ? this.idleTimesService.idleTimesSandbox
        : this.idleTimesService.idleTimesProduction;
  }

  onIdleTimeChange(idleTime: number) {
    this.item.time_to_idle = idleTime;
    this.clusterChange.emit();
  }

  onItemTypeChange(value: ClusterTypes) {
    this.item.type = value;

    if (this.item.type === ClusterTypes.production) {
      this.item.time_to_idle = IdleTimeValues.TenMinutes;
      this.idleTimes = this.idleTimesService.idleTimesProduction;
    } else {
      this.item.time_to_idle = IdleTimeValues.ThirtyMinutes;
      this.idleTimes = this.idleTimesService.idleTimesSandbox;
      this.item.nodes = 1;
    }
  }

  onNodesChange(value: number) {
    this.item.nodes = value;
    this.clusterChange.emit();
  }

  cancel() {
    this.store.dispatch(closeClustersModal());
  }

  onClusterStrategyChange(clusterStrategy: string) {
    this.item.reuse_cluster_strategy = clusterStrategy;
    this.clusterChange.emit();
  }

  saveCluster(cluster: Partial<Cluster>) {
    const newCluster: Partial<Cluster> = { ...cluster };
    const params = {};

    if (cluster.id) {
      this.store.dispatch(updateCluster({ cluster: newCluster, params, clusterId: cluster.id }));
    } else {
      this.store.dispatch(saveCluster({ cluster: newCluster }));
    }

    this.clusterNewItemSubscriber = this.isSubmitting$
      .pipe(
        filter((isSubmitting) => !isSubmitting),
        withLatestFrom(this.store.select(selectLastlyCreatedCluster)),
      )
      .subscribe(([, item]) => {
        if (item) {
          if (!this.disableModalClose) {
            this.store.dispatch(closeClustersModal());

            if (!this.isInJobsView) {
              this.router.navigate(['./'], { relativeTo: this.route });
            }
          } else {
            this.clusterCreated.emit(item);
          }
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.clusterNewItemSubscriber) {
      this.clusterNewItemSubscriber.unsubscribe();
    }
  }
}
