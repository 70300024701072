import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationGuard } from '../services/authorization.guard';

@Directive({
  selector: '[xpHref]',
})
export class XpHrefDirective implements OnInit {
  @Input('xpHref') xpHref: string;

  constructor(private router: Router, private authGuard: AuthorizationGuard, private elementRef: ElementRef) {}

  ngOnInit() {
    const accountId = this.authGuard.account?.account_id;

    const url = this.router.serializeUrl(this.router.createUrlTree([`/${accountId}/${this.xpHref}`]));

    this.elementRef.nativeElement.setAttribute('href', url);
  }
}
