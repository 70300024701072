import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConnectionTypesResource } from '../resources/connection-types.resource';
import {
  getConnectionsList,
  getConnectionsListResponse,
  loadMoreConnectionsList,
  loadMoreConnectionsListResponse,
  removeConnection,
  removeConnectionError,
  removeConnectionItem,
  removeConnectionResponse,
  saveConnection,
  saveConnectionError,
  saveConnectionResponse,
  searchConnectionsList,
  searchConnectionsListResponse,
  updateConnection,
  updateConnectionError,
  updateConnectionResponse,
  getConnectionTypes,
  getConnectionTypesResponse,
  closeConnectionsModal,
  testConnection,
  testConnectionResponse,
  testConnectionError,
  getConnectionItem,
  getConnectionItemResponse,
  getConnectionError,
  getConnectionTypesError,
  getConnectionSchema,
  getConnectionSchemaError,
  getConnectionSchemaResponse,
  getConnectionsListError,
  getDatabaseColumns,
  getDatabaseColumnsResponse,
  getDatabaseColumnsError,
} from './connections.actions';
import { SLIDER_CLOSE_ANIMATION_DURATION } from '../../constants/animation-constants';
import { ConnectionsResource } from '../resources/connections.resource';
import { ConnectionItemsResource } from '../resources/connection-items.resource';
import { AppState } from '../../store';
import { selectConnections } from './connections.selectors';
import { NotifyService } from '../../common/services/notify.service';
import { getErrorFromResponse } from '../../common/helper/response.helpers';
import { ConnectionTypeName } from '../connection.models';
import { ActivatedRoute, Router } from '@angular/router';

function handleTestError(res) {
  let message;
  if (res.error_message) {
    message = res.error_message;
  } else if (res.data && res.data.errors) {
    const element = document.querySelector('[name=connectionForm]');
    const validationType = element.parentElement.getAttribute('type');

    const errors = res.data.errors.map((e) => {
      e.fieldNameKey = e.field !== 'base' ? [validationType, 'labels', e.field].join('.') : '';
      return e;
    });

    message = errors
      .map((error) => {
        const wrapper = document.createElement('span');
        const key = this.translate.instant(error.fieldNameKey);

        if (key !== 'base') {
          const childElement = document.createElement('strong');
          childElement.innerText = `${key} `;
          wrapper.appendChild(childElement);
        }
        const childElement = document.createElement('span');
        childElement.innerText = error.message;
        wrapper.appendChild(childElement);
        return wrapper.innerHTML;
      })
      .join('<br>');
  } else if (res.error && res.error.error_message) {
    message = res.error.error_message;
  } else if (res.status === -1) {
    message = this.translate.instant('response.timeout.message');
  } else {
    message = `${res.status}`;
  }
  return message;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectionsEffects {
  getTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConnectionTypes),
      switchMap(() =>
        this.connectionTypesResource.query().pipe(
          map((types) => getConnectionTypesResponse({ types })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getConnectionTypesError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  getConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConnectionsList),
      switchMap(({ params }) =>
        this.connectionsResource.query(params).pipe(
          map((connections) => getConnectionsListResponse({ connections })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getConnectionsListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  getConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConnectionItem),
      switchMap(({ connectionType, connectionId }) =>
        this.connectionItemsResource.get(connectionType, connectionId).pipe(
          map((connection) => getConnectionItemResponse({ connection })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getConnectionError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  searchConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchConnectionsList),
      switchMap(({ params }) =>
        this.connectionsResource.search(params).pipe(
          map((connections) => searchConnectionsListResponse({ connections })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getConnectionsListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  loadMoreConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreConnectionsList),
      switchMap(({ params }) =>
        this.connectionsResource.query(params).pipe(
          map((connections) => loadMoreConnectionsListResponse({ connections })),
          catchError((response) => {
            this.notify.error(this.translate.instant(`response.${response.status}.message`));
            return of(getConnectionsListError({ errors: getErrorFromResponse(response) }));
          }),
        ),
      ),
    ),
  );

  saveConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveConnection),
      switchMap(({ connection, connectionType }) =>
        this.connectionItemsResource.save(connectionType, connection, { include: 'package,cluster,owner' } as any).pipe(
          map((connectionResponse) => {
            const shouldNotCloseModal = !!connectionResponse.public_key || connectionResponse.tunnel_type === 'reverse';
            return saveConnectionResponse({
              data: { ...connectionResponse, password: connection.password },
              closeModal: !shouldNotCloseModal,
            });
          }),
          catchError((response) => of(saveConnectionError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  saveConnectionResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveConnectionResponse),
      tap(({ data, closeModal }) => {
        this.router.navigate([
          `/${this.route.firstChild.snapshot.params.account_id}/connections/${data.type}/${data.id}/edit`,
        ]);
      }),
      map(({ closeModal }) => (closeModal ? closeConnectionsModal() : { type: 'empty' })),
    ),
  );

  updateConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConnection),
      withLatestFrom(this.store.select(selectConnections)),
      switchMap(([{ connection, params, connectionId, connectionType }, connections]) =>
        this.connectionItemsResource
          .update(connectionType, connectionId, connection, { ...params, include: 'packages' } as any)
          .pipe(
            map((connectionResponse) => {
              const previousConnection = connections.find((item) => item.id === connectionResponse.id) || ({} as any);
              const shouldNotCloseModal =
                (previousConnection.tunnel_type !== 'reverse' && connectionResponse.tunnel_type === 'reverse') ||
                (previousConnection.tunnel_type !== 'ssh-tunnel' && connectionResponse.tunnel_type === 'ssh-tunnel');
              return updateConnectionResponse({ data: connectionResponse, shouldCloseModal: !shouldNotCloseModal });
            }),
            catchError((response) => of(updateConnectionError({ errors: getErrorFromResponse(response) }))),
          ),
      ),
    ),
  );

  testConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(testConnection),
      switchMap(({ testData, connectionId, connectionType }) =>
        this.connectionItemsResource.test(connectionType, connectionId, testData).pipe(
          map(() =>
            testConnectionResponse({
              message: [this.translate.instant('connections.form.default.notifications.test.success_message')],
            }),
          ),
          catchError((error) =>
            of(
              testConnectionError({
                errors: [
                  {
                    message: this.translate.instant('connections.form.default.notifications.test.error_message', {
                      message: handleTestError(error),
                    }),
                  },
                ],
              }),
            ),
          ),
        ),
      ),
    ),
  );

  updateConnectionResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConnectionResponse),
      map(({ shouldCloseModal }) => (shouldCloseModal ? closeConnectionsModal() : { type: 'empty' })),
    ),
  );

  removeConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeConnection),
      switchMap(({ connectionId, connectionType }) =>
        this.connectionItemsResource.remove(connectionType, connectionId).pipe(
          map((connectionResponse) => removeConnectionItem({ data: connectionResponse })),
          catchError((response) => of(removeConnectionError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  removeConnectionResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeConnectionItem),
      delay(SLIDER_CLOSE_ANIMATION_DURATION),
      map(({ data }) => removeConnectionResponse({ data })),
    ),
  );

  getConnectionSchema$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConnectionSchema),
      switchMap(({ connectionId, connectionType, schemaRequestData, onlyData, hardRefresh, access_mode }) =>
        this.connectionItemsResource.schema(connectionType, connectionId, schemaRequestData).pipe(
          map((response) =>
            getConnectionSchemaResponse({
              onlyData,
              hardRefresh,
              connectionSchemaData: response,
              connectionSchema: response.fields.map((field) => ({
                name: Object.keys(field)[0],
                data_type: Object.values(field)[0],
                alias: Object.keys(field)[0],
                category: 'Schema',
              })),
              access_mode,
            }),
          ),
          catchError((response) => of(getConnectionSchemaError({ errors: getErrorFromResponse(response) }))),
        ),
      ),
    ),
  );

  getDatabaseColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDatabaseColumns),
      switchMap(({ connectionId, connectionType, tableName, schemaName }) =>
        this.connectionItemsResource
          .databaseColumns(connectionType, connectionId, { table: tableName, schema_name: schemaName })
          .pipe(
            map((response) => {
              return getDatabaseColumnsResponse({
                columns: response.fields.map((item) => Object.keys(item)[0]),
              });
            }),
            catchError((response) => of(getDatabaseColumnsError({ errors: getErrorFromResponse(response) }))),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private connectionTypesResource: ConnectionTypesResource,
    private connectionItemsResource: ConnectionItemsResource,
    private connectionsResource: ConnectionsResource,
    private translate: TranslateService,
    private store: Store<AppState>,
    private notify: NotifyService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
}
