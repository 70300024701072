import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import { Connection, ConnectionSchemaField, RestApiSourceComponentData, Schema } from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import {
  AUTHENTICATION_TYPES,
  ComponentTypeItem,
  REST_API_SOURCE_METHODS,
  REST_API_RESPONSE_TYPES,
} from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { getConnectionSchema } from '../../../connections/store/connections.actions';
import { getDataForSchemaImporter } from '../../helpers/schema.helpers';
import { AuthorizationGuard } from '../../../common/services/authorization.guard';
import { OAuthService } from '../../services/oauth.service';

@Component({
  selector: 'rest-api-source-editor',
  template: `
    <div>
      <xp-steps>
        <xp-step [step]="connectionStep">
          <div class="rest-api-authentication">
            <div class="btn-group btn-group-md btn-group-select">
              <button
                type="button"
                class="btn btn-default"
                [attr.value]="AUTHENTICATION_TYPES.NONE"
                name="authentication"
                [ngClass]="{ 'active btn-primary': rawComponent.authentication === AUTHENTICATION_TYPES.NONE }"
                (click)="setAuthenticationType(AUTHENTICATION_TYPES.NONE)"
              >
                None
              </button>
              <button
                type="button"
                class="btn btn-default"
                [attr.value]="AUTHENTICATION_TYPES.BASIC"
                name="authentication"
                [ngClass]="{ 'active btn-primary': rawComponent.authentication === AUTHENTICATION_TYPES.BASIC }"
                (click)="setAuthenticationType(AUTHENTICATION_TYPES.BASIC)"
              >
                Basic
              </button>
              <button
                type="button"
                class="btn btn-default"
                [attr.value]="AUTHENTICATION_TYPES.CONNECTION"
                name="authentication"
                [ngClass]="{ 'active btn-primary': rawComponent.authentication === AUTHENTICATION_TYPES.CONNECTION }"
                (click)="setAuthenticationType(AUTHENTICATION_TYPES.CONNECTION)"
              >
                Connection
              </button>
            </div>
            <div *ngIf="rawComponent.authentication === AUTHENTICATION_TYPES.BASIC">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group lp-ignore">
                    <label for="username">{{ 'rest-api-source-editor.form.labels.username' | translate }}</label>
                    <input
                      type="text"
                      class="form-control"
                      name="username"
                      id="username"
                      data-lpignore="true"
                      data-1p-ignore="true"
                      [ngModel]="rawComponent.username"
                      (ngModelChange)="onValueChange($event, 'username')"
                      [placeholder]="'rest-api-source-editor.form.placeholders.username' | translate"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group has-feedback lp-ignore">
                    <label for="password">{{ 'rest-api-source-editor.form.labels.password' | translate }}</label>
                    <input
                      [attr.type]="showPassword ? 'text' : 'password'"
                      class="form-control"
                      name="password"
                      id="password"
                      data-lpignore="true"
                      data-1p-ignore="true"
                      autocomplete="off"
                      [ngModel]="rawComponent.password"
                      (ngModelChange)="onValueChange($event, 'password')"
                      [placeholder]="'rest-api-source-editor.form.placeholders.password' | translate"
                    />
                    <span class="form-control-feedback">
                      <button class="btn btn-link" (click)="showPassword = !showPassword">
                        <i class="fa" [ngClass]="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="rawComponent.authentication === AUTHENTICATION_TYPES.CONNECTION">
              <label>Choose connection</label>
              <xp-select-picker-editable
                id="connection-picker-rest-api"
                [type]="selectPickerTypes.connection"
                [value]="rawComponent.connection"
                placeholder="Select connection"
                emptyPlaceholder="Connections list is empty"
                (valueChange)="onSelectConnection($event)"
                (createNew)="onCreateNewConnection($event)"
                [params]="{ type: component.connectionTypes }"
                [connectionTypes]="component.connectionTypes.split(',')"
              ></xp-select-picker-editable>
            </div>
          </div>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="rest-api-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::RestApiSourceComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <h4>Request</h4>
                <hr />
                <div class="row">
                  <div class="col-sm-12">
                    <label>Method and URL</label>
                    <xp-form-group innerClass="url-editor input-group" labelName="URL">
                      <label for="method" class="hidden">Method</label>
                      <div class="input-group-btn">
                        <button type="button" class="btn btn-default btn-lg" [matMenuTriggerFor]="dropdown">
                          {{ rawComponent.method }} <span class="caret"></span>
                        </button>
                        <mat-menu #dropdown="matMenu">
                          <li mat-menu-item (click)="onValueChange(REST_API_METHODS.GET, 'method')">
                            {{ 'url-editor.method.get' | translate }}
                          </li>
                          <li mat-menu-item (click)="onValueChange(REST_API_METHODS.POST, 'method')">
                            {{ 'url-editor.method.post' | translate }}
                          </li>
                          <li mat-menu-item (click)="onValueChange(REST_API_METHODS.PATCH, 'method')">
                            {{ 'url-editor.method.patch' | translate }}
                          </li>
                          <li mat-menu-item (click)="onValueChange(REST_API_METHODS.PUT, 'method')">
                            {{ 'url-editor.method.put' | translate }}
                          </li>
                        </mat-menu>
                      </div>
                      <label for="url" class="hidden">URL</label>
                      <input
                        type="text"
                        class="form-control"
                        name="url"
                        id="url"
                        [ngModel]="rawComponent.url"
                        (ngModelChange)="onValueChange($event, 'url')"
                        [placeholder]="'url-editor.placeholder' | translate"
                      />
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <rest-api-headers-collection
                    [records]="rawComponent.headers"
                    (recordsChange)="onValueChange($event, 'headers')"
                    (validityChange)="onHeadersValidityChange($event)"
                  >
                  </rest-api-headers-collection>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <label>Body</label>
                    <div class="form-group">
                      <code-editor
                        [value]="rawComponent.body"
                        [options]="bodyDataOptions"
                        name="body"
                        (valueChange)="onValueChange($event, 'body')"
                      ></code-editor>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" style="padding: 4px 0px;">
                      <xp-input-checkbox
                        [ngModel]="rawComponent.use_pagination"
                        (ngModelChange)="onValueChange($event, 'use_pagination')"
                        name="use_pagination"
                        [labelText]="'rest-api-source-editor.form.checkbox' | translate"
                      ></xp-input-checkbox>
                    </div>
                    <div class="well" *ngIf="rawComponent.use_pagination">
                      <xp-form-group>
                        <label for="pagination_scheme">{{
                          'rest-api-source-editor.form.labels.pagination_scheme' | translate
                        }}</label>
                        <xp-select
                          class="form-control xp-select"
                          name="pagination_scheme"
                          id="pagination_scheme"
                          [value]="rawComponent.pagination_scheme"
                          (valueChange)="onValueChange($event, 'pagination_scheme')"
                          [options]="paginationSchemeOptions"
                        >
                        </xp-select>
                      </xp-form-group>
                      <xp-form-group *ngIf="rawComponent.use_pagination">
                        <label for="pagination_sleep_interval">{{
                          'rest-api-source-editor.form.labels.sleep_interval' | translate
                        }}</label>
                        <xp-input
                          type="text"
                          class="form-control"
                          name="pagination_sleep_interval"
                          id="pagination_sleep_interval"
                          [ngModel]="rawComponent.pagination_sleep_interval"
                          (ngModelChange)="onValueChange($event, 'pagination_sleep_interval')"
                          [placeholder]="'rest-api-source-editor.form.placeholders.sleep_interval' | translate"
                        ></xp-input>
                      </xp-form-group>
                      <xp-form-group *ngIf="rawComponent.use_pagination">
                        <label for="max_requests">{{
                          'rest-api-source-editor.form.labels.max_requests' | translate
                        }}</label>
                        <xp-input
                          type="text"
                          class="form-control"
                          name="max_requests"
                          id="max_requests"
                          [ngModel]="rawComponent.max_requests"
                          (ngModelChange)="onValueChange($event, 'max_requests')"
                          [placeholder]="'rest-api-source-editor.form.placeholders.max_requests' | translate"
                        ></xp-input>
                      </xp-form-group>
                    </div>
                  </div>
                </div>
                <h4>Response</h4>
                <hr />
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group response-type-container">
                      <label for="response_type">Response type</label>
                      <div class="btn-group btn-group-md btn-group-select">
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: rawComponent.response_type === REST_API_RESPONSE_TYPES.raw }"
                          value="raw"
                          (click)="onValueChange(REST_API_RESPONSE_TYPES.raw, 'response_type')"
                        >
                          {{ 'rest-api-source-editor.form.selects.response_type.options.raw' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: rawComponent.response_type === REST_API_RESPONSE_TYPES.json }"
                          value="json"
                          (click)="onValueChange(REST_API_RESPONSE_TYPES.json, 'response_type')"
                        >
                          {{ 'rest-api-source-editor.form.selects.response_type.options.json' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{
                            active: rawComponent.response_type === REST_API_RESPONSE_TYPES.line_delimited_json,
                          }"
                          value="line_delimited_json"
                          (click)="onValueChange(REST_API_RESPONSE_TYPES.line_delimited_json, 'response_type')"
                        >
                          {{
                            'rest-api-source-editor.form.selects.response_type.options.line_delimited_json' | translate
                          }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{
                            active: rawComponent.response_type === REST_API_RESPONSE_TYPES.line_delimited_data,
                          }"
                          value="line_delimited_data"
                          (click)="onValueChange(REST_API_RESPONSE_TYPES.line_delimited_data, 'response_type')"
                        >
                          {{
                            'rest-api-source-editor.form.selects.response_type.options.line_delimited_data' | translate
                          }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{
                            active: rawComponent.response_type === REST_API_RESPONSE_TYPES.xml,
                          }"
                          value="xml"
                          (click)="onValueChange(REST_API_RESPONSE_TYPES.xml, 'response_type')"
                        >
                          {{ 'rest-api-source-editor.form.selects.response_type.options.xml' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <json-path
                        [path]="rawComponent.json_path"
                        (valueChange)="onValueChange($event, 'json_path')"
                        *ngIf="rawComponent.response_type === REST_API_RESPONSE_TYPES.json"
                      ></json-path>
                    </div>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaImporterStep" (activate)="activateSchemaStep()" [isWide]="true">
          <schema-importer
            (fieldsChange)="onFieldsChange($event)"
            [fields]="(rawComponent.schema || {}).fields || []"
            [component]="component"
            [rawComponent]="rawComponent"
            (updateValidation)="updateSchemaValidation($event)"
          ></schema-importer>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class RestApiSourceEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: RestApiSourceComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';

  selectPickerTypes = SelectPickerTypes;

  isSchemaValid = true;
  isFormValid = true;
  validationChangeSubscription: Subscription;
  isOAuthVisible = false;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaImporterStep: Step = getStep({});

  showPassword = false;
  areHeadersValid = true;
  isBaseComponentStepActivated = false;

  bodyDataOptions = {
    lineWrapping: true,
    lineNumbers: true,
    theme: 'twilight',
    mode: 'text/json',
    placeholder: '{\n  "type": "transaction"\n  "start_at": "2019-10-01"\n}\nor\ntype=transaction&start_at=2019-10-01',
  };

  AUTHENTICATION_TYPES = AUTHENTICATION_TYPES;
  REST_API_METHODS = REST_API_SOURCE_METHODS;
  REST_API_RESPONSE_TYPES = REST_API_RESPONSE_TYPES;
  paginationSchemeOptions = [
    {
      value: 'auto',
      text: 'rest-api-source-editor.form.selects.pagination_scheme.options.auto',
      translate: true,
    },
    {
      value: 'jira',
      text: 'rest-api-source-editor.form.selects.pagination_scheme.options.jira',
      translate: true,
    },
    {
      value: 'magento',
      text: 'rest-api-source-editor.form.selects.pagination_scheme.options.magento',
      translate: true,
    },
    {
      value: 'linkheaders',
      text: 'rest-api-source-editor.form.selects.pagination_scheme.options.linkheaders',
      translate: true,
    },
    {
      value: 'bigcommercev2',
      text: 'rest-api-source-editor.form.selects.pagination_scheme.options.bigcommercev2',
      translate: true,
    },
    {
      value: 'elasticsearch',
      text: 'rest-api-source-editor.form.selects.pagination_scheme.options.elasticsearch',
      translate: true,
    },
    {
      value: 'elasticsearchscroll',
      text: 'rest-api-source-editor.form.selects.pagination_scheme.options.elasticsearchscroll',
      translate: true,
    },
    {
      value: 'appsyncdynamodb',
      text: 'rest-api-source-editor.form.selects.pagination_scheme.options.appsyncdynamodb',
      translate: true,
    },
    {
      value: 'facebookbusinessdiscovery',
      text: 'rest-api-source-editor.form.selects.pagination_scheme.options.facebookbusinessdiscovery',
      translate: true,
    },
  ];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private authGuard: AuthorizationGuard,
    private oAuthSerivce: OAuthService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: true,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
    });

    this.schemaImporterStep = getStep({
      title: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-schema.${this.component.componentType}.active`),
      valid: !!this.rawComponent.schema?.fields?.length,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
    };

    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: (((this.rawComponent.schema || {}).fields as ConnectionSchemaField[]) || []).map((field) => ({
        name: field.alias,
      })),
    };
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  updateSchemaValidation(isSchemaValid: boolean) {
    this.isSchemaValid = isSchemaValid;

    this.schemaImporterStep = { ...this.schemaImporterStep, valid: this.isSchemaValid, isError: !this.isSchemaValid };
    this.onValidityChange();
  }

  onHeadersValidityChange(areHeadersValid: boolean) {
    this.areHeadersValid = areHeadersValid;

    this.onValidityChange();
  }

  activateSchemaStep() {
    this.store.dispatch(
      getConnectionSchema({
        connectionType: 'curl',
        schemaRequestData: getDataForSchemaImporter(
          this.rawComponent,
          this.component.componentType,
          this.authGuard.account.account_id,
        ),
      }),
    );
  }

  onValidityChange() {
    const isValid = this.isSchemaValid && this.isFormValid && this.areHeadersValid;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onFieldsChange(fields: ConnectionSchemaField[]) {
    this.schemaImporterStep = {
      ...this.schemaImporterStep,
      tags: fields.map((field) => ({
        name: field.alias,
      })),
    };

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { schema: { ...this.rawComponent.schema, fields } },
      }),
    );
  }

  setAuthenticationType(authenticationType: string) {
    this.onValueChange(authenticationType, 'authentication');
    if (authenticationType !== AUTHENTICATION_TYPES.CONNECTION) {
      this.onValueChange({}, AUTHENTICATION_TYPES.CONNECTION);
    }
    if (authenticationType !== AUTHENTICATION_TYPES.BASIC) {
      this.onValueChange(null, 'username');
      this.onValueChange(null, 'password');
    }
  }

  addAuthorizationHeader(token: string) {
    const authorizationHeader = (this.rawComponent.headers || []).find((header) => header.name === 'Authorization');

    if (authorizationHeader) {
      const newHeaders = this.rawComponent.headers.map((header) => {
        if (header.name === 'Authorization') {
          return { name: 'Authorization', value: `Bearer ${token}` };
        }
        return header;
      });
      this.onValueChange(newHeaders, 'headers');
    } else {
      const newHeaders = [...(this.rawComponent.headers || []), { name: 'Authorization', value: `Bearer ${token}` }];
      this.onValueChange(newHeaders, 'headers');
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }
}
