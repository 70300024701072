import { createReducer, on } from '@ngrx/store';
import {
  loadSelectPickerItem,
  loadSelectPickerItemError,
  loadSelectPickerItemResponse,
  loadSelectPickerItems,
  loadSelectPickerItemsResponse,
  loadSelectPickerItemsWithSearch,
  loadSelectPickerItemsWithSearchResponse,
  updateSelectPickerItem,
} from './select-picker.actions';
import { SelectPickerValue } from '../components/forms/select-picker/xp-select-picker.component';

export const initialState = {
  connection: {},
  package: {},
  'schedule-package-version': {},
  'run-package-package-version': {},
  'package-version': {},
  cluster: {},
  component: {},
  workspace: {},
};

interface SelectPickerSubState {
  [id: string]: {
    items: SelectPickerValue[];
    isLoading: boolean;
    isLoadingItem: boolean;
    isLoadingFlagIgnored: boolean;
    isLazyLoad: boolean;
  };
}

export interface SelectPickerState {
  connection: SelectPickerSubState;
  package: SelectPickerSubState;
  'schedule-package-version': SelectPickerSubState;
  'run-package-package-version': SelectPickerSubState;
  'package-version': SelectPickerSubState;
  cluster: SelectPickerSubState;
  component: SelectPickerSubState;
  workspace: SelectPickerSubState;
}

export const selectPickerReducer = createReducer<SelectPickerState>(
  initialState,

  on(loadSelectPickerItems, (state, { id, itemType, ignoreLoadingFlag, isLazyLoad }) => ({
    ...state,
    [itemType]: {
      ...state[itemType],
      [id]: {
        isLoadingItem: false,
        items:
          state[itemType][id]?.isLoadingFlagIgnored || ignoreLoadingFlag || isLazyLoad
            ? state[itemType][id]?.items
            : [],
        isLoading: state[itemType][id]?.isLoadingFlagIgnored || isLazyLoad ? false : !ignoreLoadingFlag,
        isLoadingFlagIgnored: state[itemType][id]?.isLoadingFlagIgnored || !!ignoreLoadingFlag,
        isLazyLoad,
      },
    },
  })),
  on(loadSelectPickerItemsResponse, (state, { id, itemType, items, isLazyLoad }) => ({
    ...state,
    [itemType]: {
      ...state[itemType],
      [id]: {
        items: isLazyLoad ? [...((state[itemType][id] || {}).items || []), ...items] : items,
        isLoading: (state[itemType][id] || {}).isLoadingItem,
        isLoadingFlagIgnored: false,
        isLoadingItem: (state[itemType][id] || {}).isLoadingItem,
        isLazyLoad: false,
      },
    },
  })),

  on(loadSelectPickerItem, (state, { id, itemType }) => ({
    ...state,
    [itemType]: {
      ...state[itemType],
      [id]: {
        ...state[itemType][id],
        isLoading: true,
        isLoadingItem: true,
      },
    },
  })),
  on(loadSelectPickerItemResponse, (state, { id, itemType, item }) => ({
    ...state,
    [itemType]: {
      ...state[itemType],
      [id]: {
        items: [...((state[itemType][id] || {}).items || []), item],
        isLoading: false,
        isLoadingFlagIgnored: false,
        isLoadingItem: false,
        isLazyLoad: false,
      },
    },
  })),
  on(loadSelectPickerItemError, (state, { id, itemType }) => ({
    ...state,
    [itemType]: {
      ...state[itemType],
      [id]: {
        items: state[itemType][id].items,
        isLoading: false,
        isLoadingFlagIgnored: false,
        isLoadingItem: false,
        isLazyLoad: false,
      },
    },
  })),

  on(loadSelectPickerItemsWithSearch, (state, { id, itemType }) => ({
    ...state,
    [itemType]: {
      ...state[itemType],
      [id]: {
        items: [],
        isLoading: true,
        isLoadingFlagIgnored: false,
        isLoadingItem: false,
        isLazyLoad: false,
      },
    },
  })),
  on(loadSelectPickerItemsWithSearchResponse, (state, { id, itemType, items }) => ({
    ...state,
    [itemType]: {
      ...state[itemType],
      [id]: {
        items,
        isLoading: false,
        isLoadingFlagIgnored: false,
        isLoadingItem: false,
        isLazyLoad: false,
      },
    },
  })),
  on(updateSelectPickerItem, (state, { id, itemType, item }) => ({
    ...state,
    [itemType]: state[itemType][id]
      ? {
          ...state[itemType],
          [id]: {
            ...state[itemType][id],
            items: (state[itemType][id].items || []).map((stateItem) =>
              stateItem.id === item.id ? { ...stateItem, ...item } : stateItem,
            ),
          },
        }
      : state[itemType],
  })),
);
