import { Injectable } from '@angular/core';
// import { init } from 'commandbar';
import { User } from '../../account/user.models';
import { Account } from '../../account/account.models';
import { AuthorizationGuard } from './authorization.guard';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { Router } from '@angular/router';
import { YoutubeDialogService } from './youtube-dialog.service';
import { savePackage } from '../../packages/store/packages.actions';
import { openShortcutsModal } from '../store/app-common.actions';
import { closeAllModals } from '../../account/store/account.actions';

@Injectable({
  providedIn: 'root',
})
export class CommandBarService {
  constructor(
    private authGuard: AuthorizationGuard,
    private store: Store<AppState>,
    private router: Router,
    private youtubeDialog: YoutubeDialogService,
  ) {}

  init() {
    // init('8d9e4566');
  }

  initUser(user: User, account: Account) {
    // (window as any).CommandBar.boot(String(user.id), {
    //   accountID: account.account_id,
    //   email: user.email,
    // });
    // (window as any).CommandBar.addMetadata('accountID', account.account_id);
    // (window as any).CommandBar.addMetadata('email', user.email);
    // this.initNavigation();
  }

  initNavigation() {
    const accountId = this.authGuard.account?.account_id;
    (window as any).CommandBar.addCallback('Keys', (args, context) => {
      this.store.dispatch(closeAllModals());
      this.router.navigate([`/${accountId}/settings/my/keys`]);
    });
    (window as any).CommandBar.addCallback('Notifications', (args, context) => {
      this.store.dispatch(closeAllModals());
      this.router.navigate([`/${accountId}/settings/my/notifications`]);
    });

    (window as any).CommandBar.addCallback('NewPackage', (args, context) => {
      this.store.dispatch(closeAllModals());
      this.router.navigate([`/${accountId}/packages/new`]);
    });

    (window as any).CommandBar.addCallback('NewCluster', (args, context) => {
      this.store.dispatch(closeAllModals());
      this.router.navigate([`/${accountId}/clusters/new`]);
    });

    (window as any).CommandBar.addCallback('NewJob', (args, context) => {
      this.store.dispatch(closeAllModals());
      this.router.navigate([`/${accountId}/jobs/new`]);
    });

    (window as any).CommandBar.addCallback('console', (args, context) => {
      this.store.dispatch(closeAllModals());
      this.router.navigate([`/${accountId}/console`]);
    });

    (window as any).CommandBar.addCallback('NewHook', (args, context) => {
      this.store.dispatch(closeAllModals());
      this.router.navigate([`/${accountId}/settings/account/hooks/new`]);
    });

    (window as any).CommandBar.addCallback('CreateDataflowPackage', (args: any, context) => {
      this.store.dispatch(closeAllModals());
      this.store.dispatch(savePackage({ packageItem: { flow_type: 'dataflow', name: args.name }, redirect: true }));
    });

    (window as any).CommandBar.addCallback('Shortcuts', (args, context) => {
      this.store.dispatch(closeAllModals());
      this.store.dispatch(openShortcutsModal());
    });

    (window as any).CommandBar.addCallback('Support', (args, context) => {
      if (window['Intercom']) {
        window['Intercom']('showNewMessage', '');
      }
    });

    this.addDocs();
    this.addYT();
  }

  addYT() {
    (window as any).CommandBar.addCallback('81_3XnaCclk', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'What is Integrate.io?',
        url: 'https://www.youtube.com/embed/81_3XnaCclk?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('77t72UpoKkI', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Win More Deals with Redshift, Salesforce & Integrate.io - Webinar',
        url: 'https://www.youtube.com/embed/77t72UpoKkI?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('Ripx0ZO6x18', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to Setup a Database Connection in Amazon RDS with Integrate.io',
        url: 'https://www.youtube.com/embed/Ripx0ZO6x18?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('FYbMsVYox8k', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to Setup a MySQL Connection within Integrate.io',
        url: 'https://www.youtube.com/embed/FYbMsVYox8k?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('jeupV05wPgQ', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to Setup a Snowflake Connection within Integrate.io',
        url: 'https://www.youtube.com/embed/jeupV05wPgQ?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('5r1Nb1tJVCA', (args, context) => {
      this.youtubeDialog.openDialog({
        title: "Integrate.io's Rest API Component Tutorial",
        url: 'https://www.youtube.com/embed/5r1Nb1tJVCA?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('2s', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to Create an Integrate.io Workflow',
        url: 'https://www.youtube.com/embed/8hgybyyBW3w&t=2s?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('luOVHUW4oQI', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Martin Gardner - A Pattern for Salesforce Data Migration',
        url: 'https://www.youtube.com/embed/luOVHUW4oQI?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('EGF0XsBIrvI', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Salesforce Heroku Connect',
        url: 'https://www.youtube.com/embed/EGF0XsBIrvI?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('TrCRzmupHl4', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How To Setup an SSH Tunnel Connection',
        url: 'https://www.youtube.com/embed/TrCRzmupHl4?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('QR0', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to Setup a PostgreSQL Connection Using Integrate.io.',
        url: 'https://www.youtube.com/embed/1Btz7Na-QR0?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('s4xehzgjZGM', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How To Setup A Microsoft SQL Server Using Integrate.io',
        url: 'https://www.youtube.com/embed/s4xehzgjZGM?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('T3Y6LT3H2ss', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How To Connect Heroku Postgres Using Integrate.io',
        url: 'https://www.youtube.com/embed/T3Y6LT3H2ss?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('IIUBhbheUh8', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How To Transfer Flat Files Using SFTP With Integrate.io',
        url: 'https://www.youtube.com/embed/IIUBhbheUh8?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('IlU1dscQ9m4', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'SFTP to Salesforce Data Integration',
        url: 'https://www.youtube.com/embed/IlU1dscQ9m4?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('bBbj6YSnTUc', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How To Setup a Salesforce Connection Using Integrate.io',
        url: 'https://www.youtube.com/embed/bBbj6YSnTUc?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('hVry_pgUugo', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Richard Clark - Slack Hack Your Salesforce - Integrating Slack with Salesforce (Demo)',
        url: 'https://www.youtube.com/embed/hVry_pgUugo?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('yicphAV80rA', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'What is ETL?',
        url: 'https://www.youtube.com/embed/yicphAV80rA?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('BYRH0PtxQHA', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Get a Complete View of Salesforce Data with MongoDB',
        url: 'https://www.youtube.com/embed/BYRH0PtxQHA?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('z07G72SnPZ0', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Integrate.io Overview',
        url: 'https://www.youtube.com/embed/z07G72SnPZ0?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('kl1rqi7o', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How To Mask Your Data With Integrate.io',
        url: 'https://www.youtube.com/embed/jM-kl1rqi7o?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('ll9n6tigc_4', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How To Setup Google BigQuery with Integrate.io',
        url: 'https://www.youtube.com/embed/ll9n6tigc_4?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('H9ozOTedY3Y', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Scheduling & Automating Pipelines With Integrate.io',
        url: 'https://www.youtube.com/embed/H9ozOTedY3Y?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('Vj2H_XN9Ys8', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How To Setup Google Cloud SQL for PostgreSQL',
        url: 'https://www.youtube.com/embed/Vj2H_XN9Ys8?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('00SzEKby7e0', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Integrate all of your data sources with Integrate.io & Heroku Postgres',
        url: 'https://www.youtube.com/embed/00SzEKby7e0?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('PX37T7i0LOo', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Google Cloud Storage Connection Tutorial',
        url: 'https://www.youtube.com/embed/PX37T7i0LOo?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('JcfyxA3KUEc', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to Setup AmazonS3 with Integrate.io',
        url: 'https://www.youtube.com/embed/JcfyxA3KUEc?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('stFqlI5x44M', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to Setup MongoDB with Integrate.io',
        url: 'https://www.youtube.com/embed/stFqlI5x44M?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('g', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Create a Customer 360 with Salesforce and Integrate.io',
        url: 'https://www.youtube.com/embed/BrnSmUtE--g?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('BCLEO10w', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to Transform and Load Data into MongoDB',
        url: 'https://www.youtube.com/embed/1H-BCLEO10w?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('A2DaCUeZmgA', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Integrate.io Customer Story - CloudFactory',
        url: 'https://www.youtube.com/embed/A2DaCUeZmgA?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('kqByuCC5xY', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Make your Salesforce the single source of truth with Integrate.io!',
        url: 'https://www.youtube.com/embed/-kqByuCC5xY?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('ySf_zithLFg', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to use the REST API connector in Integrate.io',
        url: 'https://www.youtube.com/embed/ySf_zithLFg?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('tMlEEJR_Vxg', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to use the Integrate.io workflows feature',
        url: 'https://www.youtube.com/embed/tMlEEJR_Vxg?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('_D5TemtKI38', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Integrate.io LIVE Platform Demo: Using Workflows',
        url: 'https://www.youtube.com/embed/_D5TemtKI38?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('Hlsm9epRrqs', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Salesforce Data Migrations & Attribute-Driven Design',
        url: 'https://www.youtube.com/embed/Hlsm9epRrqs?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('IWeAuPq0o', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Enriching Salesforce with Exotic Data',
        url: 'https://www.youtube.com/embed/6-IWeAuPq0o?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('tl0fL_Y_NYA', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'All About Salesforce Flows',
        url: 'https://www.youtube.com/embed/tl0fL_Y_NYA?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('O9iAFFNpo7M', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Value Stream and the Quality of Your DevOps',
        url: 'https://www.youtube.com/embed/O9iAFFNpo7M?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('juvoo8bHMl8', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Collaborating and Sharing with Reports & Dashboards',
        url: 'https://www.youtube.com/embed/juvoo8bHMl8?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('56cSEdZbYP4', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Using Integrate.io with Heroku Connect Pt. 2',
        url: 'https://www.youtube.com/embed/56cSEdZbYP4?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('Zqa0QNIgk1o', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Einstein Next Best Action',
        url: 'https://www.youtube.com/embed/Zqa0QNIgk1o?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('8', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Superhero Salesforce Records & Predictions with Einstein',
        url: 'https://www.youtube.com/embed/FFWFGCJvh-8?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('Rq_iN6Cc30I', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Using Integrate.io with Heroku Connect Pt. 1',
        url: 'https://www.youtube.com/embed/Rq_iN6Cc30I?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('2_U3KPQoWWM', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Org Merges and ETL',
        url: 'https://www.youtube.com/embed/2_U3KPQoWWM?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('yZagGWJHy94', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'How to Connect NetSuite to Integrate.io',
        url: 'https://www.youtube.com/embed/yZagGWJHy94?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('AvQw6Tdz5y0', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Integrate.io Platform Demo: Extracting and Transforming Data with Teri Morgan',
        url: 'https://www.youtube.com/embed/AvQw6Tdz5y0?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('DhkMknFvbG4', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Integrate.io Google BigQuery Access tutorial',
        url: 'https://www.youtube.com/embed/DhkMknFvbG4?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('zlUWVw06GgI', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Mandy Gu - Data Stack @ Wealthsimple',
        url: 'https://www.youtube.com/embed/zlUWVw06GgI?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('wvzY5CNIwo', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Sharing Your Expertise in the Salesforce Ecosystem and Beyond',
        url: 'https://www.youtube.com/embed/-wvzY5CNIwo?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('QUoLzmwJwvc', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Mark Jones - Redeveloping Your Established Data Model Using a Managed App',
        url: 'https://www.youtube.com/embed/QUoLzmwJwvc?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('1UfgNIInUkw', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Andre van Kampen - A Framework to Conquer Your Data Quality Blues',
        url: 'https://www.youtube.com/embed/1UfgNIInUkw?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('l7kvkaaw5Cg', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Ian Gotts - Salesforce OrgConfessions. #funny #notfunny',
        url: 'https://www.youtube.com/embed/l7kvkaaw5Cg?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('HFnwRlbP10k', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Steve Baines - Essential Soft Skills to Become an Exceptional Architect',
        url: 'https://www.youtube.com/embed/HFnwRlbP10k?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('DSTWXY7RWsE', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Teri Morgan - Consolidating Multiple Salesforce Orgs with Integrate.io',
        url: 'https://www.youtube.com/embed/DSTWXY7RWsE?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('EZiuIl1rVI8', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Kerry Townsend - Getting Started With Salesforce Marketing Cloud',
        url: 'https://www.youtube.com/embed/EZiuIl1rVI8?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('V7PlyUuq95M', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Un-pretzeling Your Org with Michelle Hansen',
        url: 'https://www.youtube.com/embed/V7PlyUuq95M?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('xtILgZ7d6rY', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Cyrill Gross - Interactive Email: Don’t do it just because you can(t)',
        url: 'https://www.youtube.com/embed/xtILgZ7d6rY?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('lytActOTpXE', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Fabien Taillon - Two Years with Salesforce DX: Tips and Tricks Learned Along the Way',
        url: 'https://www.youtube.com/embed/lytActOTpXE?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('goo3oDJQoWM', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Mark Smallcombe and Leonard Linde - Rightsizing Your Salesforce ETL Solution',
        url: 'https://www.youtube.com/embed/goo3oDJQoWM?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('YocpHa0w21Y', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Cyril Louis - Marketing Cloud Limitations (and ways around them)',
        url: 'https://www.youtube.com/embed/YocpHa0w21Y?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('24UpaoqDy5k', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Prepare and Load Data to Salesforce using Heroku Postgres & Heroku Connect | Integrate.io ETL',
        url: 'https://www.youtube.com/embed/24UpaoqDy5k?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('TUoXfJPNGs', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Salesforce Summer 2020 Release',
        url: 'https://www.youtube.com/embed/-TUoXfJPNGs?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('HSkBlCHwqkk', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Zach Behrman - SFDC Bi-Directional Sync: Pushing Data Into Salesforce',
        url: 'https://www.youtube.com/embed/HSkBlCHwqkk?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('KDo', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Sharing Your Expertise in the Salesforce Ecosystem and Beyond',
        url: 'https://www.youtube.com/embed/oVjv6ga-KDo?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('DA07SV63FbY', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Zach Behrman - SFDBi-Directional Sync: Pulling Data Out of Salesforce',
        url: 'https://www.youtube.com/embed/DA07SV63FbY?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('f5jw4LMqRrI', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Gal Steinberg - The Hidden Treasure of Data in Your Users inbox and the way to automate Everything..',
        url: 'https://www.youtube.com/embed/f5jw4LMqRrI?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('YnekTllVSBc', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Ellie Mary Finley - Intercom to Salesforce Tutorial',
        url: 'https://www.youtube.com/embed/YnekTllVSBc?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('gvghNE2sVVg', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'XForce - Salesforce Data Integrity',
        url: 'https://www.youtube.com/embed/gvghNE2sVVg?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('nF1HeCjjtbA', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Xforce Summit',
        url: 'https://www.youtube.com/embed/nF1HeCjjtbA?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('UOj9etghX9w', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Xforce Data Summit',
        url: 'https://www.youtube.com/embed/UOj9etghX9w?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('7I4ppyPt4LM', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Integrate.io - The Leading Data Integration Platform',
        url: 'https://www.youtube.com/embed/7I4ppyPt4LM?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('vlU', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Integrate.io Data Pipelines Overview | Salesforce Data to Amazon Redshift',
        url: 'https://www.youtube.com/embed/DUekfWa-vlU?autoplay=1',
      });
    });
    (window as any).CommandBar.addCallback('qC9_SKRJDIo', (args, context) => {
      this.youtubeDialog.openDialog({
        title: 'Integrate.io Facebook Ads',
        url: 'https://www.youtube.com/embed/qC9_SKRJDIo?autoplay=1',
      });
    });

    (window as any).CommandBar.addCommand({
      text: 'What is Integrate.io?',
      name: 'What is Integrate.io?',
      template: {
        type: 'callback',
        value: '81_3XnaCclk',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Win More Deals with Redshift, Salesforce & Integrate.io - Webinar',
      name: 'Win More Deals with Redshift, Salesforce & Integrate.io - Webinar',
      template: {
        type: 'callback',
        value: '77t72UpoKkI',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to Setup a Database Connection in Amazon RDS with Integrate.io',
      name: 'How to Setup a Database Connection in Amazon RDS with Integrate.io',
      template: {
        type: 'callback',
        value: 'Ripx0ZO6x18',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to Setup a MySQL Connection within Integrate.io',
      name: 'How to Setup a MySQL Connection within Integrate.io',
      template: {
        type: 'callback',
        value: 'FYbMsVYox8k',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to Setup a Snowflake Connection within Integrate.io',
      name: 'How to Setup a Snowflake Connection within Integrate.io',
      template: {
        type: 'callback',
        value: 'jeupV05wPgQ',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: "Integrate.io's Rest API Component Tutorial",
      name: "Integrate.io's Rest API Component Tutorial",
      template: {
        type: 'callback',
        value: '5r1Nb1tJVCA',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to Create an Integrate.io Workflow',
      name: 'How to Create an Integrate.io Workflow',
      template: {
        type: 'callback',
        value: '2s',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Martin Gardner - A Pattern for Salesforce Data Migration',
      name: 'Martin Gardner - A Pattern for Salesforce Data Migration',
      template: {
        type: 'callback',
        value: 'luOVHUW4oQI',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Salesforce Heroku Connect',
      name: 'Salesforce Heroku Connect',
      template: {
        type: 'callback',
        value: 'EGF0XsBIrvI',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How To Setup an SSH Tunnel Connection',
      name: 'How To Setup an SSH Tunnel Connection',
      template: {
        type: 'callback',
        value: 'TrCRzmupHl4',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to Setup a PostgreSQL Connection Using Integrate.io.',
      name: 'How to Setup a PostgreSQL Connection Using Integrate.io.',
      template: {
        type: 'callback',
        value: 'QR0',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How To Setup A Microsoft SQL Server Using Integrate.io',
      name: 'How To Setup A Microsoft SQL Server Using Integrate.io',
      template: {
        type: 'callback',
        value: 's4xehzgjZGM',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How To Connect Heroku Postgres Using Integrate.io',
      name: 'How To Connect Heroku Postgres Using Integrate.io',
      template: {
        type: 'callback',
        value: 'T3Y6LT3H2ss',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How To Transfer Flat Files Using SFTP With Integrate.io',
      name: 'How To Transfer Flat Files Using SFTP With Integrate.io',
      template: {
        type: 'callback',
        value: 'IIUBhbheUh8',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'SFTP to Salesforce Data Integration',
      name: 'SFTP to Salesforce Data Integration',
      template: {
        type: 'callback',
        value: 'IlU1dscQ9m4',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How To Setup a Salesforce Connection Using Integrate.io',
      name: 'How To Setup a Salesforce Connection Using Integrate.io',
      template: {
        type: 'callback',
        value: 'bBbj6YSnTUc',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Richard Clark - Slack Hack Your Salesforce - Integrating Slack with Salesforce (Demo)',
      name: 'Richard Clark - Slack Hack Your Salesforce - Integrating Slack with Salesforce (Demo)',
      template: {
        type: 'callback',
        value: 'hVry_pgUugo',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'What is ETL?',
      name: 'What is ETL?',
      template: {
        type: 'callback',
        value: 'yicphAV80rA',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Get a Complete View of Salesforce Data with MongoDB',
      name: 'Get a Complete View of Salesforce Data with MongoDB',
      template: {
        type: 'callback',
        value: 'BYRH0PtxQHA',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Overview',
      name: 'Integrate.io Overview',
      template: {
        type: 'callback',
        value: 'z07G72SnPZ0',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How To Mask Your Data With Integrate.io',
      name: 'How To Mask Your Data With Integrate.io',
      template: {
        type: 'callback',
        value: 'kl1rqi7o',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How To Setup Google BigQuery with Integrate.io',
      name: 'How To Setup Google BigQuery with Integrate.io',
      template: {
        type: 'callback',
        value: 'll9n6tigc_4',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Scheduling & Automating Pipelines With Integrate.io',
      name: 'Scheduling & Automating Pipelines With Integrate.io',
      template: {
        type: 'callback',
        value: 'H9ozOTedY3Y',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How To Setup Google Cloud SQL for PostgreSQL',
      name: 'How To Setup Google Cloud SQL for PostgreSQL',
      template: {
        type: 'callback',
        value: 'Vj2H_XN9Ys8',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate all of your data sources with Integrate.io & Heroku Postgres',
      name: 'Integrate all of your data sources with Integrate.io & Heroku Postgres',
      template: {
        type: 'callback',
        value: '00SzEKby7e0',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Google Cloud Storage Connection Tutorial',
      name: 'Google Cloud Storage Connection Tutorial',
      template: {
        type: 'callback',
        value: 'PX37T7i0LOo',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to Setup AmazonS3 with Integrate.io',
      name: 'How to Setup AmazonS3 with Integrate.io',
      template: {
        type: 'callback',
        value: 'JcfyxA3KUEc',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to Setup MongoDB with Integrate.io',
      name: 'How to Setup MongoDB with Integrate.io',
      template: {
        type: 'callback',
        value: 'stFqlI5x44M',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Create a Customer 360 with Salesforce and Integrate.io',
      name: 'Create a Customer 360 with Salesforce and Integrate.io',
      template: {
        type: 'callback',
        value: 'g',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to Transform and Load Data into MongoDB',
      name: 'How to Transform and Load Data into MongoDB',
      template: {
        type: 'callback',
        value: 'BCLEO10w',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Customer Story - CloudFactory',
      name: 'Integrate.io Customer Story - CloudFactory',
      template: {
        type: 'callback',
        value: 'A2DaCUeZmgA',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Make your Salesforce the single source of truth with Integrate.io!',
      name: 'Make your Salesforce the single source of truth with Integrate.io!',
      template: {
        type: 'callback',
        value: 'kqByuCC5xY',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to use the REST API connector in Integrate.io',
      name: 'How to use the REST API connector in Integrate.io',
      template: {
        type: 'callback',
        value: 'ySf_zithLFg',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to use the Integrate.io workflows feature',
      name: 'How to use the Integrate.io workflows feature',
      template: {
        type: 'callback',
        value: 'tMlEEJR_Vxg',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io LIVE Platform Demo: Using Workflows',
      name: 'Integrate.io LIVE Platform Demo: Using Workflows',
      template: {
        type: 'callback',
        value: '_D5TemtKI38',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Salesforce Data Migrations & Attribute-Driven Design',
      name: 'Salesforce Data Migrations & Attribute-Driven Design',
      template: {
        type: 'callback',
        value: 'Hlsm9epRrqs',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Enriching Salesforce with Exotic Data',
      name: 'Enriching Salesforce with Exotic Data',
      template: {
        type: 'callback',
        value: 'IWeAuPq0o',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'All About Salesforce Flows',
      name: 'All About Salesforce Flows',
      template: {
        type: 'callback',
        value: 'tl0fL_Y_NYA',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Value Stream and the Quality of Your DevOps',
      name: 'Value Stream and the Quality of Your DevOps',
      template: {
        type: 'callback',
        value: 'O9iAFFNpo7M',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Collaborating and Sharing with Reports & Dashboards',
      name: 'Collaborating and Sharing with Reports & Dashboards',
      template: {
        type: 'callback',
        value: 'juvoo8bHMl8',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Using Integrate.io with Heroku Connect Pt. 2',
      name: 'Using Integrate.io with Heroku Connect Pt. 2',
      template: {
        type: 'callback',
        value: '56cSEdZbYP4',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Einstein Next Best Action',
      name: 'Einstein Next Best Action',
      template: {
        type: 'callback',
        value: 'Zqa0QNIgk1o',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Superhero Salesforce Records & Predictions with Einstein',
      name: 'Superhero Salesforce Records & Predictions with Einstein',
      template: {
        type: 'callback',
        value: '8',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Using Integrate.io with Heroku Connect Pt. 1',
      name: 'Using Integrate.io with Heroku Connect Pt. 1',
      template: {
        type: 'callback',
        value: 'Rq_iN6Cc30I',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Org Merges and ETL',
      name: 'Org Merges and ETL',
      template: {
        type: 'callback',
        value: '2_U3KPQoWWM',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'How to Connect NetSuite to Integrate.io',
      name: 'How to Connect NetSuite to Integrate.io',
      template: {
        type: 'callback',
        value: 'yZagGWJHy94',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Platform Demo: Extracting and Transforming Data with Teri Morgan',
      name: 'Integrate.io Platform Demo: Extracting and Transforming Data with Teri Morgan',
      template: {
        type: 'callback',
        value: 'AvQw6Tdz5y0',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Google BigQuery Access tutorial',
      name: 'Integrate.io Google BigQuery Access tutorial',
      template: {
        type: 'callback',
        value: 'DhkMknFvbG4',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Mandy Gu - Data Stack @ Wealthsimple',
      name: 'Mandy Gu - Data Stack @ Wealthsimple',
      template: {
        type: 'callback',
        value: 'zlUWVw06GgI',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Sharing Your Expertise in the Salesforce Ecosystem and Beyond',
      name: 'Sharing Your Expertise in the Salesforce Ecosystem and Beyond',
      template: {
        type: 'callback',
        value: 'wvzY5CNIwo',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Mark Jones - Redeveloping Your Established Data Model Using a Managed App',
      name: 'Mark Jones - Redeveloping Your Established Data Model Using a Managed App',
      template: {
        type: 'callback',
        value: 'QUoLzmwJwvc',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Andre van Kampen - A Framework to Conquer Your Data Quality Blues',
      name: 'Andre van Kampen - A Framework to Conquer Your Data Quality Blues',
      template: {
        type: 'callback',
        value: '1UfgNIInUkw',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Ian Gotts - Salesforce OrgConfessions. #funny #notfunny',
      name: 'Ian Gotts - Salesforce OrgConfessions. #funny #notfunny',
      template: {
        type: 'callback',
        value: 'l7kvkaaw5Cg',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Steve Baines - Essential Soft Skills to Become an Exceptional Architect',
      name: 'Steve Baines - Essential Soft Skills to Become an Exceptional Architect',
      template: {
        type: 'callback',
        value: 'HFnwRlbP10k',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Teri Morgan - Consolidating Multiple Salesforce Orgs with Integrate.io',
      name: 'Teri Morgan - Consolidating Multiple Salesforce Orgs with Integrate.io',
      template: {
        type: 'callback',
        value: 'DSTWXY7RWsE',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Kerry Townsend - Getting Started With Salesforce Marketing Cloud',
      name: 'Kerry Townsend - Getting Started With Salesforce Marketing Cloud',
      template: {
        type: 'callback',
        value: 'EZiuIl1rVI8',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Un-pretzeling Your Org with Michelle Hansen',
      name: 'Un-pretzeling Your Org with Michelle Hansen',
      template: {
        type: 'callback',
        value: 'V7PlyUuq95M',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Cyrill Gross - Interactive Email: Don’t do it just because you can(t)',
      name: 'Cyrill Gross - Interactive Email: Don’t do it just because you can(t)',
      template: {
        type: 'callback',
        value: 'xtILgZ7d6rY',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Fabien Taillon - Two Years with Salesforce DX: Tips and Tricks Learned Along the Way',
      name: 'Fabien Taillon - Two Years with Salesforce DX: Tips and Tricks Learned Along the Way',
      template: {
        type: 'callback',
        value: 'lytActOTpXE',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Mark Smallcombe and Leonard Linde - Rightsizing Your Salesforce ETL Solution',
      name: 'Mark Smallcombe and Leonard Linde - Rightsizing Your Salesforce ETL Solution',
      template: {
        type: 'callback',
        value: 'goo3oDJQoWM',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Cyril Louis - Marketing Cloud Limitations (and ways around them)',
      name: 'Cyril Louis - Marketing Cloud Limitations (and ways around them)',
      template: {
        type: 'callback',
        value: 'YocpHa0w21Y',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Prepare and Load Data to Salesforce using Heroku Postgres & Heroku Connect | Integrate.io ETL',
      name: 'Prepare and Load Data to Salesforce using Heroku Postgres & Heroku Connect | Integrate.io ETL',
      template: {
        type: 'callback',
        value: '24UpaoqDy5k',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Salesforce Summer 2020 Release',
      name: 'Salesforce Summer 2020 Release',
      template: {
        type: 'callback',
        value: 'TUoXfJPNGs',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Zach Behrman - SFDC Bi-Directional Sync: Pushing Data Into Salesforce',
      name: 'Zach Behrman - SFDC Bi-Directional Sync: Pushing Data Into Salesforce',
      template: {
        type: 'callback',
        value: 'HSkBlCHwqkk',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Sharing Your Expertise in the Salesforce Ecosystem and Beyond',
      name: 'Sharing Your Expertise in the Salesforce Ecosystem and Beyond',
      template: {
        type: 'callback',
        value: 'KDo',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Zach Behrman - SFDBi-Directional Sync: Pulling Data Out of Salesforce',
      name: 'Zach Behrman - SFDBi-Directional Sync: Pulling Data Out of Salesforce',
      template: {
        type: 'callback',
        value: 'DA07SV63FbY',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Gal Steinberg - The Hidden Treasure of Data in Your Users inbox and the way to automate Everything..',
      name: 'Gal Steinberg - The Hidden Treasure of Data in Your Users inbox and the way to automate Everything..',
      template: {
        type: 'callback',
        value: 'f5jw4LMqRrI',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Ellie Mary Finley - Intercom to Salesforce Tutorial',
      name: 'Ellie Mary Finley - Intercom to Salesforce Tutorial',
      template: {
        type: 'callback',
        value: 'YnekTllVSBc',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'XForce - Salesforce Data Integrity',
      name: 'XForce - Salesforce Data Integrity',
      template: {
        type: 'callback',
        value: 'gvghNE2sVVg',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Xforce Summit',
      name: 'Xforce Summit',
      template: {
        type: 'callback',
        value: 'nF1HeCjjtbA',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Xforce Data Summit',
      name: 'Xforce Data Summit',
      template: {
        type: 'callback',
        value: 'UOj9etghX9w',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io - The Leading Data Integration Platform',
      name: 'Integrate.io - The Leading Data Integration Platform',
      template: {
        type: 'callback',
        value: '7I4ppyPt4LM',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Data Pipelines Overview | Salesforce Data to Amazon Redshift',
      name: 'Integrate.io Data Pipelines Overview | Salesforce Data to Amazon Redshift',
      template: {
        type: 'callback',
        value: 'vlU',
      },
      category: 4170,
      icon: 'videocamera',
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Facebook Ads',
      name: 'Integrate.io Facebook Ads',
      template: {
        type: 'callback',
        value: 'qC9_SKRJDIo',
      },
      category: 4170,
      icon: 'videocamera',
    });
  }

  addDocs() {
    (window as any).CommandBar.addCommand({
      text: 'ETL Knowledge Base',
      name: 'ETL Knowledge Base',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Defining connections',
      name: 'Defining connections',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/defining-connections/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Programming and API',
      name: 'Programming and API',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/programming-and-api/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetYear',
      name: 'GetYear',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getyear/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'LAST_INDEX_OF',
      name: 'LAST_INDEX_OF',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/last-index-of/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to relational databases on Amazon RDS',
      name: 'Allowing Integrate.io ETL access to relational databases on Amazon RDS',
      template: {
        type: 'link',
        value:
          'https://www.integrate.io/docs/etl/allowing-integrateio-direct-access-to-relational-databases-on-amazon-web-services-rds/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'MinutesBetween',
      name: 'MinutesBetween',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/minutesbetween/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SIZE',
      name: 'SIZE',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/size/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'TRIM',
      name: 'TRIM',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/trim/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'TOMAP',
      name: 'TOMAP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tomap/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CCurlPoll',
      name: 'CCurlPoll',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/ccurlpoll/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ACOS',
      name: 'ACOS',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/acos/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'WeeksBetween',
      name: 'WeeksBetween',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/weeksbetween/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'JsonExtractScalar',
      name: 'JsonExtractScalar',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/jsonextractscalar/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'REGEX_EXTRACT',
      name: 'REGEX_EXTRACT',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/regex-extract/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'REGEX_FIND_ALL',
      name: 'REGEX_FIND_ALL',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/regex-find-all/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExtractUrlPart',
      name: 'ExtractUrlPart',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/extracturlpart/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'COALESCE',
      name: 'COALESCE',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/coalesce/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Our Team.',
      name: 'Our Team.',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/about/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'RANDOM',
      name: 'RANDOM',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/random/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Salesforce (SOAP)',
      name: 'Allowing Integrate.io ETL access to my data on Salesforce (SOAP)',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-salesforce-soap/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to PostgreSQL Databases',
      name: 'Allowing Integrate.io ETL access to PostgreSQL Databases',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-postgresql-databases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to Marketing Cloud (SOAP)',
      name: 'Allowing Integrate.io ETL access to Marketing Cloud (SOAP)',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-marketing-cloud-soap/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'LCFIRST',
      name: 'LCFIRST',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/lcfirst/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Facebook Ads Insights Source',
      name: 'Using components: Facebook Ads Insights Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-facebook-ads-insights-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExtractUABrowserID',
      name: 'ExtractUABrowserID',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/extractuabrowserid/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Encrypt and Decrypt Sensitive Data',
      name: 'Encrypt and Decrypt Sensitive Data',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-do-i-encrypt-and-decrypt-sensitive-data/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Viewing jobs in a specific cluster',
      name: 'Viewing jobs in a specific cluster',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/viewing-jobs-in-a-specific-cluster/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Snowflake Destination',
      name: 'Using components: Snowflake Destination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-snowflake-destination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISODatetimeToTimestampFormat',
      name: 'ISODatetimeToTimestampFormat',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isodatetimetotimestampformat/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'POW',
      name: 'POW',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/pow/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Creating a workflow',
      name: 'Creating a workflow',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/creating-a-workflow/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'LOWER',
      name: 'LOWER',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/lower/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Running and monitoring jobs',
      name: 'Running and monitoring jobs',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/running-and-monitoring-jobs/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Curl',
      name: 'Curl',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/curl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'xplenty_rb.transform',
      name: 'xplenty_rb.transform',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/xplenty-rbtransform/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'DateDiffMonths',
      name: 'DateDiffMonths',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/datediffmonths/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: "Integrate.io ETL's clusters",
      name: "Integrate.io ETL's clusters",
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/integrateio-etls-clusters/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Managing your account settings',
      name: 'Managing your account settings',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/managing-your-account-settings/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SHA1',
      name: 'SHA1',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sha1/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Google Cloud Storage',
      name: 'Allowing Integrate.io ETL access to my data on Google Cloud Storage',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-google-cloud-storage/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to data on Amazon S3',
      name: 'Allowing Integrate.io ETL access to data on Amazon S3',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-data-on-amazon-s3/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'xplenty_rb.concat_bag',
      name: 'xplenty_rb.concat_bag',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/xplenty-rbconcat-bag/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'OsMajor',
      name: 'OsMajor',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/osmajor/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Changing your password',
      name: 'Changing your password',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/changing-your-password/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on YouTube',
      name: 'Allowing Integrate.io ETL access to my data on YouTube',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-youtube/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Getting started',
      name: 'Getting started',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/getting-started/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'How Do I Process JSON data',
      name: 'How Do I Process JSON data',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-do-i-process-json-data/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'OsMinor',
      name: 'OsMinor',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/osminor/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'xplenty_rb.bags_intersect',
      name: 'xplenty_rb.bags_intersect',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/xplenty-rbbags-intersect/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'TAN',
      name: 'TAN',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tan/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Set up a reverse SSH tunnel from Windows',
      name: 'Set up a reverse SSH tunnel from Windows',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/set-up-a-reverse-ssh-tunnel-from-windows/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Validating a package',
      name: 'Validating a package',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/validating-a-package/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExecuteBQInsert',
      name: 'ExecuteBQInsert',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/executebqinsert/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'DateDiffMinutes',
      name: 'DateDiffMinutes',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/datediffminutes/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetWeekYear',
      name: 'GetWeekYear',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getweekyear/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExtractUABrowserName',
      name: 'ExtractUABrowserName',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/extractuabrowsername/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'TOKENIZE',
      name: 'TOKENIZE',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tokenize/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Creating a new package',
      name: 'Creating a new package',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/creating-a-new-package/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'UUID',
      name: 'UUID',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/uuid/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Viewing and setting web and email notifications',
      name: 'Viewing and setting web and email notifications',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/viewing-and-setting-web-and-email-notifications/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'AddDuration',
      name: 'AddDuration',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/addduration/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetBytes',
      name: 'GetBytes',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getbytes/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'OsPatchMinor',
      name: 'OsPatchMinor',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/ospatchminor/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'MonthsBetween',
      name: 'MonthsBetween',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/monthsbetween/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io ETL Functions',
      name: 'Integrate.io ETL Functions',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/integrateio-etl-functions/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Transform the E-Commerce Experience of Your Customers',
      name: 'Transform the E-Commerce Experience of Your Customers',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/ecommerce/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetMinute',
      name: 'GetMinute',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getminute/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISOToMonth',
      name: 'ISOToMonth',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isotomonth/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'LatitudeFromIP',
      name: 'LatitudeFromIP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/latitudefromip/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExecuteSqlDouble',
      name: 'ExecuteSqlDouble',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/executesqldouble/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'MilliSecondsBetween',
      name: 'MilliSecondsBetween',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/millisecondsbetween/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'XPathToBag',
      name: 'XPathToBag',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/xpathtobag/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Business Intelligence',
      name: 'Business Intelligence',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/business-intelligence/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ParseIntOrDefault',
      name: 'ParseIntOrDefault',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/parseintordefault/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ToHexString',
      name: 'ToHexString',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tohexstring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to Facebook Ads Insights data',
      name: 'Allowing Integrate.io ETL access to Facebook Ads Insights data',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-facebook-ads-insights-data/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my Redshift cluster',
      name: 'Allowing Integrate.io ETL access to my Redshift cluster',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-redshift-cluster/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'How do I select my file input format',
      name: 'How do I select my file input format',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-do-i-select-my-file-input-format/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Google Sheets',
      name: 'Allowing Integrate.io ETL access to my data on Google Sheets',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-google-sheets/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Google Adwords source',
      name: 'Using components: Google Adwords source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-google-adwords-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Editing and scaling clusters',
      name: 'Editing and scaling clusters',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/editing-and-scaling-clusters/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SHA512',
      name: 'SHA512',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sha512/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BinaryCCurl',
      name: 'BinaryCCurl',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/binaryccurl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to Microsoft SQL Server Databases',
      name: 'Allowing Integrate.io ETL access to Microsoft SQL Server Databases',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-microsoft-sql-server-databases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'TANH',
      name: 'TANH',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tanh/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: MongoDB Destination',
      name: 'Using components: MongoDB Destination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-mongodb-destination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Advanced Package Search',
      name: 'Advanced Package Search',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/advanced-search/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'IsBase64',
      name: 'IsBase64',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isbase64/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Our Partners',
      name: 'Our Partners',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/partners/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'DaysBetween',
      name: 'DaysBetween',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/daysbetween/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'DeviceFamily',
      name: 'DeviceFamily',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/devicefamily/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Signing out from Integrate.io ETL',
      name: 'Signing out from Integrate.io ETL',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/signing-out-from-integrateio-etl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Explore the world of data integration',
      name: 'Explore the world of data integration',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/webinars/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BrowserFamily',
      name: 'BrowserFamily',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/browserfamily/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Blog',
      name: 'Integrate.io Blog',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/jp/blog/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'MD2',
      name: 'MD2',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/md2/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Empower the Healthcare Experience',
      name: 'Empower the Healthcare Experience',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/healthcare/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: "Integrate.io ETL's Workspaces",
      name: "Integrate.io ETL's Workspaces",
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/workspaces/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Creating a cluster',
      name: 'Creating a cluster',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/creating-a-cluster/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Customer 360',
      name: 'Customer 360',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/customer-360/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ParseFloatOrDefault',
      name: 'ParseFloatOrDefault',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/parsefloatordefault/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Rest API Source',
      name: 'Using components: Rest API Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-rest-api-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Google AdWords',
      name: 'Allowing Integrate.io ETL access to my data on Google AdWords',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-google-adwords/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Union Transformation',
      name: 'Using components: Union Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-union-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my Snowflake account',
      name: 'Allowing Integrate.io ETL access to my Snowflake account',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-snowflake-account/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'DateDiffYears',
      name: 'DateDiffYears',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/datediffyears/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'UCFIRST',
      name: 'UCFIRST',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/ucfirst/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'xplenty_rb.bags_union',
      name: 'xplenty_rb.bags_union',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/xplenty-rbbags-union/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'OsVersion',
      name: 'OsVersion',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/osversion/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Modifying your personal settings',
      name: 'Modifying your personal settings',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/modifying-your-personal-settings/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Maximize customer satisfaction and brand loyalty',
      name: 'Maximize customer satisfaction and brand loyalty',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/industries/retail/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CONCAT',
      name: 'CONCAT',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/concat/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Decrypt',
      name: 'Decrypt',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/decrypt/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CurlPoll',
      name: 'CurlPoll',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/curlpoll/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'OsFamily',
      name: 'OsFamily',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/osfamily/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Bing Ads',
      name: 'Allowing Integrate.io ETL access to my data on Bing Ads',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-bing-ads/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'OsFullName',
      name: 'OsFullName',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/osfullname/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Join Tranformation',
      name: 'Using components: Join Tranformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-join-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Salesforce source',
      name: 'Using components: Salesforce source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-salesforce-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Salesforce',
      name: 'Allowing Integrate.io ETL access to my data on Salesforce',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-salesforce/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Secure File Transfer Protocol (SFTP)',
      name: 'Allowing Integrate.io ETL access to my data on Secure File Transfer Protocol (SFTP)',
      template: {
        type: 'link',
        value:
          'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-secure-file-transfer-protocol-sftp/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'The Complete Integration Layer For Your Data Warehouse',
      name: 'The Complete Integration Layer For Your Data Warehouse',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/product/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISOToHour',
      name: 'ISOToHour',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isotohour/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SUBSTRING',
      name: 'SUBSTRING',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/substring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'xplenty_rb.transform_bag',
      name: 'xplenty_rb.transform_bag',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/xplenty-rbtransform-bag/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ABS',
      name: 'ABS',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/abs/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Component Previewer',
      name: 'Component Previewer',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/component-previewer/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'To view job progress details',
      name: 'To view job progress details',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/to-view-job-progress-details/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Salesforce Team',
      name: 'Salesforce Team',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/salesforce-team/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SHA256WithRSA',
      name: 'SHA256WithRSA',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sha256withrsa/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'IsEmpty',
      name: 'IsEmpty',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isempty/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Selecting a plan',
      name: 'Selecting a plan',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/selecting-a-plan/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'The Fastest Real-time Data Replication On The Market',
      name: 'The Fastest Real-time Data Replication On The Market',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/product/cdc/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Selecting the account to work in',
      name: 'Selecting the account to work in',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/selecting-the-account-to-work-in/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Working in the package designer',
      name: 'Working in the package designer',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/working-in-the-package-designer/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Filter Transformation',
      name: 'Using components: Filter Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-filter-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CurlWithPagination',
      name: 'CurlWithPagination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/curlwithpagination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetHour',
      name: 'GetHour',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/gethour/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SPRINTF',
      name: 'SPRINTF',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sprintf/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Creating a new package in New Integrate.io ETL',
      name: 'Creating a new package in New Integrate.io ETL',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/creating-a-new-package-in-integrateio-etl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExecuteSqlLong',
      name: 'ExecuteSqlLong',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/executesqllong/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CCurl',
      name: 'CCurl',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/ccurl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Aggregate Transformation',
      name: 'Using components: Aggregate Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-aggregate-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'HoursBetween',
      name: 'HoursBetween',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/hoursbetween/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Connectivity And Security',
      name: 'Connectivity And Security',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/connectivity-and-security/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BagToString',
      name: 'BagToString',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/bagtostring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: "Integrate.io's Privacy Policy",
      name: "Integrate.io's Privacy Policy",
      template: {
        type: 'link',
        value: 'https://www.integrate.io/privacy/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Google Spanner Destination',
      name: 'Using components: Google Spanner Destination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-google-spanner-destination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Blog',
      name: 'Integrate.io Blog',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/ko/blog/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CountryCodeFromIP',
      name: 'CountryCodeFromIP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/countrycodefromip/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my Google BigQuery dataset',
      name: 'Allowing Integrate.io ETL access to my Google BigQuery dataset',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-google-bigquery-dataset/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ToDate',
      name: 'ToDate',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/todate/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'EXP',
      name: 'EXP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/exp/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Engineering Team',
      name: 'Engineering Team',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/engineering-team/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Running jobs',
      name: 'Running jobs',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/running-jobs/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Data Science Team',
      name: 'Data Science Team',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/data-science-team/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'JsonExtract',
      name: 'JsonExtract',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/jsonextract/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: "How Do I Import or Export a Package's JSON Source Code",
      name: "How Do I Import or Export a Package's JSON Source Code",
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-do-i-import-or-export-a-packages-json-source-code/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Terms of Services',
      name: 'Integrate.io Terms of Services',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/tos/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SwitchTimeZone',
      name: 'SwitchTimeZone',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/switchtimezone/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExtractSearchQuery',
      name: 'ExtractSearchQuery',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/extractsearchquery/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Transform the E-Learning Experience',
      name: 'Transform the E-Learning Experience',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/e-learning/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Pipeline Development Lifecycle Best Practices',
      name: 'Pipeline Development Lifecycle Best Practices',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/migrate-from-development-to-production/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io, designed for e-commerce',
      name: 'Integrate.io, designed for e-commerce',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/integrations/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Google BigQuery Source',
      name: 'Using components: Google BigQuery Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-google-bigquery-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Base64EncodeToString',
      name: 'Base64EncodeToString',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/base64encodetostring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Cube transformation',
      name: 'Using components: Cube transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-cube-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Single Sign-On',
      name: 'Single Sign-On',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/single-sign-on/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Monitoring clusters',
      name: 'Monitoring clusters',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/monitoring-clusters/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BagToTuple',
      name: 'BagToTuple',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/bagtotuple/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ToDateCustomFormats',
      name: 'ToDateCustomFormats',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/todatecustomformats/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetDay',
      name: 'GetDay',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getday/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Create a package from a template',
      name: 'Create a package from a template',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/create-a-package-from-a-template/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ContinentCodeFromIP',
      name: 'ContinentCodeFromIP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/continentcodefromip/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: File Storage Destination',
      name: 'Using components: File Storage Destination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-file-storage-destination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BrowserMajor',
      name: 'BrowserMajor',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/browsermajor/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ASIN',
      name: 'ASIN',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/asin/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'How Do I Use Package Version Control',
      name: 'How Do I Use Package Version Control',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-do-i-use-package-version-control/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CCurlWithPagination',
      name: 'CCurlWithPagination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/ccurlwithpagination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'LOG',
      name: 'LOG',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/log/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Fivetran Alternative',
      name: 'Fivetran Alternative',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/compare/fivetran-alternative/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'The #1 Secure Data Warehouse Integration Platform. Your Security is Our Priority.',
      name: 'The #1 Secure Data Warehouse Integration Platform. Your Security is Our Priority.',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/security/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'How to create a delivery',
      name: 'How to create a delivery',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-to-create-a-delivery/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Hadoop Distributed File System (HDFS)',
      name: 'Allowing Integrate.io ETL access to my data on Hadoop Distributed File System (HDFS)',
      template: {
        type: 'link',
        value:
          'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-hadoop-distributed-file-system-hdfs/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetMilliSecond',
      name: 'GetMilliSecond',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getmillisecond/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: "Integrate.io ETL's API",
      name: "Integrate.io ETL's API",
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/integrateio-etls-api/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'URLEncode',
      name: 'URLEncode',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/urlencode/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Power Your SaaS Business',
      name: 'Power Your SaaS Business',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/saas/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Reverse',
      name: 'Reverse',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/reverse/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'URLDecode',
      name: 'URLDecode',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/urldecode/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Google Analytics',
      name: 'Allowing Integrate.io ETL access to my data on Google Analytics',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-google-analytics/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Data Ingestion',
      name: 'Data Ingestion',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/data-ingestion/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Gain cross-channel visibility and centralize your marketing reporting',
      name: 'Gain cross-channel visibility and centralize your marketing reporting',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/industries/advertising/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CBRT',
      name: 'CBRT',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/cbrt/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Email Hooks',
      name: 'Email Hooks',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/email-hooks/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'RegionCodeFromIP',
      name: 'RegionCodeFromIP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/regioncodefromip/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Books and Guides',
      name: 'Books and Guides',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/books-and-guides/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Data warehouse analytics',
      name: 'Data warehouse analytics',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/product/performance-monitoring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using clusters',
      name: 'Using clusters',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/using-clusters/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Increase security and optimize long-term strategies',
      name: 'Increase security and optimize long-term strategies',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/industries/hospitality/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Distinct transformation',
      name: 'Using components: Distinct transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-distinct-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CityNameFromIP',
      name: 'CityNameFromIP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/citynamefromip/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Sort Transformation',
      name: 'Using components: Sort Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-sort-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Base64Decode',
      name: 'Base64Decode',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/base64decode/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'How Do I Work with Datetime Data',
      name: 'How Do I Work with Datetime Data',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-do-i-work-with-datetime-data/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ParseLongOrDefault',
      name: 'ParseLongOrDefault',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/parselongordefault/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Cross Join Transformation',
      name: 'Using components: Cross Join Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-cross-join-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to Google Cloud SQL for MySQL databases',
      name: 'Allowing Integrate.io ETL access to Google Cloud SQL for MySQL databases',
      template: {
        type: 'link',
        value:
          'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-google-cloud-sql-for-mysql-databases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CurrentTime',
      name: 'CurrentTime',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/currenttime/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'OsPatch',
      name: 'OsPatch',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/ospatch/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'QueryStringToMap',
      name: 'QueryStringToMap',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/querystringtomap/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Viewing all jobs in the account',
      name: 'Viewing all jobs in the account',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/viewing-all-jobs-in-the-account/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Clone transformation',
      name: 'Using components: Clone transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-clone-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'All articles',
      name: 'All articles',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/all-articles/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using pattern-matching in source component paths',
      name: 'Using pattern-matching in source component paths',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-pattern-matching-in-source-component-paths/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ROUND_TO',
      name: 'ROUND_TO',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/round-to/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Creating packages',
      name: 'Creating packages',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/creating-packages/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Flatten',
      name: 'Flatten',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/flatten/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Base64DecodeToString',
      name: 'Base64DecodeToString',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/base64decodetostring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'List of Salesforce objects',
      name: 'List of Salesforce objects',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/list-of-salesforce-objects/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'We have combined the best in data to provide you with Integrate.io',
      name: 'We have combined the best in data to provide you with Integrate.io',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'LongitudeFromIP',
      name: 'LongitudeFromIP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/longitudefromip/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io ETL terminology',
      name: 'Integrate.io ETL terminology',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/integrateio-etl-terminology/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Service Hooks',
      name: 'Service Hooks',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/service-hooks/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BrowserPatch',
      name: 'BrowserPatch',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/browserpatch/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Scheduling Package Execution',
      name: 'Scheduling Package Execution',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/scheduling-package-execution/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ToUnixTime',
      name: 'ToUnixTime',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tounixtime/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BinaryCurl',
      name: 'BinaryCurl',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/binarycurl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'PagerDuty Hooks',
      name: 'PagerDuty Hooks',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/pagerduty-hooks/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: NetSuite Source',
      name: 'Using components: NetSuite Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-netsuite-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Window Transformation',
      name: 'Using components: Window Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-window-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Yes, we can integrate with',
      name: 'Yes, we can integrate with',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/calendly/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Select Transformation',
      name: 'Using components: Select Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-select-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Google Drive',
      name: 'Allowing Integrate.io ETL access to my data on Google Drive',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-google-drive/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Database Source',
      name: 'Using components: Database Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-database-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SubtractDuration',
      name: 'SubtractDuration',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/subtractduration/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Data Security',
      name: 'Data Security',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/data-security/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'DateDiffHours',
      name: 'DateDiffHours',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/datediffhours/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExecuteSqlNonQuery',
      name: 'ExecuteSqlNonQuery',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/executesqlnonquery/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on File Transfer Protocol Secure (FTPS)',
      name: 'Allowing Integrate.io ETL access to my data on File Transfer Protocol Secure (FTPS)',
      template: {
        type: 'link',
        value:
          'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-file-transfer-protocol-secure-ftps/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Base64Encode',
      name: 'Base64Encode',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/base64encode/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Viewing job error details for failed jobs',
      name: 'Viewing job error details for failed jobs',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/viewing-job-error-details-for-failed-jobs/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'DistInMilesHaversine',
      name: 'DistInMilesHaversine',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/distinmileshaversine/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CSVSPLIT',
      name: 'CSVSPLIT',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/csvsplit/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to Azure SQL Databases',
      name: 'Allowing Integrate.io ETL access to Azure SQL Databases',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-azure-sql-databases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Bing Ads Source',
      name: 'Using components: Bing Ads Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-bing-ads-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: '2-Factor Authentication',
      name: '2-Factor Authentication',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/2-factor-authentication/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExecuteSqlString',
      name: 'ExecuteSqlString',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/executesqlstring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using ISO 8601 string functions',
      name: 'Using ISO 8601 string functions',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-iso-8601-string-functions/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'LOG10',
      name: 'LOG10',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/log10/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SHA384',
      name: 'SHA384',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sha384/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetWeek',
      name: 'GetWeek',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getweek/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISOToWeek',
      name: 'ISOToWeek',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isotoweek/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: "Integrate.io ETL's IP list",
      name: "Integrate.io ETL's IP list",
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/integrateio-etls-ip-list/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'System and pre-defined variables',
      name: 'System and pre-defined variables',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/system-and-pre-defined-variables/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'How Do I ...',
      name: 'How Do I ...',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/how-do-i/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Eliminate the biggest bottleneck in modern IT.',
      name: 'Eliminate the biggest bottleneck in modern IT.',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/product/api-services/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISODatetimeToString',
      name: 'ISODatetimeToString',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isodatetimetostring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'REPLACE',
      name: 'REPLACE',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/replace/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: File Storage Source',
      name: 'Using components: File Storage Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-file-storage-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to Oracle databases',
      name: 'Allowing Integrate.io ETL access to Oracle databases',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-oracle-databases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExecuteSqlDateTime',
      name: 'ExecuteSqlDateTime',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/executesqldatetime/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io Blog',
      name: 'Integrate.io Blog',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/blog/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using Expressions in Integrate.io ETL',
      name: 'Using Expressions in Integrate.io ETL',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-expressions-in-integrateio-etl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BrowserFullName',
      name: 'BrowserFullName',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/browserfullname/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Sleep',
      name: 'Sleep',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sleep/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Monitoring jobs',
      name: 'Monitoring jobs',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/monitoring-jobs/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SIN',
      name: 'SIN',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sin/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'TOBAG',
      name: 'TOBAG',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tobag/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Viewing completed tasks outputs',
      name: 'Viewing completed tasks outputs',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/viewing-completed-tasks-outputs/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Limit Transformation',
      name: 'Using components: Limit Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-limit-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'JsonStringToMap',
      name: 'JsonStringToMap',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/jsonstringtomap/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using and setting variables in your packages',
      name: 'Using and setting variables in your packages',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-and-setting-variables-in-your-packages/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'TOTUPLE',
      name: 'TOTUPLE',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/totuple/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'New Connector: DB2',
      name: 'New Connector: DB2',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-db2/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my Google Cloud Spanner instance',
      name: 'Allowing Integrate.io ETL access to my Google Cloud Spanner instance',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-google-cloud-spanner-instance/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Instagram',
      name: 'Allowing Integrate.io ETL access to my data on Instagram',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-instagram/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Terminating a cluster',
      name: 'Terminating a cluster',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/terminating-a-cluster/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my server behind a firewall',
      name: 'Allowing Integrate.io ETL access to my server behind a firewall',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-server-behind-a-firewall/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to MySQL Databases',
      name: 'Allowing Integrate.io ETL access to MySQL Databases',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-mysql-databases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'MD5',
      name: 'MD5',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/md5/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Transform the Travel Experience of Your Customers',
      name: 'Transform the Travel Experience of Your Customers',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/travel/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'How Do I Process a Different Encoding',
      name: 'How Do I Process a Different Encoding',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-do-i-process-a-different-encoding/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: MongoDB Source',
      name: 'Using components: MongoDB Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-mongodb-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Linkedin',
      name: 'Allowing Integrate.io ETL access to my data on Linkedin',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-linkedin/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Salesforce Destination',
      name: 'Using components: Salesforce Destination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-salesforce-destination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'How Do I Move data incrementally between databases',
      name: 'How Do I Move data incrementally between databases',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-do-i-move-data-incrementally-between-databases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BrowserMinor',
      name: 'BrowserMinor',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/browserminor/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ToString',
      name: 'ToString',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tostring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SHA256',
      name: 'SHA256',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sha256/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'XPath',
      name: 'XPath',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/xpath/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISOSubtractDuration',
      name: 'ISOSubtractDuration',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isosubtractduration/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Alooma Alternative',
      name: 'Alooma Alternative',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/compare/alooma-alternative/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Slack Hooks',
      name: 'Slack Hooks',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/slack-hooks/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to Google Cloud SQL for PostgreSQL databases',
      name: 'Allowing Integrate.io ETL access to Google Cloud SQL for PostgreSQL databases',
      template: {
        type: 'link',
        value:
          'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-google-cloud-sql-for-postgresql-databases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetMonth',
      name: 'GetMonth',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getmonth/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Length',
      name: 'Length',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/length/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Join happy businesses that already choose Integrate.io',
      name: 'Join happy businesses that already choose Integrate.io',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/customers/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'BrowserVersion',
      name: 'BrowserVersion',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/browserversion/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SQRT',
      name: 'SQRT',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sqrt/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Rank Transformation',
      name: 'Using components: Rank Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-rank-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ClockTime',
      name: 'ClockTime',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/clocktime/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CountryNameFromIP',
      name: 'CountryNameFromIP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/countrynamefromip/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ContinentNameFromIP',
      name: 'ContinentNameFromIP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/continentnamefromip/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExtractQueryStringParam',
      name: 'ExtractQueryStringParam',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/extractquerystringparam/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Amazon Redshift Source',
      name: 'Using components: Amazon Redshift Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-amazon-redshift-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to MongoDB',
      name: 'Allowing Integrate.io ETL access to MongoDB',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-mongodb/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'STRSPLITTOBAG',
      name: 'STRSPLITTOBAG',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/strsplittobag/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Integrate.io ETL Support Rates',
      name: 'Integrate.io ETL Support Rates',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/integrateio-etl-support-rates/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'New Releases',
      name: 'New Releases',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/new-releases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'How Do I Read data from file storage incrementally',
      name: 'How Do I Read data from file storage incrementally',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/how-do-i-read-data-from-file-storage-incrementally/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISOAddDuration',
      name: 'ISOAddDuration',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isoaddduration/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Shopify',
      name: 'Allowing Integrate.io ETL access to my data on Shopify',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-shopify/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Managing your Integrate.io ETL account members',
      name: 'Managing your Integrate.io ETL account members',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/managing-your-integrateio-etl-account-members/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExecuteSqlFloat',
      name: 'ExecuteSqlFloat',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/executesqlfloat/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'RegionNameFromIP',
      name: 'RegionNameFromIP',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/regionnamefromip/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISOToSecond',
      name: 'ISOToSecond',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isotosecond/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: "Integrate.io ETL's packages",
      name: "Integrate.io ETL's packages",
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/integrateio-etls-packages/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'MapToQueryString',
      name: 'MapToQueryString',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/maptoquerystring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to NetSuite',
      name: 'Allowing Integrate.io ETL access to NetSuite',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-netsuite/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Google Cloud Spanner Source',
      name: 'Using components: Google Cloud Spanner Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-google-cloud-spanner-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ByteArrayToString',
      name: 'ByteArrayToString',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/bytearraytostring/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Setting account member roles',
      name: 'Setting account member roles',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/setting-account-member-roles/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'DateDiffDays',
      name: 'DateDiffDays',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/datediffdays/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'The Low-code ETL to Reverse ETL Tool For All',
      name: 'The Low-code ETL to Reverse ETL Tool For All',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/product/etl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allow Integrate.io ETL access to my data on Azure Blob Storage',
      name: 'Allow Integrate.io ETL access to my data on Azure Blob Storage',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allow-integrateio-etl-access-to-my-data-on-azure-blob-storage/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Understand Your Product Usage',
      name: 'Understand Your Product Usage',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/media-and-entertainment/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ETLeap Alternative',
      name: 'ETLeap Alternative',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/compare/etleap-alternative/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'DateDiffSeconds',
      name: 'DateDiffSeconds',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/datediffseconds/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'CEIL',
      name: 'CEIL',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/ceil/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'GetSecond',
      name: 'GetSecond',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getsecond/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'xplenty_rb.bags_difference',
      name: 'xplenty_rb.bags_difference',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/xplenty-rbbags-difference/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'FLOOR',
      name: 'FLOOR',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/floor/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Data Team',
      name: 'Data Team',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/data-team/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Viewing and modifying your billing information and account usage',
      name: 'Viewing and modifying your billing information and account usage',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/viewing-and-modifying-your-billing-information-and-account-usage/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'COS',
      name: 'COS',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/cos/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'StringToISODatetime',
      name: 'StringToISODatetime',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/stringtoisodatetime/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Stitch Alternative',
      name: 'Stitch Alternative',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/compare/stitch-alternative/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'JsonStringToBag',
      name: 'JsonStringToBag',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/jsonstringtobag/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'YearsBetween',
      name: 'YearsBetween',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/yearsbetween/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ROUND',
      name: 'ROUND',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/round/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Managing your SSH public keys',
      name: 'Managing your SSH public keys',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/managing-your-ssh-keys/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Matillion Alternative',
      name: 'Matillion Alternative',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/compare/matillion-alternative/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Database Destination',
      name: 'Using components: Database Destination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-database-destination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Talend Alternative',
      name: 'Talend Alternative',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/compare/talend-alternative/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Sample Transformation',
      name: 'Using components: Sample Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-sample-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'PlatformFamily',
      name: 'PlatformFamily',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/platformfamily/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to Heroku Postgres Databases',
      name: 'Allowing Integrate.io ETL access to Heroku Postgres Databases',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-heroku-postgres-databases/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'REGEX_EXTRACT_ALL',
      name: 'REGEX_EXTRACT_ALL',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/regex-extract-all/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Encrypt',
      name: 'Encrypt',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/encrypt/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Reverse ETL',
      name: 'Reverse ETL',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/solutions/reverse-etl/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ParseDate',
      name: 'ParseDate',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/parsedate/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISOToDay',
      name: 'ISOToDay',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isotoday/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SecondsBetween',
      name: 'SecondsBetween',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/secondsbetween/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'COSH',
      name: 'COSH',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/cosh/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'API Endpoints with pagination support',
      name: 'API Endpoints with pagination support',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/api-endpoints-with-pagination-support/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ToJson',
      name: 'ToJson',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tojson/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ATAN',
      name: 'ATAN',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/atan/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Xero',
      name: 'Allowing Integrate.io ETL access to my data on Xero',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-xero/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Configuring your Integrate.io ETL environment',
      name: 'Configuring your Integrate.io ETL environment',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/category/configuring-your-integrate-io-etl-environment/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'SINH',
      name: 'SINH',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/sinh/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Google Analytics Source',
      name: 'Using components: Google Analytics Source',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-google-analytics-source/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Getting Started',
      name: 'Getting Started',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/getting-started/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ToMilliSeconds',
      name: 'ToMilliSeconds',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/tomilliseconds/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Dynamic connections',
      name: 'Dynamic connections',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/dynamic-connections/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'UPPER',
      name: 'UPPER',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/upper/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExecuteSqlInt',
      name: 'ExecuteSqlInt',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/executesqlint/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ParseDoubleOrDefault',
      name: 'ParseDoubleOrDefault',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/parsedoubleordefault/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Salesforce PK Chunking',
      name: 'Salesforce PK Chunking',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/salesforce-pk-chunking/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Amazon Redshift Destination',
      name: 'Using components: Amazon Redshift Destination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-amazon-redshift-destination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using Components: Window',
      name: 'Using Components: Window',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-window/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Working in the new package designer',
      name: 'Working in the new package designer',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/working-in-the-new-package-designer/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Google BigQuery Destination',
      name: 'Using components: Google BigQuery Destination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-google-bigquery-destination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using components: Assert Transformation',
      name: 'Using components: Assert Transformation',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-assert-transformation/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'TimestampFormatToISODatetime',
      name: 'TimestampFormatToISODatetime',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/timestampformattoisodatetime/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ExecuteSqlBoolean',
      name: 'ExecuteSqlBoolean',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/executesqlboolean/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'IsISODatetime',
      name: 'IsISODatetime',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isisodatetime/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'MapToBag',
      name: 'MapToBag',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/maptobag/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my data on Intercom',
      name: 'Allowing Integrate.io ETL access to my data on Intercom',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-data-on-intercom/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Using Components: Rest API Destination',
      name: 'Using Components: Rest API Destination',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/using-components-rest-api-destination/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'IntervalIntersection',
      name: 'IntervalIntersection',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/intervalintersection/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'INDEXOF',
      name: 'INDEXOF',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/indexof/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISOToYear',
      name: 'ISOToYear',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isotoyear/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my Amazon Athena instance',
      name: 'Allowing Integrate.io ETL access to my Amazon Athena instance',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-amazon-athena-instance/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'ISOToMinute',
      name: 'ISOToMinute',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/isotominute/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'STRSPLIT',
      name: 'STRSPLIT',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/strsplit/',
        operation: 'blank',
      },
      category: 4065,
    });
    (window as any).CommandBar.addCommand({
      text: 'Allowing Integrate.io ETL access to my Marketing Cloud (REST)',
      name: 'Allowing Integrate.io ETL access to my Marketing Cloud (REST)',
      template: {
        type: 'link',
        value: 'https://www.integrate.io/docs/etl/allowing-integrateio-etl-access-to-my-marketing-cloud-rest/',
        operation: 'blank',
      },
      category: 4065,
    });
  }
}
