import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { sortBy } from 'lodash';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  loadSelectPickerItem,
  loadSelectPickerItemError,
  loadSelectPickerItemResponse,
  loadSelectPickerItems,
  loadSelectPickerItemsResponse,
  loadSelectPickerItemsWithSearch,
  loadSelectPickerItemsWithSearchResponse,
  updateSelectPickerItem,
} from './select-picker.actions';
import { PackagesResource } from '../../packages/resources/packages.resource';
import { SelectPickerTypes } from '../components/forms/select-picker/select-picker-types.enum';
import { ClustersResource } from '../../clusters/resources/clusters.resource';
import { WorkspacesResource } from '../../workspaces/resources/workspaces.resource';
import { ConnectionsResource } from '../../connections/resources/connections.resource';
import { AppState } from '../../store';
import { updateConnectionResponse } from '../../connections/store/connections.actions';
import { NotifyService } from '../services/notify.service';
import { ConnectionItemsResource } from '../../connections/resources/connection-items.resource';
import { ConnectionTypeName } from '../../connections/connection.models';
import { ListParams } from '../helper/query-params-generic-list.helper';
import { SelectPickerValue } from '../components/forms/select-picker/xp-select-picker.component';

function sortItems(items: SelectPickerValue[], attribute: string) {
  if (attribute === 'updated_at') {
    return [...items].sort(
      (a: SelectPickerValue, b: SelectPickerValue) => (new Date(b.updated_at) as any) - (new Date(a.updated_at) as any),
    );
  }

  return sortBy<any>(items, attribute);
}

function searchByName(params: ListParams): (item: SelectPickerValue) => boolean {
  return ({ name }) => name.toLowerCase().includes(params.q.toLowerCase());
}

@Injectable({
  providedIn: 'root',
})
export class SelectPickerEffects {
  loadItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSelectPickerItems),
      withLatestFrom(this.store$),
      mergeMap(([{ itemType, params, isLazyLoad, id }, store]) => {
        if (store.selectPicker[itemType] && store.selectPicker[itemType]?.isLoadingFlagIgnored) {
          return EMPTY;
        }
        return this.loadItems(itemType, params).pipe(
          map((items) =>
            loadSelectPickerItemsResponse({ id, items: sortItems(items, params.sort_locally), itemType, isLazyLoad }),
          ),
          catchError((error) => {
            this.notify.error(
              this.translate.instant(`response.404.resources_not_found_message_exact`, { item: itemType }),
            );
            return of(loadSelectPickerItemError({ id, error, itemType }));
          }),
        );
      }),
    ),
  );

  loadItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSelectPickerItem),
      mergeMap(({ id, itemType, itemId, params, connectionType }) => {
        return this.loadItem(itemType, itemId, params, connectionType).pipe(
          map((item) => loadSelectPickerItemResponse({ id, item, itemType })),
          catchError((error) => {
            return of(loadSelectPickerItemError({ id, error, itemType }));
          }),
        );
      }),
    ),
  );

  loadItemsWithSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSelectPickerItemsWithSearch),
      switchMap(({ id, itemType, params }) =>
        this.searchItems(itemType, params).pipe(
          map((items) =>
            loadSelectPickerItemsWithSearchResponse({
              id,
              items: sortItems(items, params.sort_locally),
              itemType,
            }),
          ),

          catchError((error) => {
            this.notify.error(
              this.translate.instant(`response.404.resources_not_found_message_exact`, { item: itemType }),
            );
            return of(loadSelectPickerItemError({ id, error, itemType }));
          }),
        ),
      ),
    ),
  );

  updateConnectionItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConnectionResponse),
      map(({ data }) =>
        updateSelectPickerItem({
          id: 'connection-picker-component',
          item: data,
          itemType: SelectPickerTypes.connection,
        }),
      ),
    ),
  );

  updateConnectionItemRestApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConnectionResponse),
      map(({ data }) =>
        updateSelectPickerItem({
          id: 'connection-picker-rest-api',
          item: data,
          itemType: SelectPickerTypes.connection,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private packagesResource: PackagesResource,
    private clustersResource: ClustersResource,
    private workspacesResource: WorkspacesResource,
    private connectionsResource: ConnectionsResource,
    private connectionItemsResource: ConnectionItemsResource,
    private store$: Store<AppState>,
    private notify: NotifyService,
    private translate: TranslateService,
  ) {}

  loadItems(type: SelectPickerTypes, params: ListParams): Observable<SelectPickerValue[]> {
    switch (type) {
      case SelectPickerTypes.cluster:
        return this.clustersResource.query(params);
      case SelectPickerTypes.package:
        return this.packagesResource.query(params);
      case SelectPickerTypes.connection:
        return this.connectionsResource.query(params);
      case SelectPickerTypes.workspace:
        return this.workspacesResource.query(params);
      case SelectPickerTypes['package-version']:
        return this.packagesResource.versions(params.package_id, params);
      case SelectPickerTypes['schedule-package-version']:
      case SelectPickerTypes['run-package-package-version']:
        if (!params.package_id) {
          return of([]);
        }
        return this.packagesResource.versions(params.package_id, params);
      default:
        return EMPTY;
    }
  }

  loadItem(
    type: SelectPickerTypes,
    id: number,
    params: ListParams,
    connectionType?: ConnectionTypeName,
  ): Observable<SelectPickerValue> {
    switch (type) {
      case SelectPickerTypes.cluster:
        return this.clustersResource.get(id, {}, { ignorenotify: 'true' });
      case SelectPickerTypes.package:
        return this.packagesResource.get(id, {}, null, { ignorenotify: 'true' });
      case SelectPickerTypes.connection:
        return this.connectionItemsResource.get(connectionType, id, {}, { ignorenotify: 'true' });
      case SelectPickerTypes.workspace:
        return this.workspacesResource.get(id, {}, { ignorenotify: 'true' });
      case SelectPickerTypes['package-version']:
        return this.packagesResource.version(params.package_id, id, {}, null, { ignorenotify: 'true' });
      case SelectPickerTypes['schedule-package-version']:
      case SelectPickerTypes['run-package-package-version']:
        if (!params.package_id) {
          return of({} as SelectPickerValue);
        }
        return this.packagesResource.version(params.package_id, id, {}, null, { ignorenotify: 'true' });
      default:
        return EMPTY;
    }
  }

  searchItems(type: SelectPickerTypes, params: ListParams): Observable<SelectPickerValue[]> {
    switch (type) {
      case SelectPickerTypes.cluster:
        return this.clustersResource.search(params);
      case SelectPickerTypes.package:
        return this.packagesResource.search(params);
      case SelectPickerTypes.connection:
        return this.connectionsResource
          .query(params)
          .pipe(map((connections) => connections.filter(searchByName(params))));
      case SelectPickerTypes.workspace:
        return this.workspacesResource.search(params);
      case SelectPickerTypes['package-version']:
        return this.packagesResource
          .versions(params.package_id, params)
          .pipe(map((versions) => versions.filter(searchByName(params))));
      case SelectPickerTypes['schedule-package-version']:
      case SelectPickerTypes['run-package-package-version']:
        if (!params.package_id) {
          return of([]);
        }
        return this.packagesResource
          .versions(params.package_id, params)
          .pipe(map((versions) => versions.filter(searchByName(params))));
      default:
        return EMPTY;
    }
  }
}
