import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { OracleConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';
import { SelectOption } from '../../../common/components/forms/xp-select.component';

enum CONNECTION_TYPE {
  DATABASE = 'database',
  SERVICE_NAME = 'service_name',
}
@Component({
  selector: 'connection-form-oracle',
  template: `
    <xp-form-validation type="OracleDatabaseConnection" [name]="formName">
      <form id="connectionFormOracle" name="connectionFormOracle" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <connection-component-access-type
              [connection]="connection"
              (tunnelTypeChange)="onTunnelTypeChange($event)"
            ></connection-component-access-type>
            <div class="connection-ssl">
              <xp-form-group [validationDisabled]="true">
                <xp-input-checkbox
                  name="ssl"
                  [(ngModel)]="connection.ssl"
                  [labelText]="'connections.form.labels.connect_using_ssl' | translate"
                ></xp-input-checkbox>
              </xp-form-group>
            </div>
            <div class="connection-ssl">
              <xp-form-group [validationDisabled]="true">
                <xp-input-checkbox
                  name="ssl"
                  [ngModel]="connection.is_using_certificate"
                  (ngModelChange)="onCertificateFlagChange($event)"
                  [labelText]="'connections.form.labels.connect_using_certificate' | translate"
                ></xp-input-checkbox>
              </xp-form-group>
              <xp-form-group *ngIf="connection.is_using_certificate">
                <label for="cert_file">{{ 'connections.form.labels.certificate_file' | translate }}</label>
                <input
                  style="display: none"
                  type="file"
                  (change)="onFileChanged($event)"
                  id="cert_file"
                  name="cert_file"
                  #fileInput
                />
                <button class="btn btn-default form-control btn-info" (click)="fileInput.click()">
                  {{ selectedFile ? 'Select another file' : 'Select file' }}
                </button>
                <span class="file-name" *ngIf="selectedFile">{{ selectedFile.name | xpLengthCheck: 28 }}</span>
                <span class="error-message" *ngIf="error">{{ error }}</span>
              </xp-form-group>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label for="database">Database Identifier</label>
              <xp-select
                class="form-control xp-select"
                name="connection_type"
                id="connection_type"
                [value]="connectionType"
                [options]="connectionTypeOptions"
                (valueChange)="onConnectionTypeChange($event)"
              >
              </xp-select>
            </div>
            <xp-form-group *ngIf="connectionType === CONNECTION_TYPE.DATABASE">
              <label for="database">SID </label>
              <xp-input
                type="text"
                class="form-control"
                name="database"
                id="database"
                [(ngModel)]="connection.database"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <xp-form-group *ngIf="connectionType === CONNECTION_TYPE.SERVICE_NAME">
              <label for="database">{{ 'connections.form.labels.service_name' | translate }} </label>
              <xp-input
                type="text"
                class="form-control"
                name="service_name"
                id="service_name"
                [(ngModel)]="connection.service_name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <connection-component-username-password [connection]="connection"></connection-component-username-password>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormOracleComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<OracleConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() tunnelTypeChange = new EventEmitter<String>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormOracle';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  selectedFile: Blob;
  error: string;

  connectionTypeOptions: SelectOption[] = [
    { value: CONNECTION_TYPE.DATABASE, text: 'SID' },
    { value: 'service_name', text: 'Service Name' },
  ];

  connectionType = CONNECTION_TYPE.DATABASE;
  CONNECTION_TYPE = CONNECTION_TYPE;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.connection.database) {
      this.connectionType = CONNECTION_TYPE.DATABASE;
    }

    if (this.connection.service_name) {
      this.connectionType = CONNECTION_TYPE.SERVICE_NAME;
    }
  }

  onTunnelTypeChange(value: string) {
    this.tunnelTypeChange.emit(value);
  }

  onConnectionTypeChange(value: CONNECTION_TYPE) {
    if (value === CONNECTION_TYPE.DATABASE) {
      this.connection.database = this.connection.service_name;
      this.connection.service_name = '';
    }
    if (value === CONNECTION_TYPE.SERVICE_NAME) {
      this.connection.service_name = this.connection.database;
      this.connection.database = '';
    }

    this.connectionType = value;
  }

  onCertificateFlagChange(value: boolean) {
    this.connection.is_using_certificate = value;
  }

  onFileChanged(event: Event) {
    this.error = '';

    // eslint-disable-next-line prefer-destructuring
    this.selectedFile = (event.target as HTMLInputElement).files[0];
    const fileReader = new FileReader();

    if (this.selectedFile?.type !== 'application/x-x509-ca-cert') {
      this.error = 'Selected file is not a PEM type.';
      this.selectedFile = null;
      return;
    }

    fileReader.readAsText(this.selectedFile, 'UTF-8');

    fileReader.onload = () => {
      try {
        this.connection.ssl_ca_certificate = fileReader.result as string;
      } catch (e) {
        this.error = e;
      }
    };

    fileReader.onerror = (error) => {
      this.error = JSON.stringify(error);
    };
  }
}
