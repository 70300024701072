import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { YoutubeDialogData, YoutubeDialogTemplateComponent } from './components/youtube-dialog-template.component';

@Injectable({
  providedIn: 'root',
})
export class YoutubeDialogService {
  defaults: YoutubeDialogData;

  constructor(public dialog: MatDialog) {
    this.defaults = { url: '', title: '' };
  }

  openDialog(data: Partial<YoutubeDialogData>): MatDialogRef<YoutubeDialogTemplateComponent> {
    return this.dialog.open(YoutubeDialogTemplateComponent, {
      data: { ...this.defaults, ...data },
      position: {
        top: '150px',
      },
      maxWidth: '800px',
    });
  }
}
