export enum ValidationsEnum {
  ALIAS_EDITOR = 'alias-editor',
  DATABASE_COLUMN_EDITOR = 'database-column-editor',
  FIELD_PICKER = 'field-picker',
  ORDERED_FIELDS_PICKER = 'ordered-fields-picker',
  WINDOW_ARGUMENTS_EDITOR = 'window-arguments-editor',
  FIELDS_EXPRESSION_EDITOR = 'field-expression-editor',
  REST_API_BODY_EXPRESSION_EDITOR = 'rest-api-body-expression-editor',
  REST_API_HEADER_KEY = 'rest-api-headers-key',
  REST_API_HEADER_VALUE = 'rest-api-headers-value',
}
