import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Clipboard from 'clipboard';
import { ConnectionTypeName, DatabaseDefaultConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { AppState } from '../../../store';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';
import { selectAccount } from 'src/app/account/store/account.selectors';

@Component({
  selector: 'connection-component-access-type',
  template: `
    <div class="connection-component-access-type">
      <xp-form-group [validationDisabled]="true">
        <label for="tunnel_type">{{ 'connections.form.labels.tunnel_type' | translate }}</label>
        <xp-select
          class="form-control connection-component-access-type xp-select"
          name="tunnel_type"
          id="tunnel_type"
          [value]="connection.tunnel_type"
          [options]="tunnelTypeOptions"
          (valueChange)="tunnelTypeChanged($event)"
          [preventEmpty]="true"
        >
        </xp-select>
      </xp-form-group>
      <connection-component-host-port
        [hidePort]="hidePort"
        [connection]="connection"
        *ngIf="connection.tunnel_type === 'direct' || connection.tunnel_type === 'ssh-tunnel'"
        (portChange)="portChange.emit($event)"
      ></connection-component-host-port>
      <connection-component-ssh-host-port-user
        [connection]="connection"
        *ngIf="connection.tunnel_type === 'ssh-tunnel' && hasSSHTunnelSupport"
      ></connection-component-ssh-host-port-user>
      <div class="form-group" *ngIf="connection.tunnel_type" [hidden]="connection.tunnel_type !== 'ssh-tunnel'">
        <label for="ssh_public_key">
          {{ 'connections.form.labels.ssh_public_key' | translate }}
        </label>
        <div class="copy-public-key-container">
          <xp-input
            id="ssh_public_key"
            class="form-control col-md-11"
            [attr.readonly]="true"
            [readOnly]="true"
            type="text"
            name="ssh_public_key"
            [(ngModel)]="connection.public_key"
          ></xp-input>
          <button
            class="copy-public-key-button access-type-public-key btn btn-gray-light"
            type="button"
            [attr.data-clipboard-text]="connection.public_key"
            [disabled]="!connection.public_key"
            [matTooltip]="'connections.form.sftp.actions.copy_key' | translate"
            matTooltipPosition="above"
          >
            <i class="fa fa-copy" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="form-group" *ngIf="connection.tunnel_type && connection.tunnel_type === 'reverse'">
        <label for="tunnel_endpoint"> Tunnel endpoint </label>
        <div class="copy-public-key-container">
          <xp-input
            id="tunnel_endpoint"
            class="form-control col-md-11"
            [attr.readonly]="true"
            [readOnly]="true"
            type="text"
            name="tunnel_endpoint"
            [ngModel]="reverseTunnelEndpoint"
          ></xp-input>
          <button
            class="copy-public-key-button access-type-public-key btn btn-gray-light"
            type="button"
            [attr.data-clipboard-text]="reverseTunnelEndpoint"
            [matTooltip]="'connections.form.sftp.actions.copy_key' | translate"
            matTooltipPosition="above"
          >
            <i class="fa fa-copy" aria-hidden="true"></i>
          </button>
        </div>
        <small *ngIf="connection.tunnel_type === 'reverse' && !connection.local_port">{{
          'connections.form.labels.save_to_get_tunnel_data' | translate
        }}</small>
      </div>
      <connection-component-reverse-tunnel
        [localPort]="connection.local_port"
        *ngIf="connection.tunnel_type === 'reverse'"
        [reverseTunnelEndpoint]="reverseTunnelEndpoint"
        [isSaved]="connection.saved"
      ></connection-component-reverse-tunnel>
      <connection-component-ssh-tunnel
        [isSaved]="connection.saved"
        *ngIf="connection.tunnel_type === 'ssh-tunnel'"
      ></connection-component-ssh-tunnel>
      <div class="connection-component-ssh-tunnel alert alert-info" *ngIf="connection.tunnel_type === 'direct'">
        <strong
          ><i class="fa fa-info-circle" aria-hidden="true"></i>
          {{ 'connection-component-ssh-direct.title' | translate }}</strong
        >
        <p [innerHTML]="'connection-component-ssh-direct.text' | translate"></p>
      </div>
    </div>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ConnectionComponentAccesssTypeComponent implements OnInit, OnDestroy {
  @Input() connection: Partial<DatabaseDefaultConnection> = {};
  @Input() hidePort = false;
  @Output() tunnelTypeChange = new EventEmitter<String>();
  @Output() portChange = new EventEmitter<number>();

  tunnelTypeOptions = [];
  hasSSHTunnelSupport = false;
  clipboard: Clipboard = null;
  accountTunnelHost = '';

  constructor(
    private notify: NotifyService,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    const copyableElemSelector = `.copy-public-key-button.access-type-public-key`;
    this.clipboard = new Clipboard(copyableElemSelector);

    this.clipboard.on('success', this.notifyInfo.bind(this));

    this.hasSSHTunnelSupport = this.activatedRoute.snapshot.firstChild?.params?.type !== ConnectionTypeName.mongo;

    this.tunnelTypeOptions = [
      {
        value: 'direct',
        text: 'connection-component-access-type.selects.tunnel_type.options.direct',
        translate: true,
      },

      this.hasSSHTunnelSupport
        ? {
            value: 'ssh-tunnel',
            text: 'connection-component-access-type.selects.tunnel_type.options.ssh-tunnel',
            translate: true,
          }
        : null,

      {
        value: 'reverse',
        text: 'connection-component-access-type.selects.tunnel_type.options.reverse',
        translate: true,
      },
    ].filter(Boolean);

    this.store
      .select(selectAccount)
      .pipe(first())
      .subscribe((account) => {
        this.accountTunnelHost = account.tunnel_host;
      });
  }

  notifyInfo() {
    this.notify.info('Copied!');
  }

  get reverseTunnelEndpoint(): string {
    if (!this.connection.local_port) {
      return '';
    }

    return `${this.accountTunnelHost}:${this.connection.local_port}`;
  }

  ngOnDestroy() {
    this.clipboard.off('success', this.notifyInfo.bind(this));
  }

  tunnelTypeChanged(tunnelType: string) {
    this.connection.tunnel_type = tunnelType;

    this.tunnelTypeChange.emit(this.connection.tunnel_type);
  }
}
