import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { MatTooltipModule } from '@angular/material/tooltip';
import { accountReducer } from './store/account.reducer';
import './resources/account.resource';
import './resources/users.resource';
import './resources/validationsExports.resource';
import './resources/variables.resource';
import './services/accounts.service';
import { AccountEffects } from './store/account.effects';
import { SignInComponent } from './components/sign-in.component';
import { CommonModule } from '../common/common.module';
import { SignUpComponent } from './components/sign-up.component';
import { ThankYouComponent } from './components/thank-you.component';
import { MembersInvitationComponent } from './components/members-invitation.component';
import { ConfirmationComponent } from './components/confirmation.component';
import { ForgotPasswordComponent } from './components/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password.component';
import { Error404Component } from './components/error-404.component';
import { AlertBoxComponent } from './components/alert-box.component';
import { Error401Component } from './components/error-401.component';
import { LaunchpadComponent } from './components/launchpad.component';
import { SettingsModule } from '../settings/settings.module';
import { WelcomeComponent } from './components/welcome.component';
import { SidebarMockComponent } from './components/mocks/sidebar-mock.component';
import { SignUpFreeTrialComponent } from './components/sign-up-free-trial.component';
import { EmailConfirmationComponent } from './components/email-confirmation.component';
import { AuthComponent } from './components/auth.component';
import { PartnersComponent } from './components/partners.component';
import { ErrorNotAllowedComponent } from './components/error-not-allowed.component';
import { OauthCallbackComponent } from './components/oauth-callback.component';

@NgModule({
  declarations: [
    SignInComponent,
    SignUpComponent,
    SignUpFreeTrialComponent,
    ThankYouComponent,
    MembersInvitationComponent,
    ConfirmationComponent,
    EmailConfirmationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AlertBoxComponent,
    Error401Component,
    Error404Component,
    LaunchpadComponent,
    WelcomeComponent,
    SidebarMockComponent,
    EmailConfirmationComponent,
    AuthComponent,
    PartnersComponent,
    ErrorNotAllowedComponent,
    OauthCallbackComponent,
  ],
  imports: [
    BrowserModule,
    StoreModule.forFeature('account', accountReducer),
    EffectsModule.forFeature([AccountEffects]),
    CommonModule,
    TranslateModule,
    FormsModule,
    SettingsModule,
    MatTooltipModule,
    RouterModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AccountModule {}
