import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DatabaseDefaultConnection } from '../../connection.models';

@Component({
  selector: 'connection-component-reverse-tunnel',
  template: `
    <div class="connection-component-reverse-tunnel alert alert-info">
      <strong
        ><i class="fa fa-info-circle" aria-hidden="true"></i>
        {{ 'connection-component-reverse-tunnel.title' | translate }}</strong
      >
      <p
        *ngIf="reverseTunnelEndpoint"
        [innerHTML]="
          'connection-component-reverse-tunnel.text_saved' | translate: { reverseTunnelEndpoint: reverseTunnelEndpoint }
        "
      ></p>
      <p *ngIf="!reverseTunnelEndpoint" [innerHTML]="'connection-component-reverse-tunnel.text' | translate"></p>
    </div>
  `,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ConnectionComponentReverseTunnelComponent {
  @Input() connection: Partial<DatabaseDefaultConnection> = {};
  @Input() localPort: number;
  @Input() isSaved: boolean;
  @Input() reverseTunnelEndpoint: string;
}
