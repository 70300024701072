import { generateCodeVerifier, OAuth2Client } from '@badgateway/oauth2-client';
import { Injectable } from '@angular/core';
import {
  getFullUrlPath,
  prepareOptions,
  stringifyOptions,
} from '../../connections/helpers/connections-authentication.helpers';
import { OAuth2Token } from '@badgateway/oauth2-client/src/token';

export interface CreateClientSettings {
  clientId: string;
  clientSecret: string;
  tokenEndpoint: string;
  authorizationEndpoint: string;
  scope: string;
}

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  client: OAuth2Client;
  codeVerifier: string;
  redirectUri: string;
  settings: CreateClientSettings;

  popup = {
    url: '',
    popupWindow: null,
  };
  createClient(settings: CreateClientSettings) {
    this.settings = settings;
    this.client = new OAuth2Client({
      clientId: settings.clientId,
      clientSecret: settings.clientSecret,
      tokenEndpoint: settings.tokenEndpoint,
      authorizationEndpoint: settings.authorizationEndpoint,
      // This document is used to determine various server features.
      // If not specified, we assume it's on /.well-known/oauth2-authorization-server
      discoveryEndpoint: '/.well-known/oauth2-authorization-server',
    });
  }

  async getAuthorizeToken(): Promise<OAuth2Token> {
    this.codeVerifier = await generateCodeVerifier();
    this.redirectUri = 'http://localhost:3000/auth-callback';

    const url = await this.client.authorizationCode.getAuthorizeUri({
      // URL in the app that the user should get redirected to after authenticating
      redirectUri: this.redirectUri,

      codeVerifier: this.codeVerifier,

      scope: [this.settings.scope],
    });
    const oauthData = await this.openPopup(url, '', {})(this.redirectUri);

    return oauthData;
  }

  openPopup(url: string, name: string, options: any) {
    this.popup.url = url;

    const stringifiedOptions = stringifyOptions(prepareOptions(options));
    const UA = window.navigator.userAgent;
    const windowName = UA.indexOf('CriOS') > -1 ? '_blank' : name;

    this.popup.popupWindow = window.open(url, windowName, stringifiedOptions);

    if (this.popup.popupWindow && this.popup.popupWindow.focus) {
      this.popup.popupWindow.focus();
    }

    return this.pollPopup.bind(this);
  }

  private pollPopup(redirectUri: string): Promise<OAuth2Token> {
    return new Promise((resolve, reject) => {
      const redirectUriParser = document.createElement('a');
      redirectUriParser.href = redirectUri;

      const redirectUriPath = getFullUrlPath(redirectUriParser as any);

      const polling = setInterval(() => {
        if (!this.popup.popupWindow || this.popup.popupWindow.closed || this.popup.popupWindow.closed === undefined) {
          reject(new Error('The popup window was closed.'));
          clearInterval(polling);
        }

        try {
          const popupWindowPath = getFullUrlPath(this.popup.popupWindow.location);

          // Redirect has occurred.
          if (popupWindowPath === redirectUriPath) {
            const queryString = this.popup.popupWindow.location.search;
            const urlParams = new URLSearchParams(queryString);
            const code = urlParams.get('code');

            this.client.authorizationCode
              .getToken({
                code,
                codeVerifier: this.codeVerifier,
                redirectUri,
              })
              .then((token) => {
                resolve(token);

                clearInterval(polling);
                this.popup.popupWindow.close();
              });
          }
        } catch (error) {
          // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
          // A hack to get around same-origin security policy errors in IE.
        }
      }, 20);
    });
  }
}
