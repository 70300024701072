import { createReducer, on } from '@ngrx/store';
import { AnyConnection, ConnectionType, ConnectionTypeName } from '../connection.models';
import {
  getConnectionTypes,
  getConnectionTypesResponse,
  removeConnectionItem,
  closeConnectionsModal,
  getConnectionsList,
  getConnectionsListResponse,
  loadMoreConnectionsList,
  loadMoreConnectionsListResponse,
  openConnectionsModal,
  removeConnectionResponse,
  saveConnection,
  saveConnectionError,
  saveConnectionResponse,
  searchConnectionsList,
  searchConnectionsListResponse,
  updateConnectionResponse,
  selectConnectionType,
  updateConnectionError,
  testConnection,
  testConnectionResponse,
  testConnectionError,
  updateConnection,
  getConnectionItem,
  getConnectionItemResponse,
  getConnectionSchema,
  getConnectionSchemaResponse,
  getConnectionSchemaError,
  setConnectionOAuthToken,
  clearConnectionOAuthToken,
  getConnectionsListError,
  getDatabaseColumns,
  getDatabaseColumnsResponse,
  getDatabaseColumnsError,
} from './connections.actions';
import { ValidationError } from '../../config/validation-error.model';
import { extendConnections, fillGroups, Connections, ConnectionGroup } from '../helpers/connection-types.helpers';
import { ConnectionSchemaField, SchemaResponse } from '../../package-designer/package.models';
import { QueryParamsConnectionsList } from '../../common/helper/query-params-generic-list.helper';
import { closeAllModals } from '../../account/store/account.actions';
import { closeComponentsModal } from '../../package-designer/store/package-designer.actions';
import { setPackageFromJson } from '../../package-designer/store/package.actions';

export const initialState = {
  types: [],
  groups: [],
  extendedTypes: {},
  selectedType: null,
  areTypesLoading: true,
  items: [],
  item: null,
  isLoading: false,
  isLoadingItem: false,
  isFormSubmitting: false,
  isModalOpen: false,
  isDetailsModalOpen: false,
  isChildDetailsModalOpen: false,
  errors: [],
  testSuccessMessage: [],
  isTesting: false,
  lastlyCreatedConnection: null,
  isLoadingSchema: false,
  isLoadingSchemaData: false,
  connectionSchema: [],
  connectionSchemaData: { data: [], fields: [] },
  connectionSchemaError: null,
  token: '',
  tokenArray: [],
  isResponseError: false,
  areAllItemsLoaded: false,
  databaseColumns: [],
  databaseColumnsError: null,
};

export interface ConnectionsState {
  types: ConnectionType[];
  groups: ConnectionGroup[];
  extendedTypes: Connections;
  selectedType: ConnectionTypeName;
  areTypesLoading: boolean;
  items: AnyConnection[];
  item: Partial<AnyConnection>;
  isLoading: boolean;
  isLoadingItem: boolean;
  isFormSubmitting: boolean;
  isModalOpen: boolean;
  isDetailsModalOpen: boolean;
  isChildDetailsModalOpen: boolean;
  errors: ValidationError[];
  testSuccessMessage: string[];
  isTesting: boolean;
  lastlyCreatedConnection: AnyConnection;
  isLoadingSchema: boolean;
  isLoadingSchemaData: boolean;
  connectionSchema: ConnectionSchemaField[];
  connectionSchemaData: SchemaResponse;
  connectionSchemaError: { message?: string };
  token: string;
  tokenArray: string[];
  isResponseError: boolean;
  areAllItemsLoaded: boolean;
  databaseColumns: string[];
  databaseColumnsError: { message?: string };
}

export const connectionsReducer = createReducer<ConnectionsState>(
  initialState,

  on(getConnectionTypes, (state) => ({ ...state, areTypesLoading: true })),
  on(getConnectionTypesResponse, (state, { types }) => ({
    ...state,
    types,
    areTypesLoading: false,
    groups: fillGroups(types),
    extendedTypes: extendConnections(types),
  })),

  on(selectConnectionType, (state, { selectedType }) => ({ ...state, selectedType })),

  on(openConnectionsModal, (state) => ({
    ...state,
    isModalOpen: true,
    errors: [],
    testSuccessMessage: [],
    item: null,
  })),
  on(closeConnectionsModal, (state) => ({ ...state, isModalOpen: false, item: null, selectedType: null })),
  on(closeAllModals, (state) => ({ ...state, isModalOpen: false, selectedType: null })),

  on(getConnectionsList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(getConnectionsListResponse, (state, { connections }) => ({
    ...state,
    isLoading: false,
    items: connections,
    areAllItemsLoaded: connections.length < QueryParamsConnectionsList.limit,
  })),
  on(getConnectionsListError, (state) => ({ ...state, isLoading: false, items: [], isResponseError: true })),

  on(getConnectionItem, (state) => ({ ...state, isLoadingItem: true })),
  on(getConnectionItemResponse, (state, { connection }) => ({ ...state, isLoadingItem: false, item: connection })),

  on(searchConnectionsList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(searchConnectionsListResponse, (state, { connections }) => ({
    ...state,
    isLoading: false,
    items: connections,
    areAllItemsLoaded: connections.length < QueryParamsConnectionsList.limit,
  })),

  on(loadMoreConnectionsList, (state) => ({ ...state, isLoading: true, isResponseError: false })),
  on(loadMoreConnectionsListResponse, (state, { connections }) => ({
    ...state,
    isLoading: false,
    items: [...state.items, ...connections],
    areAllItemsLoaded: connections.length < QueryParamsConnectionsList.limit,
  })),

  on(saveConnection, (state) => ({ ...state, isFormSubmitting: true })),
  on(saveConnectionResponse, (state, { data, closeModal, shouldNotModifyFormData }) => {
    if (shouldNotModifyFormData) {
      return {
        ...state,
        // eslint-disable-next-line no-nested-ternary
        items: state.items.find((item) => item.id === data.id) || !data.owner ? state.items : [data, ...state.items],
      };
    }
    return {
      ...state,
      isFormSubmitting: false,
      item: {
        ...data,
        saved: true,
      },
      // eslint-disable-next-line no-nested-ternary
      items: state.items.find((item) => item.id === data.id) || !data.owner ? state.items : [data, ...state.items],
      isModalOpen: closeModal ? false : state.isModalOpen,
      lastlyCreatedConnection: data,
      selectedType: data.type,
    };
  }),
  on(saveConnectionError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(updateConnection, (state) => ({ ...state, isFormSubmitting: true })),
  on(updateConnectionResponse, (state, { data, shouldNotModifyFormData }) => ({
    ...state,
    items: state.items.map((item) => (item.id === data.id ? { ...item, ...data } : item)),
    item: shouldNotModifyFormData ? state.item : { ...data, saved: true },
    isFormSubmitting: shouldNotModifyFormData ? state.isFormSubmitting : false,
    selectedType: shouldNotModifyFormData ? state.selectedType : null,
  })),
  on(updateConnectionError, (state, { errors }) => ({ ...state, isFormSubmitting: false, errors })),

  on(testConnection, (state) => ({ ...state, isTesting: true, testSuccessMessage: [] })),
  on(testConnectionResponse, (state, { message }) => ({
    ...state,
    testSuccessMessage: message,
    isTesting: false,
  })),
  on(testConnectionError, (state, { errors }) => ({ ...state, isTesting: false, errors })),

  on(removeConnectionItem, (state, { data }) => ({
    ...state,
    items: state.items.map((item) => (item.id !== data.id ? item : { isRemoved: true, ...item })),
  })),

  on(removeConnectionResponse, (state, { data }) => ({
    ...state,
    items: state.items.filter((item) => item.id !== data.id),
  })),

  on(getConnectionSchema, (state, { onlyData }) => ({
    ...state,
    isLoadingSchema: !onlyData,
    isLoadingSchemaData: true,
    connectionSchema: onlyData ? state.connectionSchema : [],
    connectionSchemaData: { data: [], fields: [] },
    connectionSchemaError: null,
  })),
  on(getConnectionSchemaResponse, (state, { onlyData, connectionSchema, connectionSchemaData }) => ({
    ...state,
    connectionSchema: onlyData ? state.connectionSchema : connectionSchema,
    connectionSchemaData,
    isLoadingSchema: false,
    isLoadingSchemaData: false,
  })),
  on(getConnectionSchemaError, (state, { errors }) => ({
    ...state,
    isLoadingSchema: false,
    isLoadingSchemaData: false,
    connectionSchemaData: { data: [], fields: [] },
    connectionSchemaError: errors[0],
  })),
  on(getDatabaseColumns, (state) => ({
    ...state,
    databaseColumns: [],
    databaseColumnsError: null,
  })),
  on(getDatabaseColumnsResponse, (state, { columns }) => ({
    ...state,
    databaseColumns: columns,
    databaseColumnsError: null,
  })),
  on(getDatabaseColumnsError, (state, { errors }) => ({
    ...state,
    databaseColumns: [],
    databaseColumnsError: errors[0],
  })),
  on(closeComponentsModal, (state) => ({
    ...state,
    isLoadingSchema: false,
    isLoadingSchemaData: false,
    connectionSchemaData: { data: [], fields: [] },
    connectionSchemaError: null,
    databaseColumns: [],
    databaseColumnsError: null,
  })),
  on(setConnectionOAuthToken, (state, { chunk, index, size }) => {
    const tokenArray = [...state.tokenArray];
    tokenArray[index] = chunk;

    return { ...state, tokenArray, token: tokenArray.filter(Boolean).length === size ? tokenArray.join('') : '' };
  }),
  on(clearConnectionOAuthToken, (state) => ({ ...state, token: '', tokenArray: [] })),
  on(setPackageFromJson, (state) => ({ ...state, lastlyCreatedConnection: null })),
  on(closeComponentsModal, (state) => ({ ...state, lastlyCreatedConnection: null })),
);
