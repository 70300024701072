import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface YoutubeDialogData {
  url: string;
  title: string;
}

@Component({
  selector: 'clean-dialog-template',
  template: `
    <div class="dialog confirm alert alert-danger text-center">
      <div class="dialog-text">
        <h3>{{ data.title }}</h3>
      </div>
      <iframe
        width="800"
        height="500"
        [attr.src]="data.url | safe"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div class="dialog-close" (click)="cancel()"></div>
    </div>
  `,
})
export class YoutubeDialogTemplateComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: YoutubeDialogData,
    public dialogRef: MatDialogRef<YoutubeDialogTemplateComponent>,
  ) {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
