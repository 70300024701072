import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Job } from '../../jobs.models';
import { JobsResource } from '../../resources/jobs.resource';
import { NotifyService } from '../../../common/services/notify.service';
import { ConnectionItemsResource } from '../../../connections/resources/connection-items.resource';
import { getComponentType, getDataFromComponent } from '../../../package-designer/helpers/components.helpers';
import { COMPONENT_TYPE } from '../../../constants/component_type';
import { ConnectionsWithBucket } from '../../../connections/connection.models';

@Component({
  selector: 'job-details',
  template: `
    <div class="job-preview">
      <job-view
        [item]="loadedJob"
        [childJobs]="childJobs"
        [isLoaded]="isLoaded"
        [areResponsesLoading]="areResponsesLoading"
        *ngIf="isLoaded"
        [isLoadingChildren]="isLoadingChildren"
        [responsesErrorMessage]="responsesErrorMessage"
        (childOpen)="childOpen.emit($event)"
        (loadMoreTasks)="loadMoreTasks()"
      ></job-view>
      <xp-loader *ngIf="!isLoaded || isLoadingChildren" class="job-preview-loader"></xp-loader>
    </div>
  `,
})
export class JobDetailsComponent implements OnInit {
  @Input() item: Partial<Job>;
  @Input() isChildren: boolean;
  @Output() childOpen = new EventEmitter<Job>();

  isLoaded = false;
  loadedJob: Job;
  currentPage = 0;
  itemsPerPage = 20;
  loadedAllChildren = false;
  childJobs: Job[];
  isLoadingChildren = false;
  areResponsesLoading = false;
  responsesErrorMessage = '';

  constructor(
    private jobsResource: JobsResource,
    private notify: NotifyService,
    private translate: TranslateService,
    private connectionItemsResource: ConnectionItemsResource,
  ) {}

  ngOnInit() {
    this.jobsResource.get(this.item.id, { include: 'package,flow,cluster' } as any).subscribe({
      next: (item) => {
        if (item.package.flow_type === 'workflow') {
          this.jobsResource
            .children(this.item.id, {
              include: 'package',
              offset: this.currentPage * this.itemsPerPage,
              limit: this.itemsPerPage,
            } as any)
            .subscribe({
              next: (progressTasks) => {
                this.loadedJob = item;
                this.loadedAllChildren = progressTasks.length < this.itemsPerPage;
                this.isLoaded = true;
                this.childJobs = progressTasks;

                if (!this.loadedAllChildren) {
                  this.loadMoreTasks();
                }
              },
              error: (error) => {
                this.notify.error(this.translate.instant(`response.${error.status}.message`));
              },
            });
        } else {
          this.loadedJob = item;
          this.isLoaded = true;

          if (item.package.data_flow_json) {
            const restAPIDestinationComponent = JSON.parse(item.package.data_flow_json).components.find(
              (componentItem) => getComponentType(componentItem) === COMPONENT_TYPE.REST_API_DESTINATION_COMPONENT,
            );
            if (restAPIDestinationComponent) {
              const componentData = getDataFromComponent(restAPIDestinationComponent) as any;

              if (componentData.save_responses_to_file) {
                this.loadedJob.responses = [];
                this.areResponsesLoading = true;

                const connectionTypeHasBucket = ConnectionsWithBucket.includes(
                  componentData.responses_output_connection.type,
                );
                let bucket = '';
                let path = '';

                if (connectionTypeHasBucket) {
                  const pathParts = componentData.responses_output_path.split('/');
                  bucket = pathParts.shift();
                  path = pathParts.join('/');
                }

                this.connectionItemsResource
                  .schema(
                    componentData.responses_output_connection.type,
                    componentData.responses_output_connection.id,
                    {
                      bucket: bucket || undefined,
                      path: connectionTypeHasBucket
                        ? `${path}/responses-${item.id}.csv`
                        : `${componentData.responses_output_path}/responses-${item.id}.csv`,
                      delimiter: ',',
                      quote: 'none',
                      escape: '"',
                      lines: 50,
                    },
                  )
                  .subscribe({
                    next: (schema) => {
                      this.areResponsesLoading = false;
                      this.loadedJob.responses = schema.data;
                    },
                    error: (response) => {
                      this.responsesErrorMessage = response.error?.error_message;
                      this.areResponsesLoading = false;
                    },
                  });
              }
            }
          }
        }
      },
      error: (error) => {
        this.notify.error(this.translate.instant(`response.${error.status}.message`));
      },
    });
  }

  loadMoreTasks() {
    if (this.loadedAllChildren) {
      this.isLoadingChildren = false;
      return;
    }
    this.isLoadingChildren = true;

    this.currentPage += 1;
    this.jobsResource
      .children(this.item.id, {
        include: 'package',
        offset: this.currentPage * this.itemsPerPage,
        limit: this.itemsPerPage,
      } as any)
      .subscribe({
        next: (progressTasks) => {
          this.loadedAllChildren = progressTasks.length < this.itemsPerPage;
          this.childJobs = [...(this.childJobs || []), ...(progressTasks || [])];
          this.isLoadingChildren = false;

          if (!this.loadedAllChildren) {
            this.loadMoreTasks();
          }
        },
        error: () => {
          this.notify.error('An error occured while loading job data.');
          this.isLoadingChildren = false;
        },
      });
  }
}
