import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import CodeMirror from 'codemirror';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/display/placeholder';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/tern/tern';
import { ConditionOperatorHandler } from '../../../constants/conditions_operator_picker';
import { openExpressionEditor } from '../../store/package-designer.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { selectExpressionEditorSaveCode, selectIsExpressionOpenFlag } from '../../store/package-designer.selectors';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'code-editor',
  template: `
    <div class="code-editor">
      <div class="editor-button editor-button-open" (click)="editorButtonOpen()" *ngIf="hasExpressionEditorButton">
        <xp-icon type="edit"></xp-icon>
      </div>
      <textarea class="form-control" id="code-editor" [ngModel]="value" [attr.name]="name"></textarea>
    </div>
  `,
})
export class CodeEditorComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() value: string;
  @Input() name: string;
  @Input() options: any;
  @Input() hasExpressionEditorButton = false;
  @Output() valueChange = new EventEmitter<string>();

  textAreaElement: HTMLTextAreaElement;
  editor = null;
  isExpressionEditorOpen = false;
  expressionEditorCloseSubscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private store: Store<AppState>,
  ) {}

  ngAfterViewInit() {
    this.textAreaElement = this.elementRef.nativeElement.querySelector('textarea');

    this.initEditor();

    this.expressionEditorCloseSubscription = this.store
      .select(selectIsExpressionOpenFlag)
      .pipe(
        filter((isOpen) => !isOpen),
        withLatestFrom(this.store.select(selectExpressionEditorSaveCode)),
      )
      .subscribe(([, code]) => {
        if (this.isExpressionEditorOpen) {
          if (code) {
            this.editor.setValue(code);
            this.valueChange.emit(code);
          }
          this.isExpressionEditorOpen = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.value?.currentValue !== changes.value?.previousValue &&
      !changes.value?.firstChange &&
      this.editor &&
      this.value !== this.editor.getValue()
    ) {
      this.editor.setValue(this.value);
    }
  }

  initEditor() {
    const oldCodeMirror = document.querySelector('.CodeMirror');

    if (oldCodeMirror) {
      oldCodeMirror.parentElement.removeChild(oldCodeMirror);
    }

    setTimeout(() => {
      const defaultOptions = {
        lineWrapping: true,
        lineNumbers: true,
        mode: 'text/x-sql',
        placeholder: 'SELECT * FROM table',
      };

      const options = { ...defaultOptions, ...(this.options || {}) };

      this.editor = CodeMirror.fromTextArea(this.textAreaElement, options);

      if (this.value) this.editor.setValue(this.value);

      this.editor.on('change', (cm) => {
        this.value = cm.getValue();

        this.valueChange.emit(this.value);

        this.textAreaElement.dispatchEvent(
          new Event('input', {
            bubbles: true,
            cancelable: true,
          }),
        );
      });

      this.editor.on('blur', (cm) => {
        this.value = cm.getValue();
        this.valueChange.emit(this.value);
      });
    }, 1000);
  }

  editorButtonOpen() {
    this.openExpression();
  }

  openExpression() {
    this.isExpressionEditorOpen = true;
    this.store.dispatch(
      openExpressionEditor({
        code: this.value,
        origin: 'fields-collection',
        category: 'User',
      }),
    );
  }

  ngOnDestroy() {
    if (this.expressionEditorCloseSubscription) {
      this.expressionEditorCloseSubscription.unsubscribe();
    }
  }

  protected readonly ConditionOperatorHandler = ConditionOperatorHandler;
}
